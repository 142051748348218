<template>
    <v-dialog v-model="ipsDialog" max-width="900" :persistent="loading">
        <v-card>
            <v-card-title>
              <v-list-item class="pa-0">
                <v-list-item-title><h2>Block IP Address</h2></v-list-item-title>
                <v-list-item-action>
                  <v-btn outlined rounded color="red" :disabled="loading" @click="ipsDialog = false"><v-icon>close</v-icon></v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-card-title>
            <v-card-text>
                <v-text-field outlined dense rounded hide-details label="Enter IP Address or CIDR Block" v-model="ip"/>
                <div v-if="cidrBlocks.length > 0" class="pb-2 pt-3">CIDR BLOCKS</div>
                <v-layout v-if="cidrBlocks.length > 0" row wrap class="pb-1 px-3" style="gap: 8px">
                    <v-btn small flat outlined v-for="(item, i) in cidrBlocks" :disabled="item === ip" :key="i" @click="ip = item">{{item}}</v-btn>
                </v-layout>
                <div v-if="cidrRange" class="py-2">CIDR RANGE</div>
                <div v-if="cidrRange">{{cidrRange}}</div>
            </v-card-text>
           <div class="text-center py-4">
             <v-btn outlined rounded color="green" @click="blockIp" outline :disabled="loading" :loading="loading" width="200px">BLOCK</v-btn>
           </div>
            <v-card-actions>

                <v-spacer/>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    import cidr from 'ip-cidr'
    export default {
        name: "IPBlockDialog",
        computed: {
            cidrBlocks() {
                try {
                    const b = this.ip.indexOf("/") >= 0;
                    let ip = this.ip;
                    if(b) ip = this.ip.split("/")[0];
                    return [`${ip}/8`, `${ip}/16`, `${ip}/20`, `${ip}/24`, `${ip}/28`, `${ip}/32`, `${ip}/64`, `${ip}/128`]
                }catch (e) {
                    return []
                }
            },
            ipsDialog: {
                set(v){
                    if(!v){
                        this.$store.commit('dialog/setIpToBlock', null);
                    }
                },
                get(){return this.$store.state.dialog.ipToBlock !== null}
            },
            ip: {
                set(v){
                    this.$store.commit('dialog/setIpToBlock', v);
                },
                get(){return this.$store.state.dialog.ipToBlock}
            },
            cidrRange(){
                try {
                    return new cidr(this.$store.state.dialog.ipToBlock).toRange().join(" TO ");
                }catch (e) {
                    return null;
                }
            }
        },
        methods: {
            blockIp() {
                this.loading = true;
                this.$http.post("/security/blockIp", {cidr: this.ip})
                .then(res=>{
                    this.loading = false;
                    if(res.data.statusCode === 0){
                        this.$message.success("Successfully Blocked Ip");
                        this.ipsDialog = null;
                    }else {
                        this.$message.error("Failed to Blocked Ip");
                    }
                }).catch(err=>{
                    this.loading = false;
                    this.$message.error(err.toLocaleString());
                })
            }
        },
        data() {
            return {
                loading: false
            }
        },
    }
</script>
