<template>
  <div class="pa-2">
    <div class="text-right">
      <v-btn @click="importQuestions" large color="red" :dark="!importing" class="mr-1" :loading="importing" :disabled="importing">IMPORT
        QUESTIONS
      </v-btn>
    </div>
    <PastQuestionMetadata ref="meta" v-model="q" hideQuestionType/>
    <v-card class="ma-1" dark>
      <h3 class="pa-3">Platform Specification</h3>
      <v-divider/>
      <v-container>
        <v-layout align-center>
          <v-flex xs3>
            <v-checkbox
                dense
                v-model="q.platform"
                label="Web"
                color="red"
                value="web"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>

          <v-flex xs3>
            <v-checkbox
                dense
                v-model="q.platform"
                label="Desktop"
                color="red"
                value="desktop"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>

          <v-flex xs3>
            <v-checkbox
                v-model="q.platform"
                dense
                label="Android"
                color="red"
                value="android"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>


          <v-flex xs3>
            <v-checkbox
                dense
                v-model="q.platform"
                label="IOS"
                color="red"
                value="ios"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>

        </v-layout>
      </v-container>
    </v-card>
    <v-card class="ma-1">
      <v-card-text>
        <v-text-field v-model="query" @input="search" outlined dense hide-details label="Enter Search Query" />
      </v-card-text>
    </v-card>
    <v-layout wrap>
      <v-flex xs12 md12 lg6 v-for="question in searchedQuestions" :key="question.question_id">
        <v-card color="green" dark class="ma-1">
          <div>
            <div class="pa-4" style="height: 100px;overflow: hidden;font-size: 12px">{{ question.question.en.content }}</div>
            <v-divider/>
            <div class="pa-4"><b>Exam:</b> {{ question.exam }}, <b>Subject:</b> {{ question.subject }}, <b>Chapter:</b>
              {{ question.chapter }},
              <b>Paper:</b> {{ question.paperTitle }}
            </div>
            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-btn :to="`/freelance/${$route.params.id}/past-question/${question.question_id}`" text small outlined>
                UPDATE
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pa-4 text-center">
      <h3 v-if="loadingError">{{ loadingError }}</h3>
      <v-btn @click="getQuestions" v-if="loadingError">Retry</v-btn>
    </div>
  </div>
</template>

<script>
import PastQuestionMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "pastQuestions",
  components: {
    PastQuestionMetadata
  },
  methods: {
    search() {
      if(this.query){
         this.searchedQuestions = this.questions.filter(el=> el.question['en'].content.indexOf(this.query) >= 0)
      }else {
        this.searchedQuestions = this.questions;
      }
    },
    async getQuestions() {
      this.loading = true;
      try {
        const {data} = await this.$http.get("/freelance/" + (this.$route.params.id) + "/past-questions");
        if (data.statusCode === 0) {
          this.questions = data.results;
          this.search();
        } else {
          this.loadingError = data.error ? data.error.message : "Unknown Error";
        }
      } catch (e) {
        this.loadingError = e.toString();
      }
      this.loading = false;
    },
    async importQuestions() {
      this.importing = true;
      try {
        const {data} = await this.$http.post("/freelance/" + (this.$route.params.id) + "/import-past-questions", this.q);
        if (data.statusCode === 0) {
          this.$message.success("Imported Successfully");
          this.questions = [];
          this.q = this.getModel();
        } else {
          this.$message.error(data.error ? data.error.message : "Unknown Error");
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.importing = false;
    },
    getModel() {
      return {
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        year: null,
        paperId: null,
        paperTitle: null,
        topic: null,
        timeAllotted: -1,
        marks: -1.0,
        negMarks: -1.0,
        status: "review",
        difficulty: null,
        platform: ["desktop", "android", "ios"]
      }
    }
  },
  data() {
    return {
      importing: false,
      loading: false,
      loadingError: null,
      questions: [],
      searchedQuestions: [],
      query: null,
      q: this.getModel()
    }
  },
  mounted() {
    this.getQuestions();
  }
}
</script>

<style scoped>

</style>
