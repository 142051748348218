<template>
  <v-layout row wrap align-center class="pa-2">
    <v-flex xs12 class="text-xs-center">
      <v-layout>
        <v-flex xs6 class="mr-1">
          <v-file-input v-model="files" prepend-icon="camera" dense outlined accept="image/*" label="File input" @change="loadImage"/>
        </v-flex>
        <v-flex xs6 style="height: 60px">
           <v-select v-model="ftpHost" outlined dense :items="ftpHosts" outline label="Select Image HOST"/>
        </v-flex>
      </v-layout>
      <v-layout>
        <v-flex xs4 class="pa-1">
            <v-text-field outlined dense label="Quality" v-model="edit.quality" type="number"/>
        </v-flex>
        <v-flex xs4 class="pa-1">
          <v-text-field outlined dense label="Minimum Size Width" v-model="edit.minimumSize" type="number"/>
        </v-flex>
        <v-flex xs4 class="pa-1">
          <v-text-field outlined dense label="Maximum Size Width" v-model="edit.maximumSize" type="number"/>
        </v-flex>
      </v-layout>
      <v-switch v-model="copyright" :disabled="ftpHost === 'iron-image.cdn.examgoal.com'" label="Cut Image"/>
      <div v-if="percentComplete > 0 && percentComplete !== 100">
        <v-progress-linear v-model="percentComplete" height="10" color="green"></v-progress-linear>
      </div>
      <div class="text-xs-left">
        <v-btn
                large
                color="red"
                text
                outlined
                class="white--text"
                @click="submit"
                :loading="uploading"
                :disabled="uploading || buildingImages"
        >
          Upload
          <v-icon right dark>cloud_upload</v-icon>
        </v-btn>
        <v-btn
                large
                color="blue"
                text
                outlined
                class="white--text"
                @click="createImages"
                :loading="buildingImages"
                :disabled="buildingImages"
        >
          Build Image
        </v-btn>
        <v-btn
                large
                text
                outlined
                color="pink"
                class="white--text"
                @click="clearAll"
        >
          RESET ALL
        </v-btn>
      </div>
      <v-layout row wrap v-if="uploadedFiles.length > 0" class="ma-1" style="margin: 10px auto">
        <v-flex xs6>
          <v-text-field
            class="mr-1"
            v-model="alt"
            label="Image Alt Text"
            outlined
            dense
            hide-details
            style="margin-top: 5px;"
          />
        </v-flex>
        <v-flex xs6>
          <v-text-field
            class="ml-1"
            v-model="maxHeight"
            label="Max Height"
            outlined
            dense
            hide-details
            style="margin-top: 5px;"
          />
        </v-flex>
        <v-divider/>
        <v-flex xs12>
          <v-textarea
                  class="mt-1"
                  ref="textArea"
                  v-if="uploadedFiles.length > 0"
                  :value="imgTag"
                  outlined
                  dense
                  hide-details
                  rows="4"/>
        </v-flex>
        <v-btn fab small color="red" class="mt-1" dark @click="copy">
          <v-icon>file_copy</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
    <v-flex xs12 class="mt-1" v-if="previewFiles.length > 0 && uploadedFiles.length === 0">
      <div style="border: 1px solid #f1f1f1">
        <div class="text-center">
          <h2>Compose Image Preview</h2>
        </div>
        <div class="text-center">
           <img :src="previewFiles[previewPosition].data" style="max-width: 80%;height: auto;margin: 5px auto">
           <div class="mb-1">Size: {{Number(previewFiles[previewPosition].size / 1000).toFixed(2)+"KB"}}</div>
        </div>
        <v-divider/>
        <v-layout row wrap justify-center style="max-width: 80%;margin: 10px auto">
          <v-btn v-for="(img, i) in previewFiles" :color="previewPosition === i ? 'green' : 'blue'" :key="i" text outlined small @click="previewPosition = i">{{img.width}} <v-icon v-if="previewPosition === i" color="green" small>check</v-icon></v-btn>
        </v-layout>
      </div>
    </v-flex>
    <v-flex xs12 class="pa-1" v-if="uploadedFiles.length > 0">
      <div style="border: 1px solid #f1f1f1">
        <div class="text-center">
          <h2>Uploaded Image Preview</h2>
        </div>
        <div class="text-center">
          <img :src="uploadedFiles[upPreviewPosition].url" style="max-width: 80%;height: auto;margin: 5px auto">
          <div class="mb-1">Size: {{Number(uploadedFiles[upPreviewPosition].size / 1000).toFixed(2)+"KB"}}</div>
        </div>
        <v-divider/>
        <v-layout row wrap justify-center style="max-width: 80%;margin: 10px auto">
          <v-btn v-for="(img, i) in uploadedFiles" :color="upPreviewPosition === i ? 'green' : 'blue'" :key="i" text outlined small @click="upPreviewPosition = i">{{img.dimensions}} <v-icon v-if="upPreviewPosition === i" color="green" small>check</v-icon></v-btn>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
  import jimp from 'jimp';

  export default {
  name: "ImageUpload",
  computed: {
    imgTag() {
      if(this.uploadedFiles.length === 1){
        return `<img ${this.alt ? 'alt="'+this.alt+'"' : ``} src="${this.uploadedFiles[this.uploadedFiles.length - 1].url}" style="max-width: 100%;${(this.maxHeight
                ? !isNaN(this.maxHeight)
                        ? "max-height:" + this.maxHeight + "px;"
                        : "max-height:" + this.maxHeight + ";"
                : "")}height: auto;display: block;margin: 0 auto;max-height: 40vh" loading="lazy"/>`;
      }
      return `<picture>${this.uploadedFiles.filter((el, i) => i !== this.uploadedFiles.length - 1).map(el => {
          return `<source media="(max-width: ${el.maxWidth}px)" srcset="${el.url}"/>`}).join('')}<img ${this.alt ? 'alt="' + this.alt + '"' : ''} src="${this.uploadedFiles[this.uploadedFiles.length - 1].url}" style="max-width: 100%;${(this.maxHeight
              ? !isNaN(this.maxHeight)
                      ? "max-height:" + this.maxHeight + "px;"
                      : "max-height:" + this.maxHeight + ";"
              : "")}height: auto;display: block;margin: 0 auto;max-height: 40vh" loading="lazy"/></picture>`;
    },
    previewPosition: {
      set(v){
        this.position.preview = v;
      },
      get(){
        return ((this.previewFiles.length - 1) >= this.position.preview) ? this.position.preview : 0;
      }
    },
    upPreviewPosition: {
      set(v){
        this.position.uploadPreview = v;
      },
      get(){
        return ((this.uploadedFiles.length - 1) >= this.position.uploadPreview) ? this.position.uploadPreview : 0;
      }
    }
  },
  methods: {
    submit() {
      this.uploadedFiles = [];
      if (!this.files) {
        this.$message.error("Select Image First!");
        return;
      }
      this.uploading = true;
      let fo = new FormData();
      fo.append("image", this.files);
      fo.append("edit", JSON.stringify({...this.edit, sizes: this.fileSizes}));
      this.$http
        .post(this.copyright ? "/upload/image/watermark" : "/upload/image/default", fo, {
          params: {host: this.ftpHost},
          onUploadProgress: progressEvent =>
            (this.percentComplete = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            ))
        })
        .then(res => {
          this.uploading = false;
          let json = res.data;
          if (json.statusCode === 0) {
            this.url = json.url;
            this.uploadedFiles = json.files;
            this.$message.success("Upload Successful!");
            this.files = null;
          } else {
            this.$message.error("Unknown Error Occurred Try Again!");
          }
        })
        .catch(err => {
          this.uploading = false;
          this.$message.error("Check Your Internet Connection!");
        });
    },
    copy() {
      const textArea = this.$refs['textArea']['$refs']['input'];
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.alert = true;
    },
    loadImage(){
      this.clearAll()
      const fileReader = new FileReader();
      fileReader.onload =  (e) =>{
        this.imageBuffer = e.target.result;
        this.createImages();
      };
      fileReader.readAsArrayBuffer(this.files);
    },
    getImageSizes(w, maxWidth, minimumWidth){
      let sizes = [];
      let maxSize = maxWidth && maxWidth !== - 1 ? maxWidth : 1980;
      let minimumSize = minimumWidth && minimumWidth !== -1 ? minimumWidth : 500;
      let sta = minimumSize;
      while ((sta - 180) <= maxSize){
        if(w >= sta){
          sizes.push([sta, sta - 180]);
        }
        sta = sta + 180;
      }
      if(w < minimumSize){
        sizes.push([w, w]);
      }
      return sizes;
    },
    getBlobURLBuffer(buffer){
      const blob = new Blob([buffer], {type: 'application/octet-stream'}); // pass a useful mime type here
      return {url: URL.createObjectURL(blob), size: blob.size};
    },
    createImages(){
      if(!this.imageBuffer){
        this.$message.error("No Image File Found");
        return false;
      }
      this.buildingImages = true;
      new Promise(async (resolve, reject)=> {
        try {
          let image = await jimp.read(this.imageBuffer);
          let files = [];
          this.fileSizes = this.getImageSizes(image.getWidth(), Number(this.edit.maximumSize), Number(this.edit.minimumSize));
          let file = await Promise.all(this.fileSizes.map(el=>{
            return new Promise(async (resolve, reject) => {
              try {
                let image = await jimp.read(this.imageBuffer);
                let im = await image.resize(el[0], jimp.AUTO).quality(Number(this.edit.quality));
                const bb = this.getBlobURLBuffer(await im.getBufferAsync(jimp.AUTO));
                return resolve({width: im.getWidth()+'x'+im.getHeight(), data: bb.url, portWidth: el[1], size: bb.size})
              }catch (e) {
                return reject(e);
              }
            })
          }));
          files = files.concat(file);
          return resolve(files);
        }catch (e) {
          return reject(e);
        }
      }).then(res=>{
        this.buildingImages = false;
        this.previewFiles = res;
      }).catch(err=>{
        this.buildingImages = false;
        this.$message.error("Failed to Build Images! Try Again");
      });
    },
    clearAll(){
      this.uploadedFiles = [];
      this.previewFiles = [];
      this.imageBuffer = [];
      this.fileSizes = [];
    }
  },
  data() {
    return {
      files: null,
      ftpHost: "imagex.cdn.examgoal.net",
      ftpHosts: [
        {
          text: "IMAGE EXAMGOAL (image.cdn.examgoal.net)",
          value: "image.cdn.examgoal.net"
        },
        {
          text: "IMAGEX (imagex.cdn.examgoal.net renamed from imagex.gateclass.com)",
          value: "imagex.cdn.examgoal.net"
        },
        {
          text: "IMAGE NEETCARE (neetcare-image.cdn.examgoal.net)",
          value: "neetcare-image.cdn.examgoal.net"
        }
      ],
      buildingImages: false,
      percentComplete: 0,
      uploaded: false,
      uploading: false,
      url: null,
      alt: null,
      uploadedFiles: [],
      previewFiles: [],
      fileSizes: [],
      previewCurrentImage: 0,
      maxHeight: null,
      imageBuffer: null,
      position:{
        preview: 0,
        uploadPreview: 0,
      },
      edit: {
        quality: 70,
        minimumSize: -1,
        maximumSize: -1
      },
      copyright: false
    };
  }
};
</script>

<style scoped>
.in {
  max-width: 100%;
  border: 2px solid #2196f3;
  border-radius: 4px;
  padding: 14px 6px;
}
</style>
