<template>
  <v-card class="mt-1 mb-1" dark outlined>
    <h3 class="pa-3">Platform Specification</h3>
    <v-divider/>
    <v-card-text>
      <v-layout align-center>
        <v-flex xs3>
          <v-checkbox
              dense
              v-model="test.platform"
              label="Web"
              color="red"
              value="web"
              multiple
              hide-details
          ></v-checkbox>
        </v-flex>
        <v-flex xs3>
          <v-checkbox
              dense
              v-model="test.platform"
              label="Desktop"
              color="red"
              value="desktop"
              multiple
              hide-details
          ></v-checkbox>
        </v-flex>

        <v-flex xs3>
          <v-checkbox
              v-model="test.platform"
              dense
              label="Android"
              color="red"
              value="android"
              multiple
              hide-details
          ></v-checkbox>
        </v-flex>
        <v-flex xs3>
          <v-checkbox
              dense
              v-model="test.platform"
              label="IOS"
              color="red"
              value="ios"
              multiple
              hide-details
          ></v-checkbox>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Platform",
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  computed: {
    test() {
      return this.value;
    }
  },
}
</script>

<style scoped>

</style>
