<template>
  <div>
    <v-card outlined class="mt-1 mb-1">
      <v-card-title>Sections</v-card-title>
      <v-card-text v-for="(item, i) in test.sections" :key="i">
        <LangFieldEditor class="ma-1" v-model="item.title" label="Title"/>
        <LangFieldEditor class="ma-1" v-model="item.description" text-area label="Description"/>
        <v-layout wrap class="pa-1">
          <v-flex xs12 md6 lg3 class="pt-1">
            <v-text-field v-model="item.totalQuestions" type="number" label="Total Questions"
                          outlined hide-details dense/>
          </v-flex>
          <v-flex xs12 md6 lg3 class="pt-1">
            <v-text-field @input="() => onSectionMarksInput(item)" v-model="item.maxMarks" type="number" label="Max Marks"
                          outlined hide-details dense/>
          </v-flex>
          <v-flex xs12 md6 lg3 class="pa-1">
            <v-text-field @input="() => onSectionNegMarksInput(item)" v-model="item.maxNegMarks" type="number"
                          label="Max Negative Marks" outlined hide-details dense/>
          </v-flex>
          <v-flex xs12 md6 lg3 class="pa-1">
            <v-text-field @input="() => onSectionTimeAllottedInput(item)" v-model="item.timeAllotted" type="number"
                          label="Time" outlined hide-details dense/>
          </v-flex>
        </v-layout>
        <v-layout class="pa-1" align-center justify-space-between>
          <h3>Optional</h3>
          <v-btn outlined text @click="item.optional.push({start: 0, end: 0, max: 0})">ADD NEW</v-btn>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 lg3 class="pa-1" v-for="(item1, i) in item.optional" :key="i">
            <v-card>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 lg6 class="pa-1">
                    <v-text-field dense outlined hide-details type="number" v-model="item1.start" label="Start"/>
                  </v-flex>
                  <v-flex xs12 lg6 class="pa-1">
                    <v-text-field dense outlined hide-details type="number" v-model="item1.end" label="End"/>
                  </v-flex>
                </v-layout>
                <v-text-field class="ma-1" dense outlined hide-details type="number" v-model="item1.max" label="Max"/>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-spacer/>
                <v-btn small outlined @click="item.optional.splice(i, 1)">DELETE</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
        <draggable tag="div" class="layout wrap" v-model="item.questions" :group="item.title.en">
          <v-flex class="pa-1" xs12 lg4 v-for="(item1, i) in item.questions" :key="item1.questionId">
            <v-card dark>
              <v-card-title>
                <v-avatar
                    color="red"
                    size="30"
                >
                  <span class="white--text">{{ i }}</span>
                </v-avatar>
                <span class="pl-2">{{ item1.from === "pq" ? "Past Year Question" : "Question" }}</span><v-chip class="ml-2">{{item1.data.type.toUpperCase()}}</v-chip></v-card-title>
              <v-divider/>
              <v-card-text style="height: 70px" v-html="item1.data.question['en'].content.substring(0, 120)"/>
              <v-divider/>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 lg6 class="pa-1">
                    <v-text-field dense outlined hide-details type="number" v-model="item1.marks" label="Marks"/>
                  </v-flex>
                  <v-flex xs12 lg6 class="pa-1">
                    <v-text-field dense outlined hide-details type="number" v-model="item1.negMarks"
                                  label="Negative Marks"/>
                  </v-flex>
                </v-layout>
                <v-text-field class="ma-1" dense outlined hide-details type="number" v-model="item1.timeAllotted"
                              label="Time"/>
              </v-card-text>
              <v-divider/>
              <v-card-actions>
                <v-spacer/>
                <v-btn small outlined @click="item.questions.splice(i, 1)">DELETE</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </draggable>
        <div class="text-center pa-2">
          <v-btn large @click="() => { currentSection = i; selectDialog = true; }" color="green" dark>ADD QUESTION</v-btn>
          <v-btn large class="ml-4" @click="() => test.sections.splice(i, 1)" color="red" dark>DELETE SECTION</v-btn>
          <v-btn large class="ml-4" :disabled="item.questions.length <= 0" @click="() => item.questions = []">REMOVE ALL QUESTIONS</v-btn>
        </div>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="addNewSection" outlined color="green">ADD NEW SECTION</v-btn>
      </v-card-actions>
    </v-card>
    <QuestionSelectDialog v-model="selectDialog"
                          @onSelect="(v) => { selectDialog = false; if(test.sections[currentSection].questions.filter(el=> el['questionId'] === v['questionId']).length === 0)  {
                            const item = test.sections[currentSection];
                            item.questions.push(v); onSectionMarksInput(item); onSectionNegMarksInput(item); onSectionTimeAllottedInput(item) } }"/>

  </div>

</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import QuestionSelectDialog from "@/components/QuestionSelectDialog";
import draggable from 'vuedraggable'

export default {
  name: "TestSections",
  components: { LangFieldEditor, QuestionSelectDialog, draggable},
  props: {
    value: {
      type: Object,
      required: true,
      default: null
    },
  },
  methods: {
    onSectionMarksInput(item) {
      if (item.questions.length === 0) return;
      const marks = Number(item.maxMarks) / item.questions.length;
      item.questions.forEach(el => {
        el.marks = parseFloat(marks.toFixed(2))
      })
    },
    onSectionNegMarksInput(item) {
      if (item.questions.length === 0) return;
      const marks = Number(item.maxNegMarks) / item.questions.length;
      item.questions.forEach(el => {
        el.negMarks = parseFloat(marks.toFixed(2))
      })
    },
    onSectionTimeAllottedInput(item) {
      if (item.questions.length === 0) return;
      const time = Number(item.timeAllotted) / item.questions.length;
      item.questions.forEach(el => {
        el.timeAllotted = Math.round(time)
      })
    },
    getSectionModel() {
     return {
       title: {en: null},
       description: {en: null},
       maxMarks: 0,
       maxNegMarks: 0,
       timeAllotted: 0,
       totalQuestions: 0,
       optional: [],
       questions: []
     }
    },
    addNewSection() {
      this.test.sections.push(this.getSectionModel())
    },
  },
  data() {
    return {
      currentSection: 0,
      selectDialog: false
    }
  },
  computed: {
    test() {
      return this.value;
    }
  },
}
</script>

<style scoped>

</style>
