<template>
  <div class="pa-1">
    <TestQuestionMetadata v-model="q" ref="meta" @type-change="() => $refs.multiLang.prepareOptions()" class="ma-1"/>
    <v-card class="ma-1">
      <MultiLanguageQuestionComponent ref="multiLang" v-model="q"/>
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn
            large
            color="red"
            dark
            :loading="sending"
            :disabled="sending"
            @click="submit"
        >Submit
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="questionGetting" persistent width="300">
      <v-card color="red" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="questionGettingError" persistent width="300">
      <v-card color="red" dark>
        <v-card-text class="text-xs-center">
          <h2>Unknown Error Occurred</h2>
          <v-btn class="ma-2" flat outline @click="getQuestion">Retry</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TestQuestionMetadata from "@/components/tests/TestQuestionMetadata";
import LangQuestionComponent from "@/components/LangQuestionComponent";
import MultiLanguageQuestionComponent from "@/components/MultiLanguageQuestionComponent";

export default {
  name: "createOrUpdate",
  components: {MultiLanguageQuestionComponent, LangQuestionComponent, TestQuestionMetadata},
  data() {
    return {
      currentLang: 'en',
      sending: false,
      langDialog: false,
      questionGetting: false,
      questionGettingError: false,
      q: {
        country: null,
        examGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        difficulty: null,
        type: null,
        languages: ["en"],
        question: {
          en: this.getLangQuestionModel()
        }
      }
    }
  },
  methods: {
    getLangQuestionModel() {
      return {
        comprehension: null,
        direction: null,
        content: null,
        options: [],
        correct_options: [],
        answer: null,
        explanation: null
      }
    },
    async submit() {
      this.sending = true
      try {
        const {data} = await this.$testHttp.post("/question", {...this.q})
        if (data['statusCode'] === 0) {
          if (this.$route.params.id === "create") {
            this.$set(this.q, "status", "review");
            for (let i in this.q.question) {
              this.$set(this.q.question, i, {
                ...this.getLangQuestionModel(),
                comprehension: this.q.question[i].comprehension,
                direction: this.q.question[i].direction
              });
            }
            this.$refs.multiLang.prepareOptions()
          }
          this.$message.success('Successful')
        } else {
          this.$message.error('Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.response ? e.response.data['error']['message'] : e.toString())
      }
      this.sending = false
    },
    getQuestion() {
      if (this.$route.params.id === "create") return
      this.questionGetting = true;
      this.questionGettingError = false;
      this.$testHttp
          .get("/question/" + this.$route.params.id)
          .then((res) => {
            this.questionGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.$message.success("Success!")
              this.$set(this, "q", json.data);
              this.$refs.meta.populating = true
              setTimeout(this.$refs["meta"].populate, 1000);
              this.$refs.multiLang.mathJaxRefresh()
            } else {
              this.questionGettingError = true;
            }
          })
          .catch((err) => {
            this.questionGetting = false;
            this.questionGettingError = true;
          });
    }
  },
  mounted() {
    this.getQuestion()
  }
}
</script>

<style scoped>

</style>
