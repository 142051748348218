<template>
  <div class="pa-1">
    <div class="text-right">
      <v-btn large color="green" :disabled="importing" :loading="importing" @click="importQ">IMPORT</v-btn>
    </div>
    <v-layout ref="el" wrap>
      <v-flex xs12 lg6 class="pa-1" v-for="(q, i) in results" :key="q.question_id">
        <v-card>
          <v-list-item>
            <v-avatar
                color="red"
                size="36"
            >
              <span class="white--text text-h6">{{ i + 1 }}</span>
            </v-avatar>
            <v-list-item-content class="pl-2">
              <v-list-item-title>{{q.title}}</v-list-item-title>
              <v-list-item-subtitle>{{new Date(q.createdAt).toLocaleString()}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-text v-html="q.excerpt"/>
          <div v-if="q.content[Object.keys(q.content)[0]].hasOwnProperty('content')">
            <v-divider/>
            <v-card-title>Content</v-card-title>
            <v-card-text v-html="q.content[Object.keys(q.content)[0]].content"/>
          </div>
          <div v-if="q.content[Object.keys(q.content)[0]].hasOwnProperty('options')">
            <v-divider/>
            <template v-for="item in q.content[Object.keys(q.content)[0]].options">
              <v-card-title>Option {{item.identifier}}</v-card-title>
              <v-card-text v-html="item.content"/>
            </template>
          </div>
          <v-divider/>
          <v-card-title>Explanation</v-card-title>
          <v-card-text v-html="q.content[Object.keys(q.content)[0]].explanation"/>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn small outlined color="red" target="_blank" :to="'/freelance/'+$route.params.id+'/exp/'+q.question_id">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="text-center pa-4" v-if="loading">
      <v-progress-circular indeterminate/>
    </div>
  </div>
</template>

<script>
export default {
  name: "questions",
  methods: {
    async loadQuestions() {
      this.loading = true
      try {
        const {data} = await this.$http.get("/freelance/" + this.$route.params.id + "/explanation/list")
        if (data['statusCode'] === 0) {
          this.results = data['results']
          setTimeout(() => {
            if (window.MathJax) window.MathJax.typesetPromise([this.$refs.el])
          }, 500);
        } else {
          this.$message.error("Unknown Error")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.loading = false
    },
    async importQ() {
      this.importing = true
      try {
        const {data} = await this.$http.post("/freelance/" + this.$route.params.id + "/explanation/import")
        if (data['statusCode'] === 0) {
          this.$message.success("Successful")
          this.loadQuestions();
        } else {
          this.$message.error("Unknown Error")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.importing = false
    }
  },
  data() {
    return {
      loading: false,
      importing: false,
      results: []
    }
  },
  mounted() {
    this.loadQuestions()
  },
  beforeDestroy() {
    window.MathJax.typesetClear([this.$refs.el])
  }
}
</script>

<style scoped>

</style>
