<template>
  <div v-if="q">
    <v-layout class="pa-1">
      <v-flex style="flex-grow: 1;" >
        <v-btn
            color="primary"
            rounded
            outlined
            class="mr-1"
            @click="currentLang = lang"
            :key="lang"
            v-for="lang in q.languages"
            :disabled="currentLang === lang"
        >
          {{$getLangName(lang)}}
          <v-btn
              small
              color="red"
              text
              style="height: 20px"
              @click.stop="removeLang(lang)"
              v-if="q.languages.length > 1 && lang !== 'en'"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-btn>
      </v-flex>
      <div>
        <v-dialog v-model="langDialog" width="400">
          <template v-slot:activator="{on}">
            <v-btn v-on="on" fab outlined small color="red">
              <v-icon>add</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
                :key="item.value"
                @click="addLang(item.value)"
                v-for="item in $availLanguages.filter((el)=> q.languages.indexOf(el.value) === -1)"
            >
              <v-list-item-title>{{$getLangName(item.value)}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-dialog>
      </div>
    </v-layout>
    <v-divider/>
    <LangQuestionComponent
        :key="i"
        v-for="(n, i) in q.languages"
        :type="q.type"
        :lang="n"
        :question="q.question"
        v-bind:style="currentLang === n ? {display: 'block'} : {display : 'none'}"
    />
  </div>
</template>

<script>
import LangQuestionComponent from "@/components/LangQuestionComponent";

export default {
  name: "MultiLanguageQuestionComponent",
  components: {LangQuestionComponent},
  props: {
    value: {
      type: Object,
      default: null
    },
  },
  methods: {
    getLangQuestionModel() {
      return {
        comprehension: null,
        direction: null,
        content: null,
        options: [],
        correct_options: [],
        answer: null,
        explanation: null
      }
    },
    removeLang(n) {
      if (this.q.languages.length === 1 || this.q.languages.indexOf(n) === -1)
        return false;
      let pos = this.q.languages.indexOf(n);
      this.q.languages.splice(pos, 1);
      this.$delete(this.q.question, n);
      if (this.currentLang === n) {
        this.currentLang = this.q.languages[0];
      }
    },
    addLang(v) {
      this.langDialog = false;
      if (this.q.languages.indexOf(v) === -1) {
        this.q.languages.push(v);
      }
      this.q.languages.forEach((el) => {
        if (!this.q.question[el]) {
          const lq = this.q.question[this.q.languages[0]]
          this.$set(this.q.question, el, JSON.parse(JSON.stringify({
            ...this.getLangQuestionModel(),
            ...lq
          })));
          this.prepareOptions();
        }
      });
    },
    prepareOptions() {
      if (["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1) {
        for (let key in this.q.question) {
          if (
              this.q.question[key].options.length === 0 ||
              this.q.type === "t/f"
          ) {
            this.q.question[key].options = [];
            switch (this.q.type) {
              case "t/f":
                this.q.question[key].options.push(
                    {
                      identifier: "A",
                      content:
                          key === "hi" ? "सच" : key === "bn" ? "সত্য" : "TRUE",
                    },
                    {
                      identifier: "B",
                      content:
                          key === "hi"
                              ? "असत्य"
                              : key === "bn"
                                  ? "মিথ্যা"
                                  : "FALSE",
                    }
                );
                break;
              default:
                this.q.question[key].options.push(
                    {
                      identifier: "A",
                      content: null,
                    },
                    {
                      identifier: "B",
                      content: null,
                    },
                    {
                      identifier: "C",
                      content: null,
                    },
                    {
                      identifier: "D",
                      content: null,
                    }
                );
            }
          }
        }
      } else {
        for (let key in this.q.question) {
          if (
              !this.q.question[key].options ||
              this.q.question[key].options.length !== 0
          ) {
            this.q.question[key].options = [];
          }
        }
      }
    }
  },
  data() {
    return {
      currentLang: null,
      langDialog: false
    }
  },
  computed: {
    q() {
      if ((!this.currentLang && this.value) || this.value.languages.indexOf(this.currentLang) === -1) this.currentLang = this.value.languages[0]
      return this.value
    }
  }
}
</script>

<style scoped>

</style>
