<template>
    <div class="pa-1">
        <RequestStats class="ma-1"/>
        <v-layout row wrap justify-end class="ma-1">
            <v-flex xs3 class="pa-1">
               <v-card dark color="green darken-2">
                   <v-card-text>
                       View all blocked ips and unblock ip
                   </v-card-text>
                   <v-card-actions>
                       <v-spacer/>
                       <v-btn @click="()=> $router.push('/security/block/list')">SHOW ALL</v-btn>
                   </v-card-actions>
               </v-card>
            </v-flex>
        </v-layout>
        <v-layout row wrap class="ma-1">
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="60" title="Last 5 Minutes" freq="5m"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="120" title="Last 10 Minutes" freq="15m"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="180" title="Last 30 Minutes" freq="30m"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="360" title="Last 1 Hour" freq="60m"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="600" title="Last 2 Hour" freq="2h"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="600" title="Last 6 Hour" freq="6h"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="1200" title="Last 12 Hour" freq="12h"/>
            </v-flex>
            <v-flex xs4 class="pa-1">
                <IPRequestList :interval="1200" title="Last 24 Hour" freq="24h"/>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
    import IPRequestList from "./components/IPRequestList";
    import RequestStats from "./components/RequestStats";
    export default {
        name: "index",
        components: {RequestStats, IPRequestList}
    }
</script>

<style scoped>

</style>
