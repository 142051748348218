<template>
  <div class="pa-2">
    <v-card class="mt-2 mb-2">
      <v-card-text>
        <v-text-field label="Search" outlined dense hide-details v-model="q" @input="() => getDocs(0)"/>
      </v-card-text>
    </v-card>
    <v-layout wrap>
      <v-flex xs12 md12 lg6 v-for="question in docs" :key="question.documentId">
        <v-card color="green" dark class="ma-1">
          <div>
            <div class="pa-4">{{ question.title }}</div>
            <v-divider/>
            <div class="pa-4" style="height: 140px;overflow: hidden;font-size: 12px">{{ question.content }}</div>
            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="() => { deleteId = question.documentId; deleteDialog = true}" color="red"  text small outlined>
                DELETE
              </v-btn>
              <v-btn :to="`/freelance/${$route.params.id}/docs/${question.documentId}`" text small outlined>
                UPDATE
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pa-4 text-sm-center" v-if="!loading && !noMore && docs.length > 0">
      <v-btn color="primary" @click="()=> getDocs(page + 1)">LOAD MORE</v-btn>
    </div>
    <div class="pa-4 text-center">
      <h3 v-if="loadingError">{{ loadingError }}</h3>
      <v-btn @click="getDocs" v-if="loadingError">Retry</v-btn>
    </div>
    <v-dialog v-model="deleteDialog" max-width="400">
       <v-card>
         <v-card-title>Confirm</v-card-title>
         <v-card-text>Do you really want to delete this doc? It can't be undone</v-card-text>
         <v-card-actions>
           <v-spacer/>
           <v-btn text @click="()=> {this.deleteId = null;this.deleteDialog = false;}" color="green">Cancel</v-btn>
           <v-btn text @click="deleteDoc" color="red">YES</v-btn>
         </v-card-actions>
       </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PastQuestionMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "pastQuestions",
  components: {
    PastQuestionMetadata
  },
  methods: {
    async deleteDoc() {
       this.deleteDialog = false;
       try {
         const {data} = await this.$http.delete("/freelance/" + (this.$route.params.id) + "/docs/"+this.deleteId);
         if(data.statusCode === 0){
           this.$message.success("Successful");
           this.docs.splice(this.docs.indexOf(this.docs.filter(el=> el.documentId === this.deleteId)[0]), 1)
           this.deleteId = null;
         }else {
           this.$message.error(data.error ? data.error.message : "Unknown Error")
         }
       }catch (e) {
         this.$message.error(e.toString())
       }
    },
    async getDocs(page) {
      this.page = page || 0
      if(this.page === 0){
        this.docs = []
        this.noMore = false
      }
      this.loading = true;
      try {
        const {data} = await this.$http.get("/freelance/" + (this.$route.params.id) + "/docs", {params: {q: this.q, page: this.page}});
        if (data.statusCode === 0) {
          this.docs = [...this.docs, ...data.results];
          if(data.results.length < 10){
            this.noMore = true
          }
        } else {
          this.loadingError = data.error ? data.error.message : "Unknown Error";
        }
      } catch (e) {
        this.loadingError = e.toString();
      }
      this.loading = false;
    }
  },
  data() {
    return {
      q: null,
      page: 0,
      noMore: false,
      deleteDialog: false,
      deleteId: null,
      importing: false,
      loading: false,
      loadingError: null,
      docs: []
    }
  },
  mounted() {
    this.getDocs();
  }
}
</script>

<style scoped>

</style>
