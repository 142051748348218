<template>
  <v-menu v-model="menu" :nudge-width="400" max-width="600" offset-y>
    <template v-slot:activator="{ on }">
      <v-btn outlined class="ml-1" small v-on="on" @click="mathJaxRefresh">
        MathType
      </v-btn>
    </template>
    <v-card ref="dialog">
      <v-card-title class="blue white--text">MathType Shortcuts</v-card-title>
      <v-card-text>
        <v-layout wrap justify-start class="pa-2">
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\therefore $$', true, true)"
            >$$ \therefore $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\because $$', true, true)"
            >$$ \because $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\Rightarrow $$', true, true)"
            >$$ \Rightarrow $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\ne $$', true, true)">$$ \ne $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon
                   outlined @click.stop="change('$$ \\to $$', true, true)">$$ \to $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\pm $$', true, true)">$$ \pm $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\times $$', true, true)"
            >$$ \times $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\ge $$', true, true)">$$ \ge $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\le $$', true, true)">$$ \le $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\cong $$', true, true)"
            >$$ \cong $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\simeq $$', true, true)"
            >$$ \simeq $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\pi $$', true, true)">$$\pi $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\theta $$', true, true)"
            >$$\theta $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\alpha $$', true, true)"
            >$$\alpha $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\beta $$', true, true)">$$\beta $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\delta $$', true, true)"
            >$$\delta $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\varepsilon $$', true, true)"
            >$$\varepsilon $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\phi $$', true, true)">$$\phi $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\varphi $$', true, true)"
            >$$\varphi $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\gamma $$', true, true)"
            >$$\gamma $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\lambda $$', true, true)"
            >$$\lambda $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\mu $$', true, true)">$$\mu $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\rho $$', true, true)">$$\rho $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\sigma $$', true, true)"
            >$$\sigma $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\tau $$', true, true)">$$\tau $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\upsilon $$', true, true)"
            >$$\upsilon $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\psi $$', true, true)">$$\psi $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\Delta $$', true, true)"
            >$$\Delta $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\Lambda $$', true, true)"
            >$$\Lambda $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\Sigma $$', true, true)"
            >$$\Sigma $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\bot $$', true, true)">$$ \bot $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\wedge $$', true, true)"
            >$$ \wedge $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\vee $$', true, true)">$$ \vee $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\in $$', true, true)">$$ \in $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\notin $$', true, true)"
            >$$ \notin $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\cup $$', true, true)">$$ \cup $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\cap $$', true, true)">$$ \cap $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\subset $$', true, true)"
            >$$ \subset $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\supset $$', true, true)"
            >$$ \supset $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\subseteq $$', true, true)"
            >$$ \subseteq $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\supseteq $$', true, true)"
            >$$ \supseteq $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\not\\subset $$', true, true)"
            >$$ \not\subset $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\emptyset $$', true, true)"
            >$$\emptyset $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$\\ell $$', true, true)">$$\ell $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\div $$', true, true)">$$ \div $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\mp $$', true, true)">$$ \mp $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\equiv $$', true, true)"
            >$$ \equiv $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$\\infty $$', true, true)"
            >$$\infty $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn text icon outlined @click.stop="change('$$ \\sim $$', true, true)">$$ \sim $$</v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\propto $$', true, true)"
            >$$ \propto $$
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
                text
                icon
                outlined
                @click.stop="change('$$ \\supseteq $$', true, true)"
            >$$\Theta $$
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "MathTypeButton",
  props: {
    textArea: {
      type: Function,
      required: true
    }
  },
  methods: {
    change(tag, iend, only) {
      this.menu = false;
      const textarea = this.textArea();
      textarea.focus();
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const sel = textarea.value.substring(start, end);
      let replace = "";
      if (!only) {
        if (!iend) {
          replace = sel.concat("<".concat(tag).concat("/>"));
        } else {
          replace = "<"
              .concat(tag)
              .concat(">")
              .concat(sel)
              .concat("</")
              .concat(tag)
              .concat(">");
        }
      } else {
        replace = sel.concat(tag);
      }
      document.execCommand("insertText", false, replace);
    },
    mathJaxRefresh() {
      setTimeout(() => {
        if (window.MathJax) {
          window.MathJax.typesetPromise([this.$refs.dialog])
        }
      }, 200);
    }
  },
  data() {
    return {
      menu: false
    }
  },
  watch: {
    menu(newValue) {
      if (newValue) {
        this.mathJaxRefresh()
      } else {
        window.MathJax.typesetClear([this.$refs.dialog])
      }
    }
  }
}
</script>

<style scoped>

</style>
