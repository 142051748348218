<template>
  <div class="pa-1">
     <v-card class="ma-1">
       <v-card-text>
          <v-text-field outlined dense hide-details v-model="q" @input="() => getResults()" label="Search by email"/>
       </v-card-text>
     </v-card>
    <v-layout wrap>
      <v-flex xs12 md4 lg3 class="pa-1" v-for="user in results" :key="user.userId">
        <v-card>
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="user.picture"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{user.displayName}}</v-list-item-title>
              <v-list-item-subtitle>{{user.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn text outlined :to="`/users/${user.userId}`" small color="green darken-2">MANAGE</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      q: null,
      page: 0,
      isLoading: false,
      isLoadingError: false,
      isMoreToLoad: false,
      results: []
    }
  },
  methods: {
    async getResults(p) {
      this.page = p || 0
      this.isMoreToLoad = false
      this.isLoadingError = false
      this.isLoading = true
      if(this.page === 0) this.results = []
      try {
         const {data} = await this.$authHttp.get("/users", {params: {page: this.page, q: this.q}})
        if(data.statusCode === 0){
          this.results = [...this.results, ...data.results]
          this.isMoreToLoad = data.results.length >= 100
        }else {
          this.$message.error(data.error ? data.error.message : "Unknown Error")
        }
      } catch (e) {
        this.isLoadingError = true
        this.$message.error(e.toString())
      }
      this.isLoading = false
    }
  },
  mounted() {
     this.getResults()
  }
}
</script>

<style scoped>

</style>