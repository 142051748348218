<template>
  <div>
    <v-layout>
      <v-flex xs12 lg6 class="pa-1">
        <v-list-item dense>
          <v-list-item-title>Posteriszer</v-list-item-title>
          <v-list-item-action>
            <v-switch v-model="posteriszer"/>
          </v-list-item-action>
        </v-list-item>
        <v-list-item dense class="mb-2">
          <v-radio-group v-model="mode" dense label="Mode" hide-details row>
            <v-radio value="default" label="Default"/>
            <v-radio value="color" label="Color"/>
          </v-radio-group>
        </v-list-item>
      </v-flex>
      <v-flex xs12 lg6 class="pa-1">
        <v-list-item class="mb-2">
          <v-list-item-title>Watermark</v-list-item-title>
          <v-list-item-action>
            <v-switch v-model="watermark"/>
          </v-list-item-action>
        </v-list-item>
        <v-layout align-center class="pa-1">
          <h3>Colors</h3>
          <div style="width: 20px;height: 20px;border-radius: 50%;margin-left: 10px;"
               :style="{'background': color}"></div>
        </v-layout>
        <v-layout wrap>
          <div class="pa-1" v-for="col in colors" :key="col">
            <v-btn small :color="col" @click="color = col" :disabled="color === col">{{ col }}</v-btn>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-file-input v-model="files" :disabled="uploading" prepend-icon="camera" dense outlined accept="image/*"
                  label="File input" hide-details/>
    <v-layout justify-space-between>
      <v-btn class="mt-4" large outlined @click="previewLoaded" :loading="uploading" min-width="200" rounded :disabled="uploading">Upload</v-btn>
      <v-btn class="mt-4 ml-2" color="green" large outlined @click="submit" min-width="200" :loading="uploading" rounded
             :disabled="uploading || !key">Submit
      </v-btn>
    </v-layout>
    <v-layout align-center class="pt-2" v-if="previewUrl">
      <v-flex xs12 lg8 class="pa-1">
        <img :src="previewUrl" style="max-width: 100%;height: auto;margin: 0 auto;display: block;max-height: 60vh"/>
      </v-flex>
      <v-flex xs12 lg4 class="pa-1" v-if="url">
        <v-textarea
            class="mt-1"
            ref="textArea2"
            :value="this.url"
            outlined
            dense
            hide-details
            rows="4"/>
        <v-btn @click="() => copy($refs['textArea2']['$refs']['input'])" small outlined color="red" class="mt-1">COPY
        </v-btn>
        <v-textarea
            class="mt-1"
            ref="textArea"
            :value="imgTag"
            outlined
            dense
            hide-details
            rows="4"/>
        <v-btn @click="() => copy($refs['textArea']['$refs']['input'])" small outlined color="red" class="mt-1">COPY
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "ImageDrawingUpload",
  methods: {
    async previewLoaded() {
      this.uploading = true
      try {
        const fo = new FormData();
        fo.append("image", this.files);
        fo.append("edit", JSON.stringify({
          posteriszer: this.posteriszer,
          color: this.color,
          watermark: this.watermark,
          mode: this.mode
        }));
        const {data} = await this.$http.post('/image/drawing', fo);
        this.previewUrl = data['data']['previewUrl'];
        this.key = data['data']['key'];
        this.metadata = data['data']['metadata'];
        this.files = null
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.uploading = false
    },
    async submit() {
      this.uploading = true
      try {
        const {data} = await this.$http.post('/image/drawing_final', {key: this.key});
        this.flyUrl = data['data']['flyUrl'];
        this.url = data['data']['url'];
        this.key = null
        this.files = null
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.uploading = false
    },
    copy(textArea) {
      textArea.select();
      textArea.setSelectionRange(0, 99999);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();
      this.$message.success("Copied");
    },
    genImgTag(fly, url) {
      return `<img src="${fly}" data-orsrc="${url}" loading="lazy" style="max-width: 100%;height: auto;display: block;margin: 0 auto;max-height: 40vh">`;
    }
  },
  data() {
    return {
      color: '#3574f0',
      colors: ['#3574f0', '#909399'],
      watermark: true,
      posteriszer: false,
      mode: 'default',
      files: null,
      key: null,
      uploading: false,
      previewUrl: null,
      metadata: null,
      flyUrl: null,
      url: null
    }
  },
  computed: {
    imgTag() {
      if (this.flyUrl && this.url) return this.genImgTag(this.flyUrl, this.url, this.metadata['width'])
      return null
    },
  }
}
</script>

<style scoped>

</style>
