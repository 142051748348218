<template>
  <PermissionComponent
      :permissions="['past-question.editor']"
  >
    <PastQuestionMetadata class="ma-1" ref="meta" v-model="q" @type-change="() => { $refs.multiLang.prepareOptions() }"/>
    <v-layout wrap>
      <v-flex xs12 lg8 class="pl-1 pr-1">
        <Platform v-model="q"/>
      </v-flex>
      <v-flex xs12 lg4 class="pa-1">
        <v-card dark outlined>
          <h3 class="pa-3">Section Specification</h3>
          <v-divider/>
          <v-container>
            <v-layout align-center>
              <v-flex xs6>
                <v-checkbox
                    dense
                    v-model="q.section"
                    label="Chapter"
                    color="red"
                    value="chapter"
                    multiple
                    hide-details
                ></v-checkbox>
              </v-flex>
              <v-flex xs6>
                <v-checkbox
                    dense
                    v-model="q.section"
                    label="Paper"
                    color="red"
                    value="paper"
                    multiple
                    hide-details
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-card class="ma-1">
      <MultiLanguageQuestionComponent ref="multiLang" v-model="q"/>
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn
            large
            color="red"
            class="white--text"
            :loading="sending"
            :disabled="sending"
            @click="submit"
        >Submit
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="questionGetting" persistent width="300">
      <v-card color="red" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="questionGettingError" persistent width="300">
      <v-card color="red" dark>
        <v-card-text class="text-xs-center">
          <h2>Unknown Error Occurred</h2>
          <v-btn class="ma-2" flat outline @click="getQuestion">Retry</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </PermissionComponent>
</template>

<script>
import LangQuestionComponent from "@/components/LangQuestionComponent";
import PastQuestionMetadata from "@/components/metadata/PastQuestionMetadata";
import PermissionComponent from "../../components/PermissionComponent";
import MultiLanguageQuestionComponent from "@/components/MultiLanguageQuestionComponent";
import Platform from "@/components/PlatformComponent";

export default {
  components: {
    Platform,
    MultiLanguageQuestionComponent,
    PermissionComponent,
    LangQuestionComponent,
    PastQuestionMetadata
  },
  name: "new",
  methods: {
    getLangQuestionModel() {
      return {
        comprehension: null,
        direction: null,
        content: null,
        options: [],
        correct_options: [],
        answer: null,
        explanation: null
      }
    },
    submit() {
      if (
          !this.q.exam ||
          !this.q.subject ||
          !this.q.chapter ||
          !this.q.marks ||
          (!this.q.negMarks && this.q.negMarks !== 0) ||
          !this.q.type ||
          !this.q.year ||
          !this.q.paperTitle ||
          !this.q.timeAllotted ||
          !this.q.status
      ) {
        this.$message.error("Enter Required Fields")
        return false;
      }
      for (let ii in this.q.question) {
        let q = this.q.question[ii];
        if (
            ["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1 &&
            (!q.correct_options || q.correct_options.length === 0)
        ) {
          this.$message.error("Select Correct Options")
          return false;
        }
        if (["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1) {
          for (let opt in q.options) {
            if (!q.options[opt].content) {
              this.$message.error("Enter Question Options")
              return false;
            }
          }
        }
        if (
            !q.content ||
            (!q.answer &&
                ["integer", "subjective", "fill-blanks"].indexOf(this.q.type) !==
                -1)
        ) {
          this.$message.error("Enter Question Required Fields")
          return false;
        }
        if (!q.explanation) {
          this.q.question[ii].explanation = null;
        }
        if (!q.answer) {
          this.q.question[ii].answer = null;
        }
      }
      this.sending = true;
      let q = {
        ...this.q,
      };
      this.$pqhttp
          .post("/createOrUpdate", q)
          .then((res) => {
            this.sending = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.$message.success("Successful")
              if (this.$route.params.qid === "new" || this.$route.params.qid === "create") {
                this.$set(this.q, "status", "review");
                for (let i in this.q.question) {
                  this.$set(this.q.question, i, {
                    ...this.$refs.multiLang.getLangQuestionModel(),
                    comprehension: this.q.question[i].comprehension,
                    direction: this.q.question[i].direction
                  });
                }
                this.$refs.multiLang.prepareOptions();
              }
            } else {
              this.$message.error(json['error'] ? json['error']['message'] : "Unknown Error!")
            }
          })
          .catch((err) => {
            this.$message.error(err.toString())
            this.sending = false;
          });
    },
    getModel() {
      return {
        status: "review",
        country: null,
        examGroup: null,
        exam: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        year: null,
        paperId: null,
        paperTitle: null,
        timeAllotted: 0,
        marks: 0.0,
        negMarks: 0.0,
        languages: ["en"],
        difficulty: null,
        topic: null,
        type: null,
        question: { en: this.getLangQuestionModel() },
        section: ["paper"],
        platform: ["web", "desktop", "android", "ios"]
      }
    },
    getQuestion() {
      this.questionGetting = true;
      this.questionGettingError = false;
      this.$pqhttp
          .get("/" + this.$route.params.qid)
          .then((res) => {
            this.questionGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.$message.success("Success!")
              this.$set(this, "q", {...this.getModel(), ...json.data});
              setTimeout(this.$refs["meta"].populate, 1000);
            } else {
              this.questionGettingError = true;
            }
          })
          .catch((err) => {
            this.questionGetting = false;
            this.questionGettingError = true;
          });
    }
  },
  data() {
    return {
      sending: false,
      questionGetting: false,
      questionGettingError: false,
      q: this.getModel()
    }
  },
  mounted() {
    if (this.$route.params.qid !== "new") {
      this.getQuestion();
    }
  },
  watch: {
    "$route.params.qid": function (val) {
      if (val !== "new") {
        this.getQuestion();
      }
    }
  }
};
</script>

<style scoped>
.text-content {
  word-wrap: break-word;
}
</style>
