<template>
  <div class="pa-2">
    <v-card v-if="template">
      <v-card-text>
        <v-layout justify-end class="pa-1 pb-2">
          <v-btn color="blue" dark :loading="isLoading" :disabled="isLoading" @click="submit">SUBMIT</v-btn>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 lg6 class="pa-1">
            <v-text-field dense hide-details v-model="template.TemplateName" label="Template Name" outlined/>
          </v-flex>
          <v-flex xs12 lg6 class="pa-1">
            <v-text-field dense hide-details v-model="template.SubjectPart" label="SubjectPart" outlined/>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 lg6 class="pa-1">
            <v-textarea dense hide-details v-model="template.HtmlPart" label="Html" outlined rows="22" @input="renderHtml"/>
          </v-flex>
          <v-flex xs12 lg6 class="pa-1">
            <iframe ref="iframe" style="border: none;width: 100%;height: 100%;background: white"/>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 lg6 class="pa-1">
            <v-textarea dense hide-details v-model="template.TextPart" label="Text" outlined rows="12"/>
          </v-flex>
          <v-flex xs12 lg6 class="pa-1">
            <pre v-html="template.TextPart" style="white-space: pre-wrap"/>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <div class="pa-8" v-if="isLoading">
      <v-progress-circular indeterminate/>
    </div>
  </div>
</template>

<script>
export default {
  name: "templateCreateOrUpdate",
  computed: {
    docId() {
      return this.$route.params.id
    }
  },
  methods: {
    getModel() {
     return {
       TemplateName: null,
       HtmlPart: null,
       SubjectPart: null,
       TextPart: null
     }
    },
    async submit() {
      this.isSubmitting = true
      try {
        const {data} = await this.$notiHttp.post('/email/template', this.template)
        if (data['statusCode'] === 0) {
          this.$message.success("Successful")
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : "Unknown Error Occurred!")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isSubmitting = false
    },
    async getTemplate() {
      if(this.docId !== "create"){
        this.isLoading = true
        try {
          const {data} = await this.$notiHttp.get('/email/template/'+this.docId)
          if (data['statusCode'] === 0) {
            this.template = data['data']
            setTimeout(() => { this.renderHtml() }, 200)
          } else {
            this.$message.error(data['error'] ? data['error']['message'] : "Unknown Error Occurred!")
          }
        } catch (e) {
          this.$message.error(e.toString())
        }
        this.isLoading = false
      }else  {
        this.template = this.getModel()
      }
    },
    renderHtml() {
      var ifrw = (this.$refs.iframe.contentWindow) ? this.$refs.iframe.contentWindow : (this.$refs.iframe.contentDocument.document) ? this.$refs.iframe.contentDocument.document : this.$refs.iframe.contentDocument;
      ifrw.document.open();
      ifrw.document.write(this.template.HtmlPart);
      ifrw.document.close();
      //23.02.2016: contentEditable is set to true, to fix text-selection (bug) in firefox.
      //(and back to false to prevent the content from being editable)
      //(To reproduce the error: Select text in the result window with, and without, the contentEditable statements below.)
      if (ifrw.document.body && !ifrw.document.body.isContentEditable) {
        ifrw.document.body.contentEditable = true;
        ifrw.document.body.contentEditable = false;
      }
    }
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      template: null
    }
  },
  mounted() {
    this.getTemplate()
  }
}
</script>

<style scoped>

</style>
