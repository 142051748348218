<template>
  <div class="pa-2">
    <v-layout style="gap: 8px">
      <v-flex xs12 lg8>
        <v-text-field v-model="map.mapId" outlined hide-details dense
                      label="Map Id (No spaces or special charecter allowed)" :disabled="isIdEditable"/>
      </v-flex>
      <v-flex xs12 lg4>
        <v-select label="Status" v-model="map.status" :items="['public', 'private', 'review']" outlined hide-details
                  dense/>
      </v-flex>
    </v-layout>
    <Platform v-model="map"/>
    <TestMappingSections v-model="map"/>
    <div class="pt-2 text-center">
      <v-btn large color="red" :disabled="isSubmitting" :loading="isSubmitting" @click="submit">SUBMIT</v-btn>
    </div>
    <v-dialog v-model="isGettingMap" persistent max-width="400">
      <v-card dark>
        <v-card-text>
          <div>Please wait....</div>
          <v-progress-linear indeterminate class="mt-2"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Platform from "@/components/PlatformComponent";
import TestMappingSections from "@/components/tests/TestMappingSections";

export default {
  name: "createOrUpdate",
  components: {TestMappingSections, Platform},
  data() {
    return {
      map: this.baseMap(),
      isGettingMap: false,
      isSubmitting: false
    }
  },
  computed: {
    isIdEditable() {
      return typeof this.$route.params.id !== "undefined"
    }
  },
  methods: {
    baseMap() {
      return {
        mapId: null,
        platform: [],
        status: 'review',
        sections: []
      }
    },
    async getMap() {
      this.isGettingMap = true
      try {
        const {data} = await this.$testHttp.get('/test-id-maps', {params: {id: this.$route.params.id}})
        if (data['statusCode'] === 0) {
          this.$set(this, 'map', {...this.baseMap(), ...data['data']})
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : 'Unknown Error!')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isGettingMap = false
    },
    async submit() {
      this.isSubmitting = true
      try {
        const map = JSON.parse(JSON.stringify(this.map))
        map.sections.forEach(el => {
          el.groups = el.groups.map(e => {
            return {...e, tests: e.tests.map(el => el.testId)}
          });
        })
        const {data} = await this.$testHttp.post('/test-id-maps', map)
        if (data['statusCode'] === 0) {
          this.$message.success('Successful')
          if (!this.$route.params.id) this.$set(this, 'map', this.getMap())
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : 'Unknown Error!')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isSubmitting = false
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.getMap()
    }
  }
}
</script>

<style scoped>

</style>
