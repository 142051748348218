export default {
  async getExamGroups(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/examGroup",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async getExams(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/exam",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async getSubjectGroups(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/subjectGroup",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async getSubjects(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/subject",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async getChapterGroups(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/chapterGroup",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async getChapters(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/chapter",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async getChapterTopics(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
        vue,
        "/chapterTopic",
        query,
        onData,
        onStart,
        onFinish,
        onError
    );
  },
  async getPapers(vue, query, onData, onStart, onFinish, onError) {
    await this.defaultFun(
      vue,
      "/paper",
      query,
      onData,
      onStart,
      onFinish,
      onError
    );
  },
  async defaultFun(vue, path, query, onData, onStart, onFinish, onError) {
    onStart();
    try {
      const { data } = await vue.$metaHttp.get(path, {
        params: query,
      });
      if (data.statusCode === 0) {
        onData(data.results)
      } else {
        onError();
        vue.$message.error(data.error.message);
      }
    } catch (e) {
      onError();
      vue.$message.error(e.message);
    }
    onFinish();
  },
};
