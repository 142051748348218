import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [{
        path: '/',
        name: 'welcome-view',
        component: require('@/pages').default
    },
        {
            path: '/formula',
            name: 'formula-list',
            component: require('@/pages/formula').default
        },
        {
            path: '/formula/:id',
            name: 'formula-create-or-update',
            component: require('@/pages/formula/createOrUpdate').default
        },
        {
            path: '/freelance/:id/select-questions',
            name: 'freelance-exp-s-list',
            component: require('@/pages/freelance/explanation/select').default
        },
        {
            path: '/freelance/:id/questions',
            name: 'freelance-exp-q-list',
            component: require('@/pages/freelance/explanation/questions').default
        },
        {
            path: '/freelance/:id/exp/:qid',
            name: 'freelance-exp-q-update',
            component: require('@/pages/freelance/explanation/update').default
        },
        {
            path: '/notification',
            name: 'noti-list',
            component: require('@/pages/notification/list').default
        },
        {
            path: '/notification/create',
            name: 'noti-compose',
            component: require('@/pages/notification/createOrUpdate').default
        },
        {
            path: '/notification/update/:id',
            name: 'noti-compose-u-id',
            component: require('@/pages/notification/createOrUpdate').default
        },
        {
            path: '/notification-email-template',
            name: 'noti-t-u-id',
            component: require('@/pages/notification/email/templates').default
        },
        {
            path: '/notification-email-template/:id',
            name: 'noti-t-up-id',
            component: require('@/pages/notification/email/templateCreateOrUpdate').default
        },
        {
            path: '/subscriptions',
            name: 'subs-index',
            component: require('@/pages/subscriptions').default
        },
        {
            path: '/subscriptions/:id',
            name: 'subs-update',
            component: require('@/pages/subscriptions/createOrUpdate').default
        },
        {
            path: '/account/login',
            name: 'login',
            component: require('@/pages/login').default
        },
        {
            path: '/syllabus/add',
            name: 'syllabus_new',
            component: require('@/pages/syllabus/new').default
        },
        {
            path: '/syllabus/update',
            name: 'syllabus_update',
            component: require('@/pages/syllabus/all').default
        },
        {
            path: '/syllabus/update/:id',
            name: 'syllabus_update_by_id',
            component: require('@/pages/syllabus/update').default
        },
        {
            path: '/pastquestions/chapter-classify',
            name: 'pastquestions_chapter_classify',
            component: require('@/pages/pastquestions/chapter-classify').default
        },
        {
            path: '/pastquestions/update',
            name: 'pastquestions_update',
            component: require('@/pages/pastquestions/all').default
        },
        {
            path: '/pastquestions/batchUpdate',
            name: 'pastquestions_batch_update',
            component: require('@/pages/pastquestions/batchUpdate').default
        },
        {
            path: '/pastquestions/:qid',
            name: 'pastquestions_update_ID',
            component: require('@/pages/pastquestions/createOrUpdate').default
        },
        {
            path: '/metadata/examGroup',
            name: 'meta_eg',
            component: require('@/pages/metadata/examGroup').default
        },
        {
            path: '/metadata/exam',
            name: 'meta_exam',
            component: require('@/pages/metadata/exam').default
        },
        {
            path: '/metadata/subject',
            name: 'meta_sub',
            component: require('@/pages/metadata/subject').default
        },
        {
            path: '/metadata/chapterGroup',
            name: 'meta_chapterGroup',
            component: require('@/pages/metadata/chapterGroup').default
        },
        {
            path: '/metadata/chapter',
            name: 'meta_chapter',
            component: require('@/pages/metadata/chapter').default
        },
        {
            path: '/metadata/paper',
            name: 'meta_paper',
            component: require('@/pages/metadata/paper').default
        },
        {
            path: '/metadata/chapterTopic',
            name: 'meta_chapter_topic',
            component: require('@/pages/metadata/chapterTopic').default
        },
        {
            path: '/admin-user',
            name: 'admin_user_i',
            component: require('@/pages/admin-users').default
        },
        {
            path: '/admin-user/find',
            name: 'admin_user_f',
            component: require('@/pages/admin-users/search').default
        },
        {
            path: '/admin-user/create',
            name: 'admin_user_c',
            component: require('@/pages/admin-users/new').default
        },
        {
            path: '/admin-user/update',
            name: 'admin_user_u',
            component: require('@/pages/admin-users/all').default
        },
        {
            path: '/admin-user/update/:id',
            name: 'admin_user_u_id',
            component: require('@/pages/admin-users/update').default
        },
        {
            path: '/user/sessions',
            name: 'admin_user_ses',
            component: require('@/pages/user/Sessions').default
        },
        {
            path: '/theory',
            name: 'notes_update',
            component: require('@/pages/theory/find').default
        },
        {
            path: '/theory/:id',
            name: 'notes_create',
            component: require('@/pages/theory/create').default
        },
        {
            path: '/security',
            name: 'security',
            component: require('@/pages/security').default
        },
        {
            path: '/security/block/list',
            name: 'security_blocked_list',
            component: require('@/pages/security/list/blocked').default
        },
        {
            path: '/report/past/all',
            name: 'report_past_list',
            component: require('@/pages/report/past/AllReports').default
        },
        {
            path: '/report/past/:id',
            name: 'report_past_item',
            component: require('@/pages/report/past/SingleReport').default
        },
        {
            path: '/stats/past-question',
            name: 'stats_past_year_question',
            component: require('@/pages/stats/pastYearQuestions').default
        },
        {
            path: '/freelance',
            name: 'freelance-users',
            component: require('@/pages/freelance/users').default
        },
        {
            path: '/freelance/:id/past-question',
            name: 'freelance-pq',
            component: require('@/pages/freelance/pastQuestions').default
        },
        {
            path: '/freelance/:id/test-question',
            name: 'freelance-tq',
            component: require('@/pages/freelance/test-questions/pastQuestions').default
        },
        {
            path: '/freelance/:id/practice-question',
            name: 'freelance-prq',
            component: require('@/pages/freelance/practice-questions/pastQuestions').default
        },
        {
            path: '/freelance/:id/past-question/:qId',
            name: 'freelance-vpq',
            component: require('@/pages/freelance/viewPastQuestion').default
        },
        {
            path: '/freelance/:id/practice-question/:qId',
            name: 'freelance-vprq',
            component: require('@/pages/freelance/practice-questions/viewPastQuestion').default
        },
        {
            path: '/freelance/:id/test-question/:qId',
            name: 'freelance-vtq',
            component: require('@/pages/freelance/test-questions/viewPastQuestion').default
        },
        {
            path: '/freelance/:id/docs',
            name: 'freelance-docs',
            component: require('@/pages/freelance/docs').default
        },
        {
            path: '/freelance/:id/docs/:docId',
            name: 'freelance-docs-view',
            component: require('@/pages/freelance/viewDoc').default
        },
        {
            path: '/vision/new',
            name: 'vision-new-view',
            component: require('@/pages/vision/createJob').default
        },
        {
            path: '/vision/list',
            name: 'vision-list-view',
            component: require('@/pages/vision/list').default
        },
        {
            path: '/vision/:id',
            name: 'vision-view',
            component: require('@/pages/vision/view').default
        },
        {
            path: '/tests/questions',
            name: 'test-qs-view',
            component: require('@/pages/tests/questions/list').default
        },
        {
            path: '/tests/questions/:id',
            name: 'test-qs-id-view',
            component: require('@/pages/tests/questions/createOrUpdate').default
        },
        {
            path: '/practice/questions',
            name: 'practice-qs-view',
            component: require('@/pages/practice-questions/list').default
        },
        {
            path: '/practice/questions/:id',
            name: 'practice-qs-id-view',
            component: require('@/pages/practice-questions/createOrUpdate').default
        },
        {
            path: '/tests',
            name: 'test-list-view',
            component: require('@/pages/tests/list').default
        },
        {
            path: '/test-map',
            name: 'test-map-view',
            component: require('@/pages/tests/testMap').default
        },
        {
            path: '/test-id-map',
            name: 'test-map-id-view',
            component: require('@/pages/tests/id-map/createOrUpdate').default
        },
        {
            path: '/test-id-map/list',
            name: 'test-map-id-list-view',
            component: require('@/pages/tests/id-map/list').default
        },
        {
            path: '/test-id-map/:id',
            name: 'test-map-get-id-view',
            component: require('@/pages/tests/id-map/createOrUpdate').default
        },
        {
            path: '/tests/:id',
            name: 'test-id-view',
            component: require('@/pages/tests/createOrUpdate').default
        },
        {
            path: '/tests/metadata/examGroup',
            name: 'test-m-exg-view',
            component: require('@/pages/tests/metadata/examGroup').default
        },
        {
            path: '/tests/metadata/subject',
            name: 'test-m-sub-view',
            component: require('@/pages/tests/metadata/subject').default
        },
        {
            path: '/tests/metadata/chapterGroup',
            name: 'test-m-chapg-view',
            component: require('@/pages/tests/metadata/chapterGroup').default
        },
        {
            path: '/tests/metadata/chapter',
            name: 'test-m-chap-view',
            component: require('@/pages/tests/metadata/chapter').default
        },
        {
            path: '/tests/metadata/chapterTopic',
            name: 'test-m-topic-view',
            component: require('@/pages/tests/metadata/chapterTopic').default
        },
        {
            path: '/users/',
            name: 'users-view',
            component: require('@/pages/users').default
        },
        {
            path: '/users/:id',
            name: 'users-by-id-view',
            component: require('@/pages/users/_id').default
        },
        {
            path: '/tools/testbook',
            name: 'tools-testbook-view',
            component: require('@/pages/tools/testbook').default
        },
        {
            path: '*',
            redirect: '/'
        }
    ]
})
