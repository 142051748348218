<template>
  <div class="pa-1">
    <div class="text-right pb-2" v-if="!select && !$route.params.id">
      <v-btn to="/formula/create" color="green" dark>CREATE</v-btn>
    </div>
    <PastQuestionsMetadata v-model="q">
      <template v-slot:header>
        <v-text-field label="Search" v-model="q.query" outlined hide-details class="ma-1" />
      </template>
    </PastQuestionsMetadata>
    <v-layout ref="el" wrap>
      <v-flex x12 md6 lg4 class="pa-1" v-for="question in questions" :key="question.formulaId">
        <v-card>
          <v-card-subtitle>{{question.formula[Object.keys(question.formula)[0]].title}}</v-card-subtitle>
          <v-card-text v-html="question.formula[Object.keys(question.formula)[0]].content" />
          <v-card-actions>
            <v-spacer/>
            <v-btn v-if="!select" :to="'/formula/'+question.formulaId" text color="green">View Formula</v-btn>
            <v-btn v-else @click="$emit('onSelect', question)" text color="green">Select Formula</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div v-if="!noMoreFormulas" class="pa-2 text-center">
      <v-btn @click="getQuestions" :disabled="questionGetting || !q.examGroup" :loading="questionGetting">LOAD
        FORMULAS
      </v-btn>
    </div>
  </div>
</template>

<script>
import TestQuestionMetadata from "@/components/tests/TestQuestionMetadata";
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "formula-list",
  components: {PastQuestionsMetadata, TestQuestionMetadata},
  props: {
    select: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      questionGetting: false,
      questionGettingError: false,
      questions: [],
      noMoreFormulas: false,
      q: {
        country: null,
        examGroup: null,
        exam: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        topic: null,
        query: null
      }
    }
  },
  methods: {
    async getQuestions(page) {
      if (typeof page !== "undefined" && page === 0) {
        this.questions = []
      }
      this.noMoreFormulas = false
      this.questionGetting = true;
      this.questionGettingError = false;
      try {
        const {data} = await this.$formulaHttp
            .get("/list", {
              params: {
                country: this.q.country,
                examGroup: this.q.examGroup,
                exam: this.q.exam,
                subject: this.q.subject,
                chapterGroup: this.q.chapterGroup,
                chapter: this.q.chapter,
                q: (this.q.query || '').trim() || null,
                topic: this.q.topic,
                page: Math.max(0, Number(Number(this.questions.length / 30).toFixed(0)))
              },
            })
        if (data.statusCode === 0) {
          this.noMoreFormulas = data.results.length < 30
          this.questions = [...this.questions, ...data['results']]
          this.$mathJaxRefresh(this.$refs.el)
        } else {
          this.questionGettingError = true;
        }
      } catch (e) {
        this.$message.error(e.toString())
        this.questionGettingError = true;
      }
      this.questionGetting = false;
    }
  },
  watch: {
    'q' : {
      handler(newValue, oldValue) {
        if(this.q.exam) this.getQuestions(0)
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.$mathJaxClear(this.$refs.el)
  }
}
</script>

<style scoped>

</style>
