<template>
  <div>
    <v-navigation-drawer fixed v-model="drawer" app>
      <v-app-bar flat class="transparent">
        <v-list class="pa-0">
          <v-list-item>
            <v-list-item-avatar>
              <img :src="photo">
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ fullName }}</v-list-item-title>
              <v-list-item-subtitle>{{ time }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-app-bar>
      <v-divider/>
      <v-list dense nav>
        <div :key="i" v-for="(item, i) in items">
          <v-list-group v-if="item.items">
            <template v-slot:activator>
              <v-list-item-action>
                <v-icon v-html="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>
            <div :key="i2" v-for="(item2, i2) in item.items">
              <v-list-group v-if="item2.items" sub-group>
                <template v-slot:activator>
                  <v-list-item-content router :to="item2.to">
                    <v-list-item-title v-text="item2.title"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <v-list-item :key="i3" v-for="(item3, i3) in item2.items" :to="item3.to" active-class="primary--text">
                  <v-list-item-content>
                    <v-list-item-title v-text="item3.title"></v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon v-html="item3.icon"></v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-group>
              <v-list-item router v-if="!item2.items" :to="item2.to" active-class="primary--text">
                <v-list-item-action v-if="item2.icon">
                  <v-icon v-html="item2.icon"></v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="item2.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-list-group>
          <v-list-item v-else-if="item.items === undefined" router :to="item.to" active-class="primary--text">
            <v-list-item-action>
              <v-icon v-html="item.icon"></v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar fixed dense app :dark="!$vuetify.theme.dark" :color="$vuetify.theme.dark ? '' : 'blue'">
      <v-app-bar-nav-icon @click.native.stop="drawer = !drawer"/>
      <v-toolbar-title v-text="title"/>
      <v-spacer></v-spacer>
      <v-btn outlined small rounded color="red">
        Live User {{ liveUser }}
      </v-btn>
      <v-btn icon @click.native.stop="$router.go(-1)">
        <v-icon v-html="'chevron_left'"></v-icon>
      </v-btn>
      <v-btn icon @click.native.stop="reload">
        <v-icon>replay</v-icon>
      </v-btn>
      <v-btn icon @click.native.stop="$router.go(1)">
        <v-icon v-html="'chevron_right'"></v-icon>
      </v-btn>
      <v-btn icon @click.native.stop="changeTheme">
        <v-icon v-html="$vuetify.theme.dark ? 'light_mode' : 'dark_mode'"></v-icon>
      </v-btn>
      <v-btn icon @click.stop="$store.commit('setHTMLDialog', true)">
        <v-icon v-html="'code'"></v-icon>
      </v-btn>
      <v-btn icon v-if="mathc" @click.native.stop="drawer2 = true">
        <v-icon v-html="'search'"></v-icon>
      </v-btn>
      <v-btn icon v-if="formulac" @click.native.stop="formulaSearchDialog = true">
        <v-icon v-html="'search'"></v-icon>
      </v-btn>
      <v-btn icon v-if="practicec" @click.native.stop="practiceQuestionSearchDialog = true">
        <v-icon v-html="'search'"></v-icon>
      </v-btn>
    </v-app-bar>
    <v-dialog
        v-model="drawer2"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        :hide-overlay="$vuetify.breakpoint.mdAndDown"
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            dense
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="drawer2 = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Questions</v-toolbar-title>
        </v-toolbar>
        <All/>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="formulaSearchDialog"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        :hide-overlay="$vuetify.breakpoint.mdAndDown"
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            dense
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="formulaSearchDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Formulas</v-toolbar-title>
        </v-toolbar>
        <FormulaList/>
      </v-card>
    </v-dialog>
    <v-dialog
        v-model="practiceQuestionSearchDialog"
        :fullscreen="$vuetify.breakpoint.mdAndDown"
        :hide-overlay="$vuetify.breakpoint.mdAndDown"
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
            dark
            dense
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="practiceQuestionSearchDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Practice Questions</v-toolbar-title>
        </v-toolbar>
        <PracticeQuestionsList/>
      </v-card>
    </v-dialog>
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view></router-view>
      </v-slide-y-transition>
    </v-main>
    {{ onUpdateAbs }}
  </div>
</template>

<script>
import All from "../pages/pastquestions/all";
import HTMLEditorDialog from "@/components/HTMLEditorDialog";
import FormulaList from "@/pages/formula";
import PracticeQuestionsList from "@/pages/practice-questions/list";

export default {
  name: "default",
  components: {PracticeQuestionsList, FormulaList, HTMLEditorDialog, All},
  computed: {
    photo() {
      return this.$store.state.user && this.$store.state.user.photo
          ? this.$store.state.user.photo
          : "https://www.101computing.net/wp/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png";
    },
    fullName() {
      return this.$store.state.user && this.$store.state.user.name
          ? this.$store.state.user.name
          : "Update Name";
    },
    mathc() {
      if (this.$route.path.startsWith("/pastquestions") && (!this.$route.path.startsWith("/pastquestions/all") && !this.$route.path.startsWith("/pastquestions/new"))) {
        return true
      }
      this.drawer2 = false
      return false;
    },
    formulac() {
      if (this.$route.path.startsWith("/formula") && !this.$route.path.startsWith("/formula/create")) {
        return true
      }
      this.formulaSearchDialog = false
      return false;
    },
    practicec() {
      if (this.$route.path.startsWith("/practice") && this.$route.path !== "/practice/questions" && this.$route.path !== "/practice/questions/create") {
        return true
      }
      this.practiceQuestionSearchDialog = false
      return false;
    },
    onUpdateAbs() {
      let s = this.$store.state.pastQuestionId;
      if (s) {
        this.drawer2 = false;
      }
    },
    theme: {
      set(val) {
        this.$store.commit("toggleTheme");
      },
      get() {
        return this.$store.state.darkTheme;
      },
    },
  },
  methods: {
    reload() {
      window.location.reload();
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      window.localStorage.setItem("darkTheme", this.$vuetify.theme.dark.toString());
    }
  },
  data: () => ({
    d: false,
    time: new Date().toLocaleTimeString(),
    ti: null,
    clipped: false,
    drawer: false,
    drawer2: false,
    formulaSearchDialog: false,
    practiceQuestionSearchDialog: false,
    fixed: false,
    routeView: true,
    items: [
      {icon: "home", title: "Home", to: "/"},
      {
        icon: "code",
        title: "Meta Data",
        items: [
          {title: 'Exam Group', to: '/metadata/examGroup'},
          {title: 'Exam', to: '/metadata/exam'},
          {title: 'Subject', to: '/metadata/subject'},
          {title: 'Chapter Group', to: '/metadata/chapterGroup'},
          {title: 'Chapter', to: '/metadata/chapter'},
          {title: 'Chapter Topic', to: '/metadata/chapterTopic'},
          {title: 'Paper', to: '/metadata/paper'},
        ],
      },
      {
        icon: "code",
        title: "Test Meta Data",
        items: [
          {title: 'Exam Group', to: '/tests/metadata/examGroup'},
          {title: 'Subject', to: '/tests/metadata/subject'},
          {title: 'Chapter Group', to: '/tests/metadata/chapterGroup'},
          {title: 'Chapter', to: '/tests/metadata/chapter'},
          {title: 'Chapter Topic', to: '/tests/metadata/chapterTopic'}
        ],
      },
      {
        icon: "subscriptions",
        title: "Subscriptions",
        to: "/subscriptions"
      },
      {
        icon: "construction",
        title: "Tools",
        items: [
          {title: 'TestBook', to: "/tools/testbook"},
        ],
      },
      {
        icon: "code",
        title: "Vision",
        items: [
          {title: 'Create', to: '/vision/new'},
          {title: 'View Jobs', to: '/vision/list'},
        ],
      },
      {icon: "account_circle", title: "Freelance", to: "/freelance"},
      {
        icon: "notifications",
        title: "Notifications",
        items: [
          {title: 'Campaigns', to: '/notification'},
          {title: 'Email Templates', to: '/notification-email-template'},
        ],
      },
      {
        icon: "lock",
        title: "Security",
        to: "/security"
      },
    ],
    miniVariant: false,
    right: true,
    rightDrawer: false,
    title: "ExamGoal Admin Panel",
    io: null,
    liveUser: 0
  }),
  mounted() {
    if (this.ti) clearInterval(this.ti);
    this.ti = setInterval(() => {
      let d = new Date();
      this.time = d.toLocaleTimeString();
    }, 1000);
    this.$vuetify.theme.dark = (window.localStorage.getItem('darkTheme') || 'false').toLowerCase() === "true"
    this.io = this.$io("https://ironman.examgoalapis.com/admin", {
      path: '/api/analytics/websocket',
      transports: ['websocket']
    })
    this.io.on('connected_user', ev => this.liveUser = Number(ev))
  },
  watch: {
    '$route'(to, from) {
      if (to.matched[0] && to.matched[0].path === "/pastquestions/:qid") {
        this.drawer2 = false
      }
      if (to.matched[0] && to.matched[0].path === "/formula/:id") {
        this.formulaSearchDialog = false
      }
      if (to.matched[0] && to.matched[0].path === "/practice/questions/:id") {
        this.practiceQuestionSearchDialog = false
      }
    }
  },
  beforeDestroy() {
    if (this.ti) clearInterval(this.ti);
    if (this.io) this.io.disconnect();
  },
};
</script>

<style scoped>
</style>
