<template>
  <div>
    <PastQuestionsMetadata ref="meta" v-model="q">
      <template v-slot:header>
        <v-text-field label="Search" v-model="q.query" outlined hide-details class="ma-1" @input="() => getQuestions(0)"/>
      </template>
      <v-card-actions>
        <v-switch v-model="noExplanation" label="No Explanation"/>
        <v-spacer/>
        <v-select v-model="mode" :items="modes" label="Mode" dense outlined hide-details />
        <v-spacer/>
        <v-select v-model="lang" :items="$availLanguages" v-if="mode !== 'exp'" label="Language" dense outlined hide-details/>
        <v-spacer/>
        <v-btn :disabled="results.length <= 0 || sending" :loading="sending" @click="() => { selected = questions.map(el => el.question_id).filter((el, i, self) => self.indexOf(el) === i) }" outlined color="green" small>SELECT ALL QUESTIONS</v-btn>
        <v-btn :disabled="selected.length <= 0 || sending" :loading="sending" @click="sendQuestions" outlined color="green" small>SEND QUESTIONS</v-btn>
      </v-card-actions>
    </PastQuestionsMetadata>
    <v-card class="mt-2" v-if="questions.length > 0">
      <v-card-title>Questions ({{questions.length}})</v-card-title>
      <v-card-text ref="mathel">
        <v-list>
          <v-list-item v-for="q in questions" :key="q.question_id" three-line>
            <v-list-item-action>
              <v-checkbox :value="selected.indexOf(q.question_id) >= 0"
                          @change="(v) => { if(v) { selected.push(q.question_id) } else { selected.splice(selected.indexOf(q.question_id), 1) } }"/>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ q.subject }} • {{ q.chapter }}</v-list-item-title>
              <div class="pt-2" v-html="q.question[q.languages[0]].content"/>
              <div class="pt-2">
                <h3>Explanation</h3>
                <div class="pt-2" v-html="q.question[q.languages[0]].explanation || 'No Explanation'"/>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions v-if="results.length % 10 === 0">
        <v-spacer/>
        <v-btn @click="() => getQuestions(results.length / 30)" small outlined>Load More</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "selectExplanationQuestion",
  components: {PastQuestionsMetadata},
  computed: {
    questions() {
      return this.results.filter(el => this.noExplanation ? !el.question[el.languages[0]].explanation : true)
    }
  },
  methods: {
    async sendQuestions() {
      this.sending = true
      try {
        const {data} = await this.$http.post("/freelance/"+this.$route.params.id+"/explanation/send", {ids: this.selected, mode: this.mode, lang: this.lang})
        if (data['statusCode'] === 0) {
          this.selected = []
          this.$message.success("Successful")
        } else {
          this.$message.error("Unknown Error")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.sending = false
    },
    async getQuestions(page) {
      const pg = Number(page || 0)
      if (pg === 0) {
        this.results = []
        this.selected = []
      }
      try {
        const {data} = await this.$pqhttp.get("/list", {
          params: {
            country: this.q.country,
            examGroup: this.q.examGroup,
            exam: this.q.exam,
            subjectGroup: this.q.subjectGroup,
            subject: this.q.subject,
            chapterGroup: this.q.chapterGroup,
            chapter: this.q.chapter,
            paper: this.q.paperId,
            q: (this.q.query || '').trim(),
            status: this.q.status,
            page: pg
          },
        })
        if (data['statusCode'] === 0) {
          this.results = [...this.results, ...data['data']]
          setTimeout(() => {
            if (window.MathJax) { window.MathJax.typesetPromise([this.$refs.mathel]) }
          }, 500);
        } else {
          this.$message.error("Unknown Error")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
    }
  },
  data() {
    return {
      results: [],
      selected: [],
      sending: false,
      noExplanation: false,
      lang: null,
      mode: "exp",
      modes: [
        {
          value: "exp",
          text: "Explanation"
        },
        {
          value: "lang",
          text: "Language Question"
        }
      ],
      q: {
        query: null,
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        status: null,
        paperId: null
      }
    }
  },
  watch: {
    'q.exam'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.subjectGroup'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.subject'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.chapterGroup'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.chapter'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.paperId'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.status'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    }
  },
  beforeDestroy() {
    window.MathJax.typesetClear([this.$refs.mathel])
  }
}
</script>

<style scoped>

</style>
