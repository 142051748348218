<template>
  <v-layout>
    <v-flex xs4>
      <v-card class="ma-1">
        <v-card-title>
          <h3>Find User</h3>
        </v-card-title>
        <v-divider/>
        <v-card-text>Find Admin user by username or full name or any other personal details</v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="blue" dark @click="$router.push('/admin-user/find')">
            Find Now &nbsp;
            <v-icon small>search</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs4>
      <v-card class="ma-1">
        <v-card-title>
          <h3>Create User</h3>
        </v-card-title>
        <v-divider/>
        <v-card-text>Create New Admin User! Only for Authorized User</v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="blue" dark @click="$router.push('/admin-user/create')">
            Create New User &nbsp;
            <v-icon small>add</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
    <v-flex xs4>
      <v-card class="ma-1">
        <v-card-title>
          <h3>Update User</h3>
        </v-card-title>
        <v-divider/>
        <v-card-text>Update a New Admin User! Only for Authorized User</v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="blue" dark @click="$router.push('/admin-user/update')">
            Update User &nbsp;
            <v-icon small>update</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "index"
};
</script>

<style scoped>
</style>