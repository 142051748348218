<template>
  <div id="app" style="overflow: hidden">
    <v-app :style="{'background-color': $vuetify.theme.dark ? '' : '#f1f1f1'}">
      <CleanLayout v-if="layout === 'clean'"/>
      <DefaultLayout v-bind:class="{hide: loginReq, show: !loginReq}"/>
      <UploadDialogComponent />
      <LoginRequired v-if="loginReq"/>
      <LoginDialog/>
      <IPInfoDialog/>
      <IPBlockDialog/>
      <IPListDialog/>
      <HTMLEditorDialog/>
    </v-app>
  </div>
</template>

<script>
import DefaultLayout from "@/layouts/default";
import CleanLayout from "@/layouts/clean";
import UploadDialogComponent from "./components/dialogs/UploadDialogComponent";
import LoginDialog from "./components/dialogs/LoginDialog";
import LoginRequired from "./components/LoginRequired";
import IPInfoDialog from "./pages/security/components/IPInfoDialog";
import IPBlockDialog from "./pages/security/components/IPBlockDialog";
import IPListDialog from "./pages/security/components/IPListDialog";
import HTMLEditorDialog from "@/components/HTMLEditorDialog";
export default {
  name: "examgoal-admin-desktop",
  components: {
    HTMLEditorDialog,
    IPListDialog,
    IPBlockDialog,
    IPInfoDialog,
    LoginRequired,
    LoginDialog,
    UploadDialogComponent,
    DefaultLayout,
    CleanLayout
  },
  computed: {
    layout() {
      return this.$store.state.layout;
    },
    ipBlockDialog: {
      set(v){},
      get(){return this.$store.state.dialog.ipToBlock !== null}
    },
    ipsDialog: {
      set(v){},
      get(){return this.$store.state.dialog.ips !== null && this.$store.state.dialog.ips.length > 0}
    },
    loginReq: {
      set(v){},
      get(){
        return this.$store.state.user === null;
      }
    },
    darkTheme() {
      return this.$store.state.darkTheme;
    }
  }
};
</script>
<style scoped>
.show {
  display: block;
}

.hide {
  display: none;
}
</style>
<style>
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons");
/* Global CSS */
</style>
