<template>
  <div ref="el" class="pa-1">
    <v-card>
      <v-card-text>
        <v-text-field v-model="authCode" hide-details outlined dense label="Auth Code"/>
        <br/>
        <v-text-field v-model="testId" hide-details outlined dense label="Test Id"/>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined small text @click="loadTest" :loading="isTestLoading" :disabled="isTestLoading"
                 color="green darken-2">Load Test
          </v-btn>
          <v-btn outlined small text @click="importQuestions" :loading="isImporting" :disabled="isImporting"
                 color="red darken-2">Import All
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <PastQuestionsMetadata v-model="q" hide-question-type class="mt-1"/>
    <v-card class="mt-1">
      <v-card-text>
        <v-select
            outlined
            dense
            hide-details
            label="Option Identifier Mode"
            :items="optionIdentifierModes"
            v-model="q.optionIdentifier"/>
      </v-card-text>
    </v-card>
    <v-card class="mt-1" v-if="questions.length > 0" v-for="question in questions" :key="question['tempId']">
      <v-tabs dark color="red">
        <v-tab v-for="lang in question['languages']" :key="lang">{{ $getLangName(lang) }}</v-tab>
        <v-tab-item v-for="lang in question['languages']" :key="lang">
          <div class="pa-2" v-if="question.question[lang].comprehension">
            <h3>Comprehension</h3>
            <div v-html="question.question[lang].comprehension"/>
          </div>
          <div class="pa-2">
            <h3>Content</h3>
            <div v-html="question.question[lang].content"/>
          </div>
          <div class="pa-2" v-if="question.question[lang].answer">
            <h3>Answer</h3>
            <div v-html="question.question[lang].answer"/>
          </div>
          <div class="pa-2" v-if="question.question[lang].options" v-for="option in question.question[lang].options"
               :key="option.identifier">
            <h3>Options {{ option.identifier }}</h3>
            <div v-html="option['content']"/>
          </div>
          <div class="pa-2" v-if="question.question[lang].correct_options">
            <h3>Correct Option</h3>
            <div v-html="question.question[lang].correct_options.join(', ')"/>
          </div>
          <div class="pa-2" v-if="question.question[lang].hiddenExplanation">
            <h3>Hidden Explanation</h3>
            <div v-html="question.question[lang].hiddenExplanation"/>
          </div>
        </v-tab-item>
      </v-tabs>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined color="red" small @click="() => { questions.splice(questions.indexOf(question), 1) }">Remove
        </v-btn>
        <v-btn outlined color="green" small @click="() => { submit(question, true) } ">Submit</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "testbook-tool",
  components: {PastQuestionsMetadata},
  data() {
    return {
      authCode: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJodHRwczovL3Rlc3Rib29rLmNvbSIsInN1YiI6IjU5NDQwNzEzZTYyMDE3MDhjZjExNWViYiIsImF1ZCI6IlRCIiwiZXhwIjoiMjAyMS0xMC0xMVQxOTo1MTo1NS45MTI0MjY0NzJaIiwiaWF0IjoiMjAyMS0wOS0xMVQxOTo1MTo1NS45MTI0MjY0NzJaIiwibmFtZSI6IkFuZHJvaWQgVmxvZyAoTW92aWVzIE1hc3RpIE1hZ2ljKSIsImVtYWlsIjoiYnVzaW5lc3Nmb3J0b3AxMEBnbWFpbC5jb20iLCJvcmdJZCI6IiIsImlzTE1TVXNlciI6ZmFsc2UsInJvbGVzIjoic3R1ZGVudCJ9.PVsqUTrdVSnlG9f0f72jGASnig9K9V3QB-a1CRBgdHY_-xdhPlVZpIS4HU188pdcLs5bv9ZLN-f4kE5IwoErUy_3NVVZptHcELGUDRkylH-VE9ue1BhwbOTKvwPGERvwsFcspb-G5Bp2mbvf_QiVfG2viF_3sjGNAu3yulnecGQ",
      testId: "6091abe466db3b69eb48d58a",
      questions: [],
      q: {
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        paperTitle: null,
        paperId: null,
        year: 0,
        difficulty: null,
        topic: null,
        timeAllotted: 0,
        optionIdentifier: null,
      },
      optionIdentifierModes: [
        {
          text: "Letter",
          value: "letter"
        },
        {
          text: "Number",
          value: "number"
        },
        {
          text: "Roman",
          value: "roman"
        }
      ],
      isTestLoading: false,
      isImporting: false,
      letters: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    }
  },
  methods: {
    htmlDecode(input) {
      let e = document.createElement('textarea');
      e.innerHTML = input;
      let html = e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      if (!html) return html
      const node = document.createElement("div")
      node.innerHTML = html
      const query = node.querySelectorAll(".math-tex")
      for (let i = 0; i < query.length; i++) {
        query[i].parentNode.replaceChild(document.createTextNode("$$" + query[i].textContent.replaceAll("\\(", "").replaceAll("\\)", "") + "$$"), query[i])
      }
      const q = node.querySelectorAll("*[style]")
      for (let i = 0; i < q.length; i++) {
        q[i].style.color = null
      }
      return node.innerHTML
    },
    async importQuestions() {
      this.isImporting = true
      try {
        for (const q of this.questions) await this.submit(q, false)
        this.$message.success("Successful")
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isImporting = false
    },
    async submit(q, catchError) {
      try {
        const qs = {...q}
        delete qs['tempId']
        const {data} = await this.$pqhttp.post("/createOrUpdate", qs)
        if (data['statusCode'] !== 0) throw new Error(data['error'] ? data['error']['message'] : "Error Occurred")
        this.questions.splice(this.questions.indexOf(q), 1)
        if (catchError) this.$message.success("Successful")
      } catch (e) {
        if (catchError) this.$message.error(e.toString())
        else throw e
      }
    },
    async loadTest() {
      this.isTestLoading = true
      this.questions = []
      try {
        const {data} = await this.$http.post('/tools/testbook/getTest', {authCode: this.authCode, testId: this.testId})
        if (data.statusCode === 0) {
          const sections = data['data']['test']['sections']
          const answers = data['data']['answers']
          for (const section of sections) {
            const questions = section['questions']
            for (const question of questions) {
              const languages = Object.keys(question).filter(el => typeof question[el] === "object" && question[el]['value']).filter(el => ['en', 'bn', 'hi', 'hn'].indexOf(el) >= 0)
              const q = {}
              for (const lang of languages) {
                q[lang === "hn" ? "hi" : lang] = {
                  direction: null,
                  comprehension: question[lang]['comp'] ? this.htmlDecode(question[lang]['comp']) : null,
                  content: this.htmlDecode(question[lang]['value']),
                  options: question[lang]['options'] ? question[lang]['options'].map(el => {
                    return {
                      identifier: el['prompt'],
                      content: this.htmlDecode(el['value'])
                    }
                  }) : [],
                  correct_options: answers[question['_id']]['multiCorrectOptions'] && Array.isArray(answers[question['_id']]['multiCorrectOptions']) ? answers[question['_id']]['multiCorrectOptions'] : (answers[question['_id']]['correctOption'] ? [answers[question['_id']]['correctOption']] : []),
                  answer: question['type'] === "numerical" ? (Number(answers[question['_id']]['range']['start']) + "TO" + Number(answers[question['_id']]['range']['end'])).split("TO").filter((el, i, self) => self.indexOf(el) === i).join("TO") : null,
                  hiddenExplanation: this.htmlDecode(answers[question['_id']]['sol'][lang]['value']),
                  explanation: null
                }
              }
              this.questions.push({
                tempId: question['_id'],
                country: this.q.country || null,
                examGroup: this.q.examGroup || null,
                exam: this.q.exam || null,
                subjectGroup: this.q.subjectGroup || null,
                subject: this.q.subject || null,
                chapterGroup: this.q.chapterGroup || null,
                chapter: this.q.chapter || null,
                year: this.q.year || null,
                paperId: this.q.paperId || null,
                paperTitle: this.q.paperTitle || null,
                timeAllotted: this.q.timeAllotted || 0,
                marks: question['posMarks'],
                negMarks: question['negMarks'],
                languages: languages.map(el => el === "hn" ? "hi" : el),
                difficulty: this.q.difficulty || null,
                topic: this.q.topic || null,
                type: question['type'] === "mamcq" ? "mcqm" : (question['type'] === "mcq" ? "mcq" : question['type'] === "numerical" ? "integer" : "mcq"),
                question: q,
                platform: ["desktop", "web", "android", "ios"],
                section: ["paper"],
                status: "review"
              })
            }
          }
          setTimeout(() => {
            if (window.MathJax) window.MathJax.typesetPromise([this.$refs.el])
          }, 200);
        } else {
          this.$message.error(data.error ? data.error.message : "Error Occurred!")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isTestLoading = false
    },
    romanize(num) {
      let lookup = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
          },
          roman = "",
          i;
      for (i in lookup) {
        while (num >= lookup[i]) {
          roman += i;
          num -= lookup[i];
        }
      }
      return roman;
    },
  },
  watch: {
    'q.country'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['country'] = newValue)
    },
    'q.examGroup'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['examGroup'] = newValue)
    },
    'q.exam'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['exam'] = newValue)
    },
    'q.subjectGroup'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['subjectGroup'] = newValue)
    },
    'q.subject'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['subject'] = newValue)
    },
    'q.chapterGroup'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['chapterGroup'] = newValue)
    },
    'q.chapter'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['chapter'] = newValue)
    },
    'q.marks'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['marks'] = newValue)
    },
    'q.negMarks'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['negMarks'] = newValue)
    },
    'q.year'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['year'] = newValue)
    },
    'q.paperTitle'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['paperTitle'] = newValue)
    },
    'q.paperId'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['paperId'] = newValue)
    },
    'q.difficulty'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['difficulty'] = newValue)
    },
    'q.topic'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['topic'] = newValue)
    },
    'q.timeAllotted'(newValue, oldValue) {
      if (this.questions && newValue) this.questions.forEach(el => el['timeAllotted'] = Number(newValue))
    },
    'q.optionIdentifier'(newValue, oldValue) {
      if (this.questions && newValue) {
        const mode = newValue;
        for (const question of this.questions) {
          for (const lang of Object.keys(question['question'])) {
            const correctIndex = question['question'][lang].correct_options.map(el1 => question['question'][lang].options.indexOf(question['question'][lang].options.filter(el => el.identifier === el1)[0])).filter(el => el !== -1)
            question['question'][lang].options.forEach((el, i) => {
              el.identifier =
                  mode === "number"
                      ? i + 1 + ""
                      : mode === "roman"
                          ? this.romanize(i + 1)
                          : this.letters[i];
            });
            question['question'][lang].correct_options = correctIndex.map(el => question['question'][lang].options[el].identifier).filter(el => typeof el === "string");
          }
        }
      }
    }
  },
  beforeDestroy() {
    window.MathJax.typesetClear([this.$refs.el])
  }
}
</script>

<style scoped>

</style>
