<template>
  <PermissionComponent
    :permissions="['syllabus.editor']"
  >
  <PastQuestionMetadata ref="meta" v-model="syllabus"/>
  <SyllabusEditor ref="editor" v-model="syllabus"/>
  <div class="text-center pa-1">
      <v-btn dark color="blue" large :disabled="submitting" :loading="submitting" @click="submit" rounded min-width="200">SUBMIT</v-btn>
  </div>
  <v-snackbar multi-line v-model="snackbar" top right>
    {{message}}
    <v-btn text outlined @click="snackbar = null">Close</v-btn>
  </v-snackbar>
  </PermissionComponent>
</template>

<script>
import PastQuestionMetadata from "@/components/SyllabusMetadata";
import SyllabusEditor from "@/components/SyllabusEditor";
import PermissionComponent from "../../components/PermissionComponent";
export default {
  components: {
    PastQuestionMetadata,
    PermissionComponent,
    SyllabusEditor,
  },
  computed: {
    snackbar: {
      set(v) {
        this.message = null;
      },
      get() {
        return this.message != null;
      },
    },
  },
  methods: {
    async submit() {
      const {
        title,
        country,
        examGroup,
        exam,
        chapterGroups,
        chapters,
      } = this.syllabus;
      if (
        !title ||
        !country ||
        !examGroup ||
        !exam ||
        (chapterGroups.length === 0 && chapters.length === 0)
      ) {
        this.message = "Enter Required fields";
        return;
      }
      this.message = null;
      this.submitting = true;
      try {
        const { data } = await this.$http.post(
          "/syllabus/create",
          this.syllabus
        );
        if (data.statusCode === 0) {
          this.message = "Success";
          this.syllabus = {
            ...this.syllabus,
            title: null,
            chapterGroups: [],
            chapters: []
          }
        } else {
          this.message = data.error.message;
        }
      } catch (error) {
        this.message = error.toString();
      }
      this.submitting = false;
    },
  },
  data: () => {
    return {
      message: null,
      submitting: false,
      syllabus: {
        title: null,
        country: null,
        examGroup: null,
        exam: null,
        chapterGroups: [],
        chapters: [],
      },
    };
  },
};
</script>

<style>
</style>
