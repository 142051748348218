<template>
   <div ref="el" class="px-4 py-2 body-2" style="display: flex;flex-direction: column; gap: 8px">
     <div class="q-body" v-if="langQuestion.comprehension" v-html="langQuestion.comprehension"/>
     <div class="q-body" v-html="langQuestion.content"/>
     <div v-if="langQuestion.options && langQuestion.options.length > 0" style="display: flex; flex-direction: column; gap: 8px">
       <div v-for="option in langQuestion.options || []" :key="option.identifier">
         <div class="bold">Option {{option.identifier}}</div>
         <div class="q-body mt-2" v-html="option.content"/>
       </div>
     </div>
   </div>
</template>

<script>
export default {
  name: "QuestionComponent",
  props: {
    value: {
      type: Object,
      required: true
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    langQuestion() {
      return this.question[this.lang]
    },
    question() {
      return this.value.question
    }
  },
  mounted() {
    this.$mathJaxRefresh(this.$refs.el)
  },
  beforeDestroy() {
    this.$mathJaxClear(this.$refs.el);
  }
}
</script>

<style scoped>
 .q-body > p:first-child {
   padding-top: 0;
 }
 .q-body > p:last-child {
   padding-bottom: 0;
 }
</style>
