import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        darkTheme: false,
        layout: 'default',
        uploadDialog: false,
        htmlDialog: false,
        user: null,
        pastQuestionId: null,
        pastQuestionGroupId: null
    },
    mutations: {
        setLayout(state, layout) {
            state.layout = layout;
        },
        setUser(state, u) {
            state.user = u;
        },
        logout(state) {
            state.loginDialog = true;
            state.user = null;
        },
        setUploadDialogState(state, val) {
            state.uploadDialog = val;
        },
        setUpdateAbs(state, is) {
            state.pastQuestionId = is.qid;
            state.pastQuestionGroupId = is.gid;
        },
        toggleTheme(state) {
            state.darkTheme = !state.darkTheme;
        },
        setHTMLDialog(state, isEnabled) {
            state.htmlDialog = isEnabled
        }
    },
    modules: modules
})
