<template>
  <v-card outlined>
    <v-card-title>SECTIONS</v-card-title>
    <v-card-text v-for="(section, i) in map.sections" :key="i">
      <LangFieldEditor v-model="map.sections[i].title"/>
      <h3>Groups</h3>
      <draggable v-model="map.sections[i].groups" :group="map.sections[i].title">
        <div class="pa-2" v-for="(group, ii) in map.sections[i].groups" :key="ii">
          <LangFieldEditor v-model="map.sections[i].groups[ii].title"/>
          <h4>Tests</h4>
          <div class="pt-2">
            <draggable class="layout wrap" v-model="map.sections[i].groups[ii].tests">
              <v-flex xs12 lg2 class="pa-1" v-for="(item, iii) in map.sections[i].groups[ii].tests" :key="item.testId">
                <v-card>
                  <v-card>
                    <v-card-text>
                      {{ item['title']['en'] }}
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer/>
                      <v-btn small outlined @click="map.sections[i].groups[ii].tests.splice(iii, 1)">Remove</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-card>
              </v-flex>
            </draggable>
            <div class="pt-2 text-right">
              <v-btn small outlined @click="selectTestDialog = true; currentSection = [i, ii]" class="mr-2" color="green">ADD TEST</v-btn>
              <v-btn small outlined @click="map.sections[i].groups.splice(ii, 1)" color="red">REMOVE THIS GROUP</v-btn>
            </div>
          </div>
        </div>
      </draggable>
      <div class="pt-2 text-right">
        <v-btn small outlined @click="() => { addNewGroup(map.sections[i].groups); } " color="orange">NEW GROUP</v-btn>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn @click="addNewSection" outlined small>ADD SECTION</v-btn>
    </v-card-actions>
    <v-dialog v-model="selectTestDialog" max-width="1100px">
      <v-card>
        <v-card-text>
          <tests-list select
                @select="(v) => { map.sections[currentSection[0]].groups[currentSection[1]].tests.push(v); selectTestDialog = false}"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="selectTestDialog = false">Dismiss</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import draggable from 'vuedraggable'
import TestsList from "@/pages/tests/list";
export default {
  name: "TestMappingSections",
  components: {TestsList, LangFieldEditor, draggable},
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      selectTestDialog: false,
      currentSection: [],
    }
  },
  computed: {
    map() {
      return this.value
    }
  },
  methods: {
    addNewSection() {
      this.map.sections.push({
        title: {en: null},
        groups: []
      })
    },
    addNewGroup(groups) {
      groups.push({
        title: {en: null},
        tests: []
      })
    },
  },
}
</script>

<style scoped>

</style>
