<template>
  <div>
    <PastQuestionMetadata ref="meta" v-model="q" @type-change="prepareOptions"/>
    <v-card class="ma-1" dark>
      <h3 class="pa-3">Platform Specification</h3>
      <v-divider/>
      <v-container>
        <v-layout align-center>
          <v-flex xs3>
            <v-checkbox
                dense
                v-model="q.platform"
                label="Web"
                color="red"
                value="web"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>
          <v-flex xs3>
            <v-checkbox
                dense
                v-model="q.platform"
                label="Desktop"
                color="red"
                value="desktop"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>

          <v-flex xs3>
            <v-checkbox
                v-model="q.platform"
                dense
                label="Android"
                color="red"
                value="android"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>


          <v-flex xs3>
            <v-checkbox
                dense
                v-model="q.platform"
                label="IOS"
                color="red"
                value="ios"
                multiple
                hide-details
            ></v-checkbox>
          </v-flex>

        </v-layout>
      </v-container>
    </v-card>
    <v-card class="ma-1">
      <v-layout class="pa-1">
        <v-flex style="flex-grow: 1">
          <v-btn
              class="mr-1"
              color="primary"
              rounded
              outlined
              @click="currentLang = lang"
              :key="lang"
              v-for="lang in q.languages"
              :disabled="currentLang === lang"
          >
            {{$getLangName(lang)}}
            <v-btn
                small
                color="red"
                text
                style="height: 20px;width: 0px"
                @click.stop="removeLang(lang)"
                v-if="q.languages.length > 1 && lang !== 'en'"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-btn>
        </v-flex>
        <div>
          <v-dialog v-model="langDialog" width="400">
            <template v-slot:activator="{on}">
              <v-btn  v-on="on" text fab outlined small color="red">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  :key="item.value"
                  @click="addLang(item.value)"
                  v-for="item in $availLanguages.filter((el)=> q.languages.indexOf(el.value) === -1)"
              >
                <v-list-item-title>{{$getLangName(item.value)}}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-dialog>
        </div>
      </v-layout>
      <v-divider/>
      <LangQuestionComponent
          :ref="q.languages[i].concat('-lang')"
          :key="i"
          v-for="(n, i) in q.languages"
          :type="q.type"
          :lang="n"
          :question="q.question"
          v-bind:style="currentLang === n ? {display: 'block'} : {display : 'none'}"
      />
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn
            large
            color="red"
            class="white--text"
            :loading="sending"
            :disabled="sending"
            @click="submit"
        >Submit</v-btn>
      </div>
    </v-card>
    <v-snackbar top right v-model="snackBar">
      {{snackBarMessage}}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            outlined
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="questionGetting" persistent width="300">
      <v-card color="red" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="questionGettingError" persistent width="300">
      <v-card color="red" dark>
        <v-card-text class="text-xs-center">
          <h2>Unknown Error Occurred</h2>
          <v-btn class="ma-2" flat outline @click="getQuestion">Retry</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LangQuestionComponent from "@/components/LangQuestionComponent";
import PastQuestionMetadata from "@/components/metadata/PastQuestionMetadata";
export default {
  components: {
    LangQuestionComponent,
    PastQuestionMetadata,
  },
  name: "Update",
  methods: {
    removeLang(n) {
      if (this.q.languages.length === 1 || this.q.languages.indexOf(n) === -1)
        return false;
      let pos = this.q.languages.indexOf(n);
      this.q.languages.splice(pos, 1);
      this.$delete(this.q.question, n);
      if (this.currentLang === n) {
        this.currentLang = this.q.languages[0];
      }
    },
    addLang(v) {
      this.langDialog = false;
      if (this.q.languages.indexOf(v) === -1) {
        this.q.languages.push(v);
      }
      this.q.languages.forEach((el) => {
        if (!this.q.question[el]) {
          this.$set(this.q.question, el, {
            content: null,
            options: [],
            correct_options: [],
            answer: null,
            explanation: null,
          });
          this.prepareOptions();
        }
      });
    },
    prepareOptions() {
      if (["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1) {
        for (let key in this.q.question) {
          if (
              this.q.question[key].options.length === 0 ||
              this.q.type === "t/f"
          ) {
            this.q.question[key].options = [];
            switch (this.q.type) {
              case "t/f":
                this.q.question[key].options.push(
                    {
                      identifier: "A",
                      content:
                          key === "hi" ? "सच" : key === "bn" ? "সত্য" : "TRUE",
                    },
                    {
                      identifier: "B",
                      content:
                          key === "hi"
                              ? "असत्य"
                              : key === "bn"
                              ? "মিথ্যা"
                              : "FALSE",
                    }
                );
                break;
              default:
                this.q.question[key].options.push(
                    {
                      identifier: "A",
                      content: null,
                    },
                    {
                      identifier: "B",
                      content: null,
                    },
                    {
                      identifier: "C",
                      content: null,
                    },
                    {
                      identifier: "D",
                      content: null,
                    }
                );
            }
          }
        }
      } else {
        for (let key in this.q.question) {
          if (
              !this.q.question[key].options ||
              this.q.question[key].options.length !== 0
          ) {
            this.q.question[key].options = [];
          }
        }
      }
    },
    submit() {
      if (
          !this.q.exam ||
          !this.q.subject ||
          !this.q.chapter ||
          !this.q.marks ||
          (!this.q.negMarks && this.q.negMarks !== 0) ||
          !this.q.type ||
          !this.q.year ||
          !this.q.paperTitle ||
          !this.q.timeAllotted ||
          !this.q.status ||
          (!this.q.platform || this.q.platform.length === 0)
      ) {
        this.snackBar = true;
        this.snackBarMessage = "Enter Required Fields";
        return false;
      }
      for (let ii in this.q.question) {
        let q = this.q.question[ii];
        if (
            ["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1 &&
            (!q.correct_options || q.correct_options.length === 0)
        ) {
          this.snackBar = true;
          this.snackBarMessage = "Select Correct Options";
          return false;
        }
        if (["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1) {
          for (let opt in q.options) {
            if (!q.options[opt].content) {
              this.snackBar = true;
              this.snackBarMessage = "Enter Question Options";
              return false;
            }
          }
        }
        if (
            !q.content ||
            (!q.answer &&
                ["integer", "subjective", "fill-blanks"].indexOf(this.q.type) !==
                -1)
        ) {
          this.snackBar = true;
          this.snackBarMessage = "Enter Question Required Fields";
          return false;
        }
        if (!q.explanation) {
          this.q.question[ii].explanation = null;
        }
        if (!q.answer) {
          this.q.question[ii].answer = null;
        }
      }
      this.snackBar = false;
      this.snackBarMessage = null;
      this.sending = true;
      const q = Object.assign({}, this.q);
      for (let i in q.question) {
        if (typeof q.question[i].correct_options === "string") {
          q.question[i].correct_options = q.question[i].correct_options.split(
              ""
          );
        }
      }
      this.$http
          .patch("/freelance/"+ this.$route.params.id + "/past-question", q)
          .then((res) => {
            this.sending = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.snackBar = true;
              this.snackBarMessage = "Success!";
              for (let i in this.q.question) {
                if (
                    Array.isArray(this.q.question[i].correct_options) &&
                    ["mcq", "t/f"].indexOf(this.q.type) !== -1
                ) {
                  let op = this.q.question[i].correct_options;
                  this.q.question[i].correct_options = op.join("");
                }
              }
            } else {
              this.snackBar = true;
              this.snackBarMessage = "Unknown Error!";
            }
          })
          .catch((err) => {
            this.snackBar = true;
            this.snackBarMessage = "Connection Error!";
            this.sending = false;
          });
    },
    getQuestion() {
      this.questionGetting = true;
      this.questionGettingError = false;
      this.$http
          .get("/freelance/"+ this.$route.params.id+"/past-questions/" + this.$route.params.qId)
          .then((res) => {
            this.questionGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.snackBar = true;
              this.snackBarMessage = "Success!";
              this.$set(this, "q", {...json.data, topic: json.data.topic || null});
              setTimeout(this.$refs["meta"].populate, 1000);
              for (let i in this.q.question) {
                if (
                    Array.isArray(this.q.question[i].correct_options) &&
                    ["mcq", "t/f"].indexOf(this.q.type) !== -1
                ) {
                  let op = this.q.question[i].correct_options;
                  this.q.question[i].correct_options = op.join("");
                }
              }
              setTimeout(() => {
                this.q.languages.forEach((el) => {
                  this.$refs[el.concat("-lang")][0].mathJaxRefresh();
                });
              }, 1000);
            } else {
              this.questionGettingError = true;
            }
          })
          .catch((err) => {
            this.questionGetting = false;
            this.questionGettingError = true;
          });
    },
    reset() {
      this.questionGetting = false;
      this.questionGettingError = false;
      this.sending = false;
      this.sendingError = false;
      this.snackBar = false;
      this.snackBarMessage = null;
      this.langDialog = false;
      this.currentLang = "en";
      this.q = {
        status: "review",
        question_id: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        year: null,
        paperTitle: null,
        timeAllotted: 0,
        marks: 0.0,
        negMarks: 0.0,
        languages: ["en"],
        difficulty: null,
        topic: null,
        type: null,
        question: {
          en: {
            content: null,
            options: [],
            correct_options: [],
            answer: null,
            explanation: null,
          },
        },
        platform: ["desktop", "android", "ios"],
      };
    },
  },
  mounted() {
    this.getQuestion();
  },
  data() {
    return {
      questionGetting: false,
      questionGettingError: false,
      sending: false,
      sendingError: false,
      snackBar: false,
      snackBarMessage: null,
      langDialog: false,
      currentLang: "en",
      q: {
        status: "review",
        question_id: null,
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        year: null,
        paperId: null,
        paperTitle: null,
        marks: 0.0,
        negMarks: 0.0,
        languages: ["en"],
        difficulty: null,
        topic: null,
        type: null,
        question: {
          en: {
            content: null,
            options: [],
            correct_options: [],
            answer: null,
            explanation: null,
          },
        },
      }
    };
  },
  watch: {
    "$route.params.qid": function(val) {
      this.reset();
      this.getQuestion();
    },
  },
};
</script>

<style scoped>
.text-content {
  word-wrap: break-word;
}
</style>
