<template>
  <v-card class="ma-4">
    <v-card-title>Exam Group</v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex xs8>
          <v-layout wrap>
            <v-flex xs6 grow="1">
              <v-text-field outlined hide-details v-model="find.country" label="Country" disabled dense/>
            </v-flex>
            <v-flex xs6 class="p-1">
              <v-text-field v-model="create.key" label="Enter Key" hide-details outlined dense/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.title" label="Title"/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.description" text-area label="Description (Optional)"/>
            </v-flex>
          </v-layout>
          <KeyValuePairEditor v-model="create.keyValuePairs"/>
          <div class="text-center pa-4">
            <v-btn outlined color="primary" @click="createOrUpdate">CREATE OR UPDATE</v-btn>
            <v-btn outlined color="red" :disabled="create.id == null" @click="deleteDialog = true">DELETE</v-btn>
          </div>
        </v-flex>
        <v-flex xs4 class='pl-1'>
          <v-select outlined hide-details v-model="find.country" label="Select Country"
                    @input="()=> {findRequest(); create.id = null;}" :items="$availCountries" dense/>
          <v-select outlined hide-details v-model="find.group" :loading="find.loading" @input="(v) => {
  const item = this.find.items.filter(el=> el.key === v)[0];
  this.create.title = typeof item.title === 'object' ? item.title : {en: item.title};
   this.create.description = typeof item.description === 'object' && item.description ? item.description : {en: item.description};
   this.create.keyValuePairs = item.keyValuePairs || {}
  this.create.key = item.key;
this.create.id = item.metaId}" class="mt-2" :items="groupItems" label="Select Group" dense/>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" :max-width="600">
        <v-card class="pa-4">
          <v-card-text>
            Do you want to delete?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text outlined color="green" @click="deleteDialog = false">CANCEL</v-btn>
            <v-btn text outlined color="red" @click="deleteRequest">DELETE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>

</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import KeyValuePairEditor from "@/components/KeyValuePairEditor";
import create from "@/pages/theory/create";

export default {
  components: {KeyValuePairEditor, LangFieldEditor},
  methods: {
    async findRequest() {
      this.find.loading = true;
      try {
        const {data} = await this.$testHttp.get("/metadata/examGroup", {
          params: {
            country: this.find.country,
          },
        });
        if (data.statusCode === 0) {
          this.find.items = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async createOrUpdate() {
      this.create.loading = false;
      try {
        const {data} = await this.$testHttp.post(
            "/metadata/examGroup",
            {
              country: this.find.country,
              title: this.create.title,
              description: this.create.description,
              keyValuePairs: this.create.keyValuePairs,
              key: this.create.key,
            }
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.create.loading = false;
    },
    async deleteRequest() {
      this.deleteDialog = false;
      try {
        const {data} = await this.$metaHttp.delete(
            "/metadata/examGroup", {params: {id: this.create.id}}
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
          this.findRequest();
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  computed: {
    groupItems() {
      return this.find.items.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
  },
  data: () => {
    return {
      deleteDialog: false,
      create: {
        id: null,
        loading: false,
        description: {en: null},
        title: {en: null},
        key: null,
        keyValuePairs: {}
      },
      find: {
        country: null,
        loading: false,
        items: [],
      },
    };
  },
};
</script>

<style>
</style>
