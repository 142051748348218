<template>
  <v-card dark color="green" class="pa-1 ma-1">
      <v-card-text>
         <v-text-field v-model="chapterGroup.title" dense label="Group Title" outlined hide-details/>
         <v-card-text> 
         <Chapter v-for="(item, i) in chapterGroup.chapters" @remove="chapterGroup.chapters.splice(i, 1)" v-model="chapterGroup.chapters[i]" :key="i"/>
         </v-card-text>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
          <v-spacer/>
          <v-btn text outlined dense @click="chapterGroup.chapters.push({title: null, topics: []})">ADD CHAPTER</v-btn>
          <v-btn text outlined dense @click="$emit('remove')">REMOVE</v-btn>
      </v-card-actions>
  </v-card>
</template>

<script>
import Chapter from "./Chapter";
export default {
  components: { Chapter },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chapterGroup() {
      return this.value;
    },
  },
};
</script>

<style>
</style>
