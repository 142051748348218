<template>
  <v-layout wrap>
    <v-flex xs6 class="pa-1">
      <v-menu
          ref="menu1"
          v-model="dateDialog"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="inputDate"
              label="Date"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              outlined
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="inputDate"
            no-title
            @input="dateDialog = false"
        ></v-date-picker>
      </v-menu>
    </v-flex>
    <v-flex xs6 class="pa-1">
      <v-menu
          ref="menu1"
          v-model="timePickerDialog"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              v-model="inputTime"
              label="Time"
              prepend-icon="mdi-clock"
              v-bind="attrs"
              v-on="on"
              hide-details
              dense
              outlined
          ></v-text-field>
        </template>
        <v-time-picker
            v-model="inputTime"
            format="24hr"
            @input="timePickerDialog = false"
        ></v-time-picker>
      </v-menu>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "DateTimeField",
  props: {
    value: {
      type: String,
      default: null
    }
  },
  computed: {
    date: {
      set(v) {
        this.$emit("input", v)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      inputDate: null,
      inputTime: null,
      dateDialog: false,
      timePickerDialog: false
    }
  },
  watch: {
    'inputDate'(newValue, oldValue) {
      if (newValue) {
        this.date = new Date(`${newValue}${this.inputTime ? ` ${this.inputTime}` : ''}`).toISOString()
      }
    },
    'inputTime'(newValue, oldValue) {
      if (newValue && this.inputDate) {
        this.date = new Date(`${this.inputDate} ${newValue}`).toISOString()
      }
    },
    value(newValue, oldValue) {
      if (newValue) {
        const d = new Date(newValue)
        this.inputDate = d.toLocaleDateString().split("/").reverse().join("-")
        this.inputTime = d.toLocaleTimeString().split(":").slice(0, 2).join(":")
      } else {
        this.inputDate = this.inputTime = null;
      }
    }
  }
}
</script>

<style scoped>

</style>
