<template>
  <v-card class="ma-4">
      <v-card-title>Subject</v-card-title>
      <v-card-text>
          <v-layout>
              <v-flex xs8>
  <v-layout wrap>
            <v-flex xs6>
                <v-text-field disabled outlined hide-details v-model="find.country" label="Country" dense/>
            </v-flex>
            <v-flex xs6 class="pl-1">
                <v-text-field disabled outlined hide-details v-model="find.examGroup" label="Exam Group" dense/>
            </v-flex>
    <v-flex xs4 class="mt-2">
      <v-text-field type="number" outlined hide-details v-model="create.order" label="Enter Order" dense/>
    </v-flex>
    <v-flex xs8 class="pl-1 mt-2">
      <v-text-field v-model="create.key" label="Enter Key" hide-details outlined dense/>
    </v-flex>
    <v-flex xs12 class="mt-2">
      <v-text-field v-model="create.image" label="Enter Image Url (Optional)" hide-details outlined dense/>
    </v-flex>
    <v-flex xs12 class="mt-2">
      <LangFieldEditor v-model="create.title" label="Title"/>
    </v-flex>
    <v-flex xs12 class="mt-2">
      <LangFieldEditor v-model="create.description" text-area label="Description (Optional)"/>
    </v-flex>
  </v-layout>
                <KeyValuePairEditor v-model="create.keyValuePairs"/>
        <div class="text-center pa-4">
            <v-btn outlined color="primary" :disabled="!create.title || !create.key || !find.country || !find.examGroup" @click="createOrUpdate">CREATE OR UPDATE</v-btn>
            <v-btn outlined color="red" :disabled="create.id == null" @click="deleteDialog = true">DELETE</v-btn>
        </div>
              </v-flex>
              <v-flex xs4 class='pl-1'>
<v-select outlined hide-details v-model="find.country" label="Select Country" @input="()=> {find.examGroups = find.exams = []; find.examGroup = null; create.country = find.country; create.id = null; findRequest(); }" :items="$availCountries" dense/>
<v-select outlined hide-details v-model="find.examGroup" :loading="find.loading" @input="(v) => { find.subject = null; findRequest4();}" class="mt-2" :items="groupItems" label="Select Exam Group" dense/>
<v-select outlined hide-details v-model="find.subject" :loading="find.loading" @input="(v) => {
  const item = this.find.subjects.filter(el=> el.key === v)[0];
  this.create.order = item.order || 0;
  this.create.key = item.key;
  this.create.title = typeof item.title === 'object' ? item.title : {en: item.title};
  this.create.description = typeof item.description === 'object' && item.description ? item.description : {en: item.description};
  this.create.image = item.image;
  this.create.keyValuePairs = item.keyValuePairs || {};
  this.create.id = item.metaId;
}" class="mt-2" :items="subItems" label="Select Subject" dense/>
              </v-flex>
          </v-layout>
          <v-dialog v-model="deleteDialog" :max-width="600">
              <v-card class="pa-4">
                  <v-card-text>
                      Do you want to delete?
                  </v-card-text>
                  <v-card-actions>
                      <v-spacer/>
                      <v-btn text outlined color="green" @click="deleteDialog = false">CANCEL</v-btn>
                      <v-btn text outlined color="red" @click="deleteRequest">DELETE</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
      </v-card-text>
  </v-card>

</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import KeyValuePairEditor from "@/components/KeyValuePairEditor";
export default {
  components: {KeyValuePairEditor, LangFieldEditor},
  methods: {
    async findRequest() {
      this.find.loading = true;
      try {
        const { data } = await this.$testHttp.get("/metadata/examGroup", {
          params: {
            country: this.find.country,
          },
        });
        if (data.statusCode === 0) {
          this.find.examGroups = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest4() {
      this.find.loading = true;
      try {
        const { data } = await this.$testHttp.get("/metadata/subject", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country
          },
        });
        if (data.statusCode === 0) {
          this.find.subjects = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async createOrUpdate() {
      this.create.loading = false;
      try {
        const { data } = await this.$testHttp.post(
          "/metadata/subject",
            {
              country: this.find.country,
              examGroup: this.find.examGroup,
              description: this.create.description,
              image: this.create.image,
              title: this.create.title,
              key: this.create.key,
              keyValuePairs: this.create.keyValuePairs,
              order: this.create.order
            }
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.create.loading = false;
    },
    async deleteRequest() {
      this.deleteDialog = false;
      try {
        const { data } = await this.$testHttp.delete(
          "/metadata/subject", {params: {id: this.create.id}}
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
          this.create.id = null;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  computed: {
    groupItems() {
      return this.find.examGroups.map((el) => {
        return { value: el.key, text: el.name || el.title['en'] };
      });
    },
    subItems() {
      return this.find.subjects.map((el) => {
        return { value: el.key, text: el.name || el.title['en'] };
      });
    }
  },
  data: () => {
    return {
      deleteDialog: false,
      create: {
        id: null,
        loading: false,
        title: {en: null},
        key: null,
        description: {en: null},
        image: null,
        keyValuePairs: {},
        order: 0
      },
      find: {
        country: null,
        examGroup: null,
        subject: null,
        loading: false,
        subjectGroups: [],
        subjects: [],
        examGroups: [],
        exams: [],
      },
    };
  },
};
</script>

<style>
</style>
