<template>
  <v-card>
    <v-card-text>
      <slot name="header"></slot>
      <v-layout wrap>
        <v-flex xs12 lg3 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="$availCountries"
              v-model="question.country"
              label="Select Country"
          ></v-select>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="examGroups"
              v-model="question.examGroup"
              label="Exam Group"
          ></v-select>
          <v-progress-circular v-if="examGroupGetting" indeterminate color="white"></v-progress-circular>
          <v-btn
              v-if="examGroupError"
              outlined
              text
              color="white"
              rounded
              @click="getExamGroups"
          >Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="subjects"
              v-model="question.subject"
              label="Subject"
          ></v-select>
          <v-progress-circular v-if="subjectGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="subjectError" outlined text color="white" class="mt-1" rounded @click="getSubjects">Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="chapterGroups"
              v-model="question.chapterGroup"
              label="Chapter Group"
          ></v-select>
          <v-progress-circular v-if="chapterGroupGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="chapterGroupError" outlined text color="white" class="mt-1" rounded @click="getChapterGroups">
            Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="chapters"
              v-model="question.chapter"
              label="Chapter"
          ></v-select>
          <v-progress-circular v-if="chapterGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="chapterError" outlined text color="white" class="mt-1" rounded @click="getChapters">Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="topics"
              v-model="question.topic"
              label="Topic"
          ></v-select>
          <v-progress-circular v-if="topicGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="topicError" outlined text color="white" class="mt-1" rounded @click="getTopics">Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1" v-if="question.hasOwnProperty('difficulty')">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="difficulty_levels"
              v-model="question.difficulty"
              label="Difficulty"
          ></v-select>
        </v-flex>
        <v-flex xs12 lg3 class="pa-1" v-if="question.hasOwnProperty('type')">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="question_types"
              v-model="question.type"
              label="Question Type"
          ></v-select>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TestQuestionMetadata",
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  methods: {
    async getExamGroups() {
      this.examGroupError = false
      this.examGroupGetting = true
      try {
        const {data} = await this.$testHttp.get('/metadata/examGroup', {params: {country: this.question.country}})
        if (data['statusCode'] === 0) {
          this.examGroups = data['results'].map(el => {
            return {text: el['title']['en'], value: el.key}
          })
        } else {
          this.$message.error('Unknown Error Occurred!')
        }
      } catch (e) {
        this.examGroupError = true
        this.$message.error(e.toString())
      }
      this.examGroupGetting = false
    },
    async getSubjects() {
      this.subjectError = false
      this.subjectGetting = true
      try {
        const {data} = await this.$testHttp.get('/metadata/subject', {
          params: {
            country: this.question.country,
            examGroup: this.question.examGroup
          }
        })
        if (data['statusCode'] === 0) {
          this.subjects = data['results'].map(el => {
            return {text: el['title']['en'], value: el.key}
          })
        } else {
          this.$message.error('Unknown Error Occurred!')
        }
      } catch (e) {
        this.subjectError = true
        this.$message.error(e.toString())
      }
      this.subjectGetting = false
    },
    async getChapterGroups() {
      this.chapterGroupError = false
      this.chapterGroupGetting = true
      try {
        const {data} = await this.$testHttp.get('/metadata/chapterGroup', {
          params: {
            country: this.question.country,
            examGroup: this.question.examGroup,
            subject: this.question.subject
          }
        })
        if (data['statusCode'] === 0) {
          this.chapterGroups = data['results'].map(el => {
            return {text: el['title']['en'], value: el.key}
          })
        } else {
          this.$message.error('Unknown Error Occurred!')
        }
      } catch (e) {
        this.chapterGroupError = true
        this.$message.error(e.toString())
      }
      this.chapterGroupGetting = false
    },
    async getChapters() {
      this.chapterError = false
      this.chapterGetting = true
      try {
        const {data} = await this.$testHttp.get('/metadata/chapter', {
          params: {
            country: this.question.country,
            examGroup: this.question.examGroup,
            subject: this.question.subject,
            chapterGroup: this.question.chapterGroup
          }
        })
        if (data['statusCode'] === 0) {
          this.chapters = data['results'].map(el => {
            return {text: el['title']['en'], value: el.key}
          })
        } else {
          this.$message.error('Unknown Error Occurred!')
        }
      } catch (e) {
        this.chapterError = true
        this.$message.error(e.toString())
      }
      this.chapterGetting = false
    },
    async getTopics() {
      this.topicError = false
      this.topicGetting = true
      try {
        const {data} = await this.$testHttp.get('/metadata/chapterTopic', {
          params: {
            country: this.question.country,
            examGroup: this.question.examGroup,
            subject: this.question.subject,
            chapterGroup: this.question.chapterGroup,
            chapter: this.question.chapter
          }
        })
        if (data['statusCode'] === 0) {
          this.topics = data['results'].map(el => {
            return {text: el['title']['en'], value: el.key}
          })
        } else {
          this.$message.error('Unknown Error Occurred!')
        }
      } catch (e) {
        this.topicError = true
        this.$message.error(e.toString())
      }
      this.topicGetting = false
    },
    async populate() {
      try {
        if (this.question.country) {
          await this.getExamGroups();
        }
        if (this.question.examGroup) {
          await this.getSubjects();
        }
        if (this.question.subject) {
          await this.getChapterGroups();
          await this.getChapters();
        }
        if (this.question.chapter) {
          await this.getTopics()
        }
      }catch (e) {}
      this.populating = false
    },
  },
  data() {
    return {
      populating: false,
      examGroups: [],
      examGroupGetting: false,
      examGroupError: false,
      subjects: [],
      subjectGetting: false,
      subjectError: false,
      chapterGroups: [],
      chapterGroupGetting: false,
      chapterGroupError: false,
      chapters: [],
      chapterGetting: false,
      chapterError: false,
      topics: [],
      topicGetting: false,
      topicError: false,
      q_status: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Review",
          value: "review",
        },
        {
          text: "Private",
          value: "private",
        },
      ],
      difficulty_levels: [
        {
          text: "Easy",
          value: "easy",
        },
        {
          text: "Medium",
          value: "medium",
        },
        {
          text: "Hard",
          value: "hard",
        },
      ],
      question_types: [
        {
          text: "MCQ",
          value: "mcq",
        },
        {
          text: "MCQ Multiple",
          value: "mcqm",
        },
        {
          text: "Numerical",
          value: "integer",
        },
        {
          text: "Fill in the Blanks",
          value: "fill-blanks",
        },
        {
          text: "Subjective",
          value: "subjective",
        },
        {
          text: "True or False",
          value: "t/f",
        },
      ],
    }
  },
  computed: {
    question() {
      return this.value
    }
  },
  watch: {
    'question.country'(newValue, oldValue) {
      if(!this.populating) this.question.examGroup = null
      this.getExamGroups()
    },
    'question.examGroup'(newValue, oldValue) {
      if(!this.populating) this.question.subject = null
      this.getSubjects()
    },
    'question.subject'(newValue, oldValue) {
      if(!this.populating) this.question.chapterGroup = null
      this.getChapterGroups()
      this.getChapters()
    },
    'question.chapterGroup'(newValue, oldValue) {
      if(!this.populating) this.question.chapter = null
      this.getChapters()
    },
    'question.chapter'(newValue, oldValue) {
      if(!this.populating) this.question.topic = null
      this.getTopics()
    },
    'question.type'(newValue, oldValue) {
      this.$emit('type-change', this.question)
    }
  },
}
</script>

<style scoped>

</style>
