<template>
  <div class="pa-1">
    <div class="text-right pb-2" v-if="!select">
      <v-btn to="/tests/questions/create" color="green" dark>CREATE</v-btn>
    </div>
    <TestQuestionMetadata v-model="q">
      <template v-slot:header>
        <v-text-field label="Search" v-model="q.query" outlined hide-details class="ma-1" />
      </template>
    </TestQuestionMetadata>
    <v-card v-for="question in questions" :key="question.questionId" class="mt-2">
      <v-card-text>
        {{question.question[Object.keys(question.question)[0]].content}}
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn v-if="!select" :to="'/tests/questions/'+question.questionId" text color="green">View Question</v-btn>
        <v-btn v-else @click="$emit('onSelect', question)" text color="green">Select Question</v-btn>
      </v-card-actions>
    </v-card>
    <div class="pa-2 text-center">
      <v-btn @click="getQuestions" :disabled="questionGetting || !q.examGroup" :loading="questionGetting">LOAD
        QUESTIONS
      </v-btn>
    </div>
  </div>
</template>

<script>
import TestQuestionMetadata from "@/components/tests/TestQuestionMetadata";

export default {
  name: "list",
  components: {TestQuestionMetadata},
  props: {
    select: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data() {
    return {
      questionGetting: false,
      questionGettingError: false,
      questions: [],
      q: {
        country: null,
        examGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        difficulty: null,
        topic: null,
        query: null
      }
    }
  },
  methods: {
    async getQuestions(page) {
      if (typeof page !== "undefined" && page === 0) {
        this.questions = []
      }
      this.questionGetting = true;
      this.questionGettingError = false;
      try {
        const {data} = await this.$testHttp
            .get("/question", {
              params: {
                country: this.q.country,
                examGroup: this.q.examGroup,
                subject: this.q.subject,
                chapterGroup: this.q.chapterGroup,
                chapter: this.q.chapter,
                q: (this.q.query || '').trim() || null,
                difficulty: this.q.difficulty,
                topic: this.q.topic,
                page: Math.max(0, Number(Number(this.questions.length / 10).toFixed(0)))
              },
            })
        if (data.statusCode === 0) {
          if (data.results.length === 0) {
            this.$message.warning(
                `No ${this.questions.length > 0 ? "More" : ""} Questions Found`
            );
          } else {
            this.questions = [...this.questions, ...data['results']]
          }
        } else {
          this.questionGettingError = true;
        }
      } catch (e) {
        this.$message.error(e.toString())
        this.questionGettingError = true;
      }
      this.questionGetting = false;
    }
  },
  watch: {
    'q' : {
      handler(newValue, oldValue) {
        if(this.q.examGroup) this.getQuestions(0)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
