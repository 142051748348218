<template>
   <div>
      <PastQuestionMetadata ref="meta" v-model="syllabus" @on-exam="getList"/>
      <v-layout wrap>
          <v-flex xs4 class="pa-1" :key="item.syllabusId" v-for="(item, i) in results">
              <v-card>
                  <v-card-text>
                      {{item.title}}
                      <br/>
                      <small>Last updated at: {{new Date(item.updatedAt).toLocaleString()}}</small>
                  </v-card-text>
                  <v-divider/>
                  <v-card-actions>
                      <v-spacer/>
                      <v-btn small outlined @click="$router.push('/syllabus/update/'+item.syllabusId)">UPDATE</v-btn>
                  </v-card-actions>
              </v-card>
          </v-flex>
      </v-layout>
      <div class="text-center pa-2">
        <v-progress-circular indeterminate v-if="submitting"/>
      </div>
       <v-snackbar multi-line v-model="snackbar" top right>
        {{message}}
        <v-btn text outlined @click="snackbar = null">Close</v-btn>
      </v-snackbar>
   </div>
</template>

<script>
import PastQuestionMetadata from "@/components/SyllabusMetadata";
export default {
  components: {
    PastQuestionMetadata,
  },
  computed: {
    snackbar: {
      set(v) {
        this.message = null;
      },
      get() {
        return this.message != null;
      },
    },
  },
  methods: {
    async getList() {
      this.message = null;
      this.submitting = true;
      try {
        const { data } = await this.$http.get("/syllabus/list", {
          params: {
            country: this.syllabus.country,
            examGroup: this.syllabus.examGroup,
            exam: this.syllabus.exam,
          },
        });
        if (data.statusCode === 0) {
          this.results = data.data;
        } else {
          this.message = data.error.message;
        }
      } catch (error) {
        this.message = error.toString();
      }
      this.submitting = false;
    },
  },
  data: () => {
    return {
      message: null,
      submitting: false,
      results: [],
      syllabus: {
        title: null,
        country: null,
        examGroup: null,
        exam: null,
      },
    };
  },
};
</script>

<style>
</style>
