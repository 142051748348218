<template>
    <div class="pa-2">
        <v-layout align-center justify-center class="pt-4 pb-4" v-if="loading">
            <v-progress-circular indeterminate/>
        </v-layout>
        <div v-else-if="errorMessage">
            <h2>{{errorMessage}}</h2>
            <v-btn @click="refresh" color="primary" dark>RETRY</v-btn>
        </div>
        <div v-else>
            <v-card class="mb-1" v-for="(item, i) in result.filter(el=> el.update.length > 0 || el.create.length > 0)" :key="i">
                <v-card-text>
                    <h2>{{item.name}}</h2>
                    <v-layout>
                        <v-flex xs6 class="pa-1">
                            <h4 class="green--text">Update</h4>
                            <v-layout justify-space-between class="pt-1 pb-1" v-for="item1 in item.update" :key="item1._id">
                                <v-flex>
                                    {{new Date(item1._id).toDateString()}}
                                </v-flex>
                                {{item1.count}}
                            </v-layout>
                        </v-flex>
                        <v-flex xs6 class="pa-1">
                            <h4 class="green--text">Create</h4>
                            <v-layout justify-space-between class="pt-1 pb-1" v-for="item1 in item.create" :key="item1._id">
                                <v-flex>
                                    {{new Date(item1._id).toDateString()}}
                                </v-flex>
                                {{item1.count}}
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
    export default {
        name: "pastYearQuestionsStats",
        data() {
            return {
                result: [],
                errorMessage: null,
                loading: false,
            }
        },
        methods: {
            refresh() {
                this.errorMessage = null;
                this.loading = true;
                this.$http.get("/question/past/stats")
                    .then(res=>{
                        this.loading = false;
                        this.result = res.data.data;
                    }).catch(err=> {
                    this.loading = false;
                    this.errorMessage = err.toString()
                });
            }
        },
        mounted() {
            this.refresh()
        }
    }
</script>

<style scoped>

</style>