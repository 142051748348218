<template>
  <div class="pa-2">
    <h2 class="pa-1">Content</h2>
    <v-layout wrap>
      <v-flex xs12 md4 lg5 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Past Year Question</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/pastquestions/chapter-classify"
            >Classify</v-btn>
            <v-btn
                small
                color="blue"
                text
                outlined
                to="/report/past/all"
            >REPORTS</v-btn>
            <v-btn
                small
                color="yellow"
                text
                outlined
                to="/pastquestions/batchUpdate"
            >Batch</v-btn>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/pastquestions/update"
            >Update</v-btn>
            <v-btn
                small
                text
                outlined
                color="red"
                to="/pastquestions/new"
            >New</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Syllabus</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="green"
                text
                outlined
                @click="$router.push('/syllabus/update')"
            >Update</v-btn>
            <v-btn
                small
                text
                outlined
                color="red"
                @click="$router.push('/syllabus/add')"
            >New</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Theory</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/theory"
            >Update</v-btn>
            <v-btn
                small
                text
                outlined
                color="red"
                to="/theory/create"
            >Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <h2 class="pa-1">Test</h2>
    <v-layout wrap>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Tests</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="pink"
                text
                outlined
                to="/test-map"
            >MAPPING</v-btn>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/tests"
            >Update</v-btn>
            <v-btn
                small
                text
                outlined
                color="red"
                to="/tests/create"
            >New</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Questions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="yellow"
                text
                outlined
                to="/tests/questions"
            >Update</v-btn>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/tests/questions/create"
            >New</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Tests Id Map</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="pink"
                text
                outlined
                to="/test-id-map/list"
            >UPDATE</v-btn>
            <v-btn
                small
                color="yellow"
                text
                outlined
                to="/test-id-map"
            >NEW</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <h2 class="pa-1">Practice</h2>
    <v-layout wrap>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Questions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="yellow"
                text
                outlined
                to="/practice/questions"
            >Update</v-btn>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/practice/questions/create"
            >Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <h2 class="pa-1">Formulas</h2>
    <v-layout wrap>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Formulas</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="yellow"
                text
                outlined
                to="/formula"
            >Update</v-btn>
            <v-btn
                small
                color="green"
                text
                outlined
                to="/formula/create"
            >Create</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <h2 class="pa-1">User Data</h2>
    <v-layout wrap>
      <v-flex xs12 md4 lg3 class="pa-1">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                small
                color="green"
                text
                outlined
                @click="$router.push('/users')"
            >Manage</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "welcome"
};
</script>

<style scoped>
.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  max-width: 100%;
}

.link-btn {
  width: 150px;
}
</style>
