<template>
  <div class="pa-1">
    <h2 class="pa-2">Metadata</h2>
    <template v-for="(item, i) in q.metadata">
      <PastQuestionsMetadata v-model="q.metadata[i]" :key="i" ref="meta" :class="{'mt-2': i > 0}">
        <div class="text-right mt-2">
          <v-btn outlined @click="q.metadata.splice(i, 1)" color="red">Remove</v-btn>
        </div>
      </PastQuestionsMetadata>
    </template>
    <div class="text-right pa-2">
      <v-btn outlined color="green" @click="addMetadata">ADD METADATA</v-btn>
    </div>
    <v-card class="ma-1">
      <v-layout class="pa-2">
        <v-flex style="flex-grow: 1;">
          <v-btn
              color="primary"
              rounded
              outlined
              class="mr-1"
              @click="currentLang = lang"
              :key="lang"
              v-for="lang in languages"
              :disabled="currentLang === lang"
          >
            {{ $getLangName(lang) }}
            <v-btn
                small
                color="red"
                text
                style="height: 20px"
                @click.stop="removeLang(lang)"
                v-if="languages.length > 1 && lang !== 'en'"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-btn>
        </v-flex>
        <div>
          <v-dialog v-model="langDialog" width="400">
            <template v-slot:activator="{on}">
              <v-btn v-on="on" fab outlined small color="red">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  :key="item.value"
                  @click="addLang(item.value)"
                  v-for="item in $availLanguages.filter((el)=> languages.indexOf(el.value) === -1)"
              >
                <v-list-item-title>{{ $getLangName(item.value) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-dialog>
        </div>
      </v-layout>
      <v-divider/>
      <v-card-text ref="el">
        <v-layout style="gap: 8px">
          <v-flex xs6>
            <v-text-field
                v-model="q.formula[currentLang].title"
                outlined
                dense
                rounded
                label="Title"
            />
          </v-flex>
          <v-flex xs6>
            <v-select
                v-model="q.formula[currentLang].renderMode"
                outlined
                dense
                rounded
                :items="['mathjax', 'katex']"
                label="Rendered Mode"
            />
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex xs6 class="pa-1">
            <RichTextEditor
                outlined
                :render-mode="q.formula[currentLang].renderMode || 'mathjax'"
                v-model="q.formula[currentLang].content"
                label="Content"
            />
          </v-flex>
          <v-flex xs6 class="pa-1">
            <div style="border: 1px solid #d1d1d1;border-radius: 4px">
              <h2 class="text-center">Content Preview</h2>
              <v-divider/>
              <div class="pa-1 text-content font-math html-content" ref="content"></div>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn
            large
            color="red"
            dark
            :loading="sending"
            :disabled="sending"
            @click="submit"
        >Submit
        </v-btn>
      </div>
    </v-card>
    <v-dialog v-model="questionGetting" persistent width="300">
      <v-card color="red" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="questionGettingError" persistent width="300">
      <v-card color="red" dark>
        <v-card-text class="text-xs-center">
          <h2>Unknown Error Occurred</h2>
          <v-btn class="ma-2" flat outlined @click="getQuestion">Retry</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TestQuestionMetadata from "@/components/tests/TestQuestionMetadata";
import LangQuestionComponent from "@/components/LangQuestionComponent";
import MultiLanguageQuestionComponent from "@/components/MultiLanguageQuestionComponent";
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";
import RichTextEditor from "@/components/RichTextEditor";

export default {
  name: "createOrUpdate",
  components: {
    RichTextEditor,
    PastQuestionsMetadata, MultiLanguageQuestionComponent, LangQuestionComponent, TestQuestionMetadata
  },
  data() {
    return {
      currentLang: 'en',
      sending: false,
      langDialog: false,
      questionGetting: false,
      questionGettingError: false,
      q: {
        formulaId: null,
        metadata: [
          {
            country: null,
            examGroup: null,
            exam: null,
            subject: null,
            chapterGroup: null,
            chapter: null,
            topic: null,
            order: 0
          }
        ],
        formula: {
          en: this.getLangQuestionModel()
        }
      }
    }
  },
  computed: {
    languages() {
      return Object.keys(this.q.formula)
    }
  },
  methods: {
    removeLang(n) {
      if (this.languages.length === 1 || this.languages.indexOf(n) === -1)
        return false;
      this.$delete(this.q.question, n);
      if (this.currentLang === n) this.currentLang = this.languages[0];
    },
    addLang(v) {
      this.langDialog = false;
      if (this.languages.indexOf(v) === -1) {
        this.$set(this.q.formula, v, this.getLangQuestionModel());
      }
    },
    addMetadata() {
      this.q.metadata.push({
        country: null,
        examGroup: null,
        exam: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        topic: null,
        order: 0
      })
    },
    getLangQuestionModel() {
      return {
        title: null,
        content: null,
        derivation: null,
        renderMode: 'mathjax'
      }
    },
    async submit() {
      this.sending = true
      try {
        const {data} = await this.$formulaHttp.post("/createOrUpdate", {...this.q})
        if (data['statusCode'] === 0) {
          if (this.$route.params.id === "create") {
            for (let i in this.q.formula) {
              this.$set(this.q.formula, i, this.getLangQuestionModel());
            }
          }
          this.$message.success('Successful')
        } else {
          this.$message.error('Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.response ? e.response.data['error']['message'] : e.toString())
      }
      this.sending = false
    },
    getQuestion() {
      if (this.$route.params.id === "create") return
      this.questionGetting = true;
      this.questionGettingError = false;
      this.$formulaHttp
          .get("/" + this.$route.params.id)
          .then((res) => {
            this.questionGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.$message.success("Success!")
              this.$set(this, "q", json.data);
              for (const meta of this.$refs.meta) setTimeout(meta.populate, 1000);
            } else {
              this.questionGettingError = true;
            }
          })
          .catch((err) => {
            console.log(err)
            this.questionGetting = false;
            this.questionGettingError = true;
          });
    },
    refreshMathType(el) {
      if (this.q.formula[this.currentLang].renderMode === 'katex') {
        setTimeout(() => window.renderMathInElement(el, KatexConfig), 200)
      } else {
        this.$mathJaxRefresh(el);
      }
    }
  },
  mounted() {
    this.getQuestion()
  },
  watch: {
    'q.formula': {
      deep: true,
      handler() {
        if (this.q.formula[this.currentLang].content) {
          this.$refs.content.innerHTML = this.q.formula[this.currentLang].content
          this.refreshMathType(this.$refs.content)
        }
      }
    },
    "$route.params.id": function (val) {
      if (val !== "create") {
        this.getQuestion();
      }
    }
  },
  beforeDestroy() {
    this.$mathJaxClear(this.$refs.content)
  }
}
</script>

<style scoped>

</style>
