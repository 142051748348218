<template>
  <div class="pa-1">
    <div class="text-right" v-if="!select">
      <v-btn to="/tests/create" color="green" dark>CREATE</v-btn>
    </div>
    <v-layout wrap>
      <v-flex v-for="test in results" :key="test.testId" xs12 md6 lg4 class="pa-1">
        <v-card class="mt-2">
          <v-card-title>{{test.title['en']}}</v-card-title>
          <v-card-text>
            {{test.totalQuestions}} questions • {{test.maxMarks}} marks • {{test.timeAllotted / 60000}} minutes
            <div v-if="test.liveAt">Live At: {{new Date(test.liveAt).toLocaleString()}}</div>
          </v-card-text>
          <v-card-actions>
            <v-chip class="mr-2" :color="test.status === 'public' ? 'green' : 'red'" small>{{test.status}}</v-chip>&nbsp;
            <v-spacer/>
            <v-btn small outlined rounded min-width="100" color="red" @click="clone(test.testId)">Clone</v-btn>
            <v-btn outlined v-if="select" @click="() => { $emit('select', test)} " rounded color="green" width="220" small>SELECT TEST</v-btn>
            <v-btn small outlined v-else :to="'/tests/'+test.testId" rounded color="green" width="220">VIEW TEST</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "tests-list",
  props: {
    select: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    async getTests() {
      try {
        const {data} = await this.$testHttp.get('/test')
        if (data['statusCode'] === 0) {
          this.results = data['results']
        } else {
          this.$message.error('Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
    },
    async clone(id) {
      const alert = confirm("Do you want to clone this test?")
      if(!alert) return;
      try {
        const {data} = await this.$testHttp.post('/test/clone', {id})
        if (data['statusCode'] === 0) {
          this.$message.success('Successful')
          this.getTests();
        } else {
          this.$message.error('Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
    }
  },
  data() {
    return {
      results: []
    }
  },
  mounted() {
    this.getTests()
  }
}
</script>

<style scoped>

</style>
