<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <LangFieldEditor class="ma-1" v-model="test.title" label="Title"/>
        <LangFieldEditor class="ma-1" v-model="test.description" label="Description" textArea/>
        <LangFieldEditor class="ma-1" v-model="test.instructions" label="Instructions" textArea/>
        <LangFieldEditor class="ma-1" v-model="test.syllabus" label="Syllabus" textArea/>
        <v-layout wrap>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-text-field v-model="test.totalQuestions" type="number" label="Total Questions" outlined hide-details
                          dense/>
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-text-field v-model="test.maxMarks" type="number" label="Max Marks" outlined hide-details dense/>
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-text-field v-model="test.maxNegMarks" type="number" label="Max Negative Marks" outlined hide-details
                          dense/>
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-text-field v-model="test.timeAllotted" type="number" label="Time (Milliseconds)" outlined hide-details
                          dense/>
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-select
                outlined
                hide-details
                dense
                multiple
                :items="$availLanguages"
                label="Languages"
                v-model="test.languages"
                @input="() => $emit('onChange')"
            />
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-select
                outlined
                hide-details
                dense
                :items="status"
                label="Status"
                v-model="test.status"
                @input="() => $emit('onChange')"
            />
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-select
                outlined
                hide-details
                dense
                :items="layouts"
                label="Layout"
                v-model="test.layout"
                @input="() => $emit('onChange')"
            />
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-select dense outlined hide-details v-model="test.defaultLanguage" :items="$availLanguages"
                      label="Default Language"/>
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-select
                outlined
                hide-details
                dense
                :items="difficulty_levels"
                label="Difficulty Level"
                v-model="test.difficulty"
            />
          </v-flex>
          <v-flex xs12 lg2 class="pa-1">
            <v-select
                outlined
                dense
                hide-details
                height="20"
                :items="policy"
                v-model="test.policy"
                label="Select Test Policy"
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 lg2 class="pa-1">
            <v-text-field v-model="test.maxAttempt" type="number" label="Max Attempt" outlined hide-details
                          dense/>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 lg4 class="pa-1">
            <h4>Live At</h4>
            <DateTimeField v-model="test.liveAt"/>
          </v-flex>
          <v-flex xs12 lg2 class="pa-1" align-self-center>
            <v-container fluid>
              <v-switch label="Premium" v-model="test.isPremium"/>
            </v-container>
          </v-flex>
          <v-flex xs12 lg2 class="pa-1" align-self-center>
            <v-container fluid>
              <v-switch label="Analysis Deleteble" v-model="test.isAnalysisDeletable"/>
            </v-container>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import Platform from "@/components/PlatformComponent";
import Metadata from "@/pages/pastquestions/lib/metadata";
import DateTimeField from "@/components/DateTimeField";

export default {
  name: "TestMetadata",
  components: {DateTimeField, Platform, LangFieldEditor},
  props: {
    value: {
      type: Object,
      required: true,
      default: null
    },
  },
  methods: {
    async getExamGroups() {
      await Metadata.getExamGroups(
          this,
          {
            country: this.test.country,
          },
          (data) => {
            this.exam_groups = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.examGroupGetting = true;
            this.examGroupGettingError = false;
          },
          () => {
            this.examGroupGetting = false;
          },
          () => {
            this.examGroupGettingError = true;
          }
      );
    },
    async getExams() {
      await Metadata.getExams(
          this,
          {
            country: this.test.country,
            examGroup: this.test.examGroup,
          },
          (data) => {
            this.exams = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.examGetting = true;
            this.examGettingError = false;
          },
          () => {
            this.examGetting = false;
          },
          () => {
            this.examGettingError = true;
          }
      );
    },
  },
  data() {
    return {
      dateDialog: false,
      timePickerDialog: false,
      examGroupGetting: false,
      examGroupGettingError: false,
      examGetting: false,
      examGettingError: false,
      exam_groups: [],
      exams: [],
      time: {
        date: null,
        time: null
      },
      policy: [
        {
          text: "JEE Advanced",
          value: "jee-advanced",
        },
        {
          text: "JEE Main",
          value: "jee-main",
        },
        {
          text: "NEET",
          value: "neet"
        },
      ],
      layouts: [
        {
          text: "Default",
          value: "default"
        },
      ],
      status: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Review",
          value: "review",
        },
        {
          text: "Private",
          value: "Private",
        },
      ],
      difficulty_levels: [
        {
          text: "Easy",
          value: "easy",
        },
        {
          text: "Medium",
          value: "medium",
        },
        {
          text: "Hard",
          value: "hard",
        },
      ]
    }
  },
  computed: {
    test() {
      return this.value;
    }
  },
  watch: {
    'time.date'(newValue, oldValue) {
      if (newValue) {
        this.test.liveAt = new Date(`${newValue}${this.time.time ? ` ${this.time.time}` : ''}`).toISOString()
      }
    },
    'time.time'(newValue, oldValue) {
      if (newValue && this.time.date) {
        this.test.liveAt = new Date(`${this.time.date} ${newValue}`).toISOString()
      }
    },
    'test.liveAt'(newValue, oldValue) {
      if (newValue && !this.time.date) {
        const d = new Date(newValue);
        this.time.date = d.toLocaleDateString().split("/").reverse().join("-")
        this.time.time = d.toLocaleTimeString()
      }
    }
  },
}
</script>

<style scoped>

</style>
