<template>
    <div class="pt-2 pb-2">
        <v-layout column align-center justify-center v-if="loading || errorMessage" class="pa-2">
            <v-progress-circular indeterminate v-if="loading"/>
            <div v-if="errorMessage && !loading">{{errorMessage}}</div>
            <v-btn v-if="errorMessage && !loading" @click="getReport">RETRY</v-btn>
        </v-layout>
        <v-layout v-if="report">
            <v-flex xs6 class="pa-1">
                <v-card>
                    <v-card-title><h3>Report Info</h3></v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-layout justify-space-between class="mb-1">
                           <div>Report Id</div>
                           <div>{{report._id}}</div>
                        </v-layout>
                        <v-layout justify-space-between class="mb-1">
                            <div>Question Id</div>
                            <div>{{report.questionID || report.question_id}}</div>
                        </v-layout>
                        <v-layout justify-space-between class="mb-1">
                            <div>Report Type</div>
                            <div class="green--text font-weight-bold">{{(report.reportType || report.type).toUpperCase()}}</div>
                        </v-layout>
                        <v-layout justify-space-between class="mb-1">
                            <div>Package</div>
                            <div><b>{{report.appPackage}}</b></div>
                        </v-layout>
                        <v-layout justify-space-between>
                            <div>Time</div>
                            <div>{{isNaN(report.timestamp) ? new Date(report.timestamp).toLocaleString() : new Date(report.timestamp * 1000).toLocaleString()}}</div>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
            <v-flex xs6 class="pa-1">
                <v-card>
                    <v-card-title><h3>Device Info</h3></v-card-title>
                    <v-divider/>
                    <v-card-text>
                        <v-layout justify-space-between class="mb-1">
                            <div>Brand</div>
                            <div>{{report.DEVICE_BRAND || report.device.brand}}</div>
                        </v-layout>
                        <v-layout justify-space-between class="mb-1">
                            <div>Model</div>
                            <div>{{report.DEVICE_MODEL || report.device.model}}</div>
                        </v-layout>
                        <v-layout justify-space-between class="mb-1">
                            <div>Manufacturer</div>
                            <div>{{report.DEVICE_MANUFACTURER || report.device.manufacturer}}</div>
                        </v-layout>
                        <v-layout justify-space-between class="mb-1">
                            <div>OS Version</div>
                            <div>{{report.OS_VERSION || report.device.os}}</div>
                        </v-layout>
                        <v-layout justify-space-between>
                            <div>SDK</div>
                            <div>{{report.SDK || report.device.sdk}}</div>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-card class="mr-1 ml-1 mb-1" v-if="report">
            <v-card-title><h2>Report Details</h2></v-card-title>
            <v-divider/>
            <v-card-text>{{report.details || 'No Details Found'}}</v-card-text>
        </v-card>
        <SinglePastQuestionInfo v-if="report" :question="report.question" class="mr-1 ml-1"/>
        <v-layout justify-center v-if="report" class="mt-2">
            <v-btn @click="deleteReport" color="red" dark large :loading="deletingReport" :disabled="deletingReport">DELETE REPORT</v-btn>
        </v-layout>
    </div>
</template>

<script>
import SinglePastQuestionInfo from "../../../components/SinglePastQuestionInfo";

export default {
        name: "SingleReport",
        components: {SinglePastQuestionInfo},
        methods: {
            getReport() {
                this.loading = true;
                this.errorMessage = null;
                this.$pqhttp.get("/report/"+this.$route.params.id)
                .then(res=>{
                    this.loading = false;
                    if(res.data.statusCode === 0){
                        this.report = res.data.data;
                    }else {
                        this.$message.error("Failed to Load Report");
                        this.errorMessage = "Failed to Load Report";
                    }
                }).catch(err=>{
                    this.loading = false;
                    this.$message.error(err.toLocaleString());
                    this.errorMessage = err.toLocaleString();
                })
            },
            deleteReport(){
                this.deletingReport = true;
                this.$pqhttp.delete("/report/"+this.$route.params.id)
                    .then(res=>{
                        this.deletingReport = false;
                        if(res.data.statusCode === 0){
                            this.$message.success("Successfully deleted report");
                            this.$router.push('/report/past/all');
                        }else {
                            this.$message.error("Failed to delete report");
                        }
                    }).catch(error => {
                    this.deletingReport = false;
                    this.$message.error(error.toLocaleString());
                });
            }
        },
        data() {
            return {
                deletingReport: false,
                loading: false,
                errorMessage: null,
                report: null
            }
        },
        mounted() {
            this.getReport();
        }
    }
</script>

<style scoped>

</style>
