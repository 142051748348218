<template>
  <div class="pa-2">
    <div class="text-right">
      <v-btn :to="'/notification/create'" large color="green" dark>CREATE CAMPAIGN</v-btn>
    </div>
    <v-card class="mt-1">
      <v-card-title>Campaigns</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-layout wrap v-for="message in results" :key="message.messageId" align-center class="mt-1 mb-1">
          <v-chip outlined small :color="message.status === 'COMPLETE' ? 'green' : ''">{{message.status}}</v-chip>
          <v-flex grow="1" class="pl-2">
            <h4>{{message.title}}</h4>
            <div>Scheduled At: {{new Date(message.scheduledAt).toString()}}</div>
          </v-flex>
          <v-btn small outlined :to="'/notification/update/'+message.messageId">EDIT</v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      isLoading: false,
      results: []
    }
  },
  methods: {
    async list() {
      this.isLoading = true
      try {
        const {data} = await this.$notiHttp.get('/list')
        if (data['statusCode'] === 0) {
          this.results = data['results']
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : "Unknown Error Occurred!")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isLoading = false;
    }
  },
  mounted() {
    this.list()
  }
}
</script>

<style scoped>

</style>
