<template>
  <div>
    <v-layout v-if="!disabled" wrap class="c">
      <button class="btn" @click.stop="change('p', true)">&lt;p&gt;</button>
      <button class="btn" @click.stop="change('br', false)">&lt;br/&gt;</button>
      <button class="btn" @click.stop="change('sup', true)">&lt;sup&gt;</button>
      <button class="btn" @click.stop="change('sub', true)">&lt;sub&gt;</button>
      <button class="btn" @click.stop="change('i', true)">&lt;i&gt;</button>
      <button class="btn" @click.stop="change('b', true)">&lt;b&gt;</button>
      <button class="btn" @click.stop="change('div', true)">&lt;div&gt;</button>
      <button class="btn" @click.stop="change('pre', true)">&lt;pre&gt;</button>
      <button class="btn" @click.stop="change('code', true)">&lt;code&gt;</button>
      <button class="btn" @click.stop="change('&nbsp;', true, true)">&amp;nbsp;</button>
      <v-menu v-model="menu" :nudge-width="400" max-width="600" offset-y>
        <template v-slot:activator="{ on }">
          <button v-on="on" class="btn">
            <b>MathType</b>
          </button>
        </template>
        <v-card ref="mathtype-dialog">
          <v-card-title class="blue white--text">MathType Shortcuts</v-card-title>
          <v-card-text>
            <v-layout wrap justify-start class="pa-2">
              <v-flex>
                <v-btn
                        text
                  icon
                  outlined
                  @click.stop="change('$$ \\therefore $$', true, true)"
                >$$ \therefore $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                        text
                        icon
                        outlined
                  @click.stop="change('$$ \\because $$', true, true)"
                >$$ \because $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                        text
                        icon
                        outlined
                  @click.stop="change('$$ \\Rightarrow $$', true, true)"
                >$$ \Rightarrow $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\ne $$', true, true)">$$ \ne $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon
                       outlined @click.stop="change('$$ \\to $$', true, true)">$$ \to $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\pm $$', true, true)">$$ \pm $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\times $$', true, true)"
                >$$ \times $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\ge $$', true, true)">$$ \ge $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\le $$', true, true)">$$ \le $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\cong $$', true, true)"
                >$$ \cong $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\simeq $$', true, true)"
                >$$ \simeq $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\pi $$', true, true)">$$\pi $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\theta $$', true, true)"
                >$$\theta $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\alpha $$', true, true)"
                >$$\alpha $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\beta $$', true, true)">$$\beta $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\delta $$', true, true)"
                >$$\delta $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\varepsilon $$', true, true)"
                >$$\varepsilon $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\phi $$', true, true)">$$\phi $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\varphi $$', true, true)"
                >$$\varphi $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\gamma $$', true, true)"
                >$$\gamma $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\lambda $$', true, true)"
                >$$\lambda $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\mu $$', true, true)">$$\mu $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\rho $$', true, true)">$$\rho $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\sigma $$', true, true)"
                >$$\sigma $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\tau $$', true, true)">$$\tau $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\upsilon $$', true, true)"
                >$$\upsilon $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\psi $$', true, true)">$$\psi $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\Delta $$', true, true)"
                >$$\Delta $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\Lambda $$', true, true)"
                >$$\Lambda $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\Sigma $$', true, true)"
                >$$\Sigma $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\bot $$', true, true)">$$ \bot $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\wedge $$', true, true)"
                >$$ \wedge $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\vee $$', true, true)">$$ \vee $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\in $$', true, true)">$$ \in $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\notin $$', true, true)"
                >$$ \notin $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\cup $$', true, true)">$$ \cup $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\cap $$', true, true)">$$ \cap $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\subset $$', true, true)"
                >$$ \subset $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\supset $$', true, true)"
                >$$ \supset $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\subseteq $$', true, true)"
                >$$ \subseteq $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\supseteq $$', true, true)"
                >$$ \supseteq $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\not\\subset $$', true, true)"
                >$$ \not\subset $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\emptyset $$', true, true)"
                >$$\emptyset $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$\\ell $$', true, true)">$$\ell $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\div $$', true, true)">$$ \div $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\mp $$', true, true)">$$ \mp $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\equiv $$', true, true)"
                >$$ \equiv $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$\\infty $$', true, true)"
                >$$\infty $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn text icon outlined @click.stop="change('$$ \\sim $$', true, true)">$$ \sim $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\propto $$', true, true)"
                >$$ \propto $$</v-btn>
              </v-flex>
              <v-flex>
                <v-btn
                  text
                  icon
                  outlined
                  @click.stop="change('$$ \\supseteq $$', true, true)"
                >$$\Theta $$</v-btn>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-menu>
      <button
        class="btn red--text"
        style="font-size: 12px"
        @click.stop="$store.commit('setUploadDialogState', true)"
      >
        <i class="material-icons">cloud_upload</i>
      </button>
    </v-layout>
    <textarea
      ref="textArea"
      @input="onChange"
      :disabled="disabled"
      :value="value"
      :placeholder="label"
      class="textArea"
      rows="5"
      style="color: inherit"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: "RichTextEditor",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String | null,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    },
    renderMode: {
      type: String,
      default: 'mathjax',
      required: false
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.$refs.textArea.value);
    },
    change(tag, iend, only) {
      this.menu = false;
      this.$refs.textArea.focus();
      let textarea = this.$refs.textArea;
      let len = textarea.value.length;
      let start = textarea.selectionStart;
      let end = textarea.selectionEnd;
      let sel = textarea.value.substring(start, end);
      let replace = "";
      if (!only) {
        if (!iend) {
          replace = sel.concat("<".concat(tag).concat("/>"));
        } else {
          replace = "<"
            .concat(tag)
            .concat(">")
            .concat(sel)
            .concat("</")
            .concat(tag)
            .concat(">");
        }
      } else {
        replace = sel.concat(tag);
      }
      document.execCommand("insertText", false, replace);
      this.mathJaxRefresh();
    },
    mathJaxRefresh() {
      if(this.renderMode === 'katex') {
        setTimeout(() => {
          window.renderMathInElement(this.$refs["mathtype-dialog"].$el, KatexConfig)
        }, 200)
      }else {
        this.$mathJaxRefresh(this.$refs["mathtype-dialog"].$el);
      }
    }
  },
  data() {
    return {
      menu: false
    };
  },
  watch: {
    menu(newValue) {
      if (newValue) {
        this.$nextTick(this.mathJaxRefresh)
      } else {
        window.MathJax.typesetClear([this.$refs["mathtype-dialog"].$el])
      }
    }
  },
};
</script>

<style scoped>
.c {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.btn {
  padding: 6px 8px 6px 8px;
  outline: none;
  border-right: 1px solid #f1f1f1;
}
.btn:hover {
  background-color: #e9f4ff;
  color: #2e88ff;
  font-weight: bold;
}
.btn:hover i {
  color: #2e88ff;
}
.textArea {
  width: 100%;
  padding: 4px;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  outline: none;
  resize: both;
}
.textArea:focus {
  outline: 1px solid #0d47a1;
}
.textArea:disabled {
  background-color: rgb(235, 235, 228);
}
</style>
