<template>
  <v-layout class="mt-2" justify-center align-center>
     <v-flex xs12 lg6>
         <v-card>
           <v-card-title>Create A Job</v-card-title>
           <v-card-text>
             <v-file-input :disabled="loading" v-model="file" label="Choose PDF File" accept="application/pdf" outlined dense hide-details/>
           </v-card-text>
           <div class="pb-2 text-center">
             <v-btn color="primary" :loading="loading" :disabled="loading" @click="create">CREATE</v-btn>
           </div>
         </v-card>
     </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "create-job",
  methods: {
    async create() {
      this.loading = true;
      try {
        let fo = new FormData();
        fo.append("pdfFile", this.file);
        const {data} = await this.$http.post("/vision/pdf", fo)
        if(data.statusCode === 0){
          this.$message.success("Success")
        }else {
          this.$message.error(data.error ? data.error.message : "Unknown Error")
        }
      }catch (e) {
        this.$message.error(e.toString())
      }
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      file: null
    }
  },
}
</script>

<style scoped>

</style>