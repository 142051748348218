<template>
   <div class="pa-2">
     <div class="text-center">
        <v-progress-circular indeterminate color="green" v-if="loading"/>
        <h2 v-if="loadingError">{{loadingError}}</h2>
        <v-btn v-if="loadingError" @click="getUsers">RETRY</v-btn>
     </div>
     <v-layout wrap>
       <v-flex xs12 md6 lg4 v-for="item in users" :key="item.userId">
         <v-card class="ma-1">
           <v-card-text>
             <h3>Name</h3>
             <p class="pa-0 ma-0">{{item.name}}</p>
           </v-card-text>
           <v-divider/>
           <v-card-actions>
             <v-spacer/>
             <v-btn :to="`/freelance/${item.userId}/docs`" text outlined color="green" small>DOCS</v-btn>
             <v-btn :to="`/freelance/${item.userId}/past-question`" text outlined color="green" small>PAST QUESTION</v-btn>
             <v-btn :to="`/freelance/${item.userId}/test-question`" text outlined color="green" small>TEST QUESTION</v-btn>
             <v-btn :to="`/freelance/${item.userId}/practice-question`" text outlined color="green" small>PRACTICE QUESTION</v-btn>
           </v-card-actions>
           <v-divider/>
           <v-card-title>Explanation</v-card-title>
           <v-card-actions>
             <v-spacer/>
             <v-btn :to="`/freelance/${item.userId}/questions`" text outlined color="green" small>View Questions</v-btn>
             <v-btn :to="`/freelance/${item.userId}/select-questions`" text outlined color="green" small>Send Questions</v-btn>
           </v-card-actions>
         </v-card>
       </v-flex>
     </v-layout>
   </div>
</template>

<script>
export default {
name: "users",
  data() {
    return {
      loading: false,
      loadingError: null,
      users: []
    }
  },
  methods: {
   async getUsers() {
     this.loading = true;
     this.loadingError = null;
      try {
        const {data} = await this.$http.get("/freelance/users");
        if(data.statusCode === 0){
          this.users = data.results
        }else {
          this.loadingError = data.error ? data.error.message : "Unknown Error";
        }
      }catch (e) {
        this.loadingError = e.toString()
      }
      this.loading = false
    }
  },
  mounted() {
     this.getUsers();
  }
}
</script>

<style scoped>

</style>
