<template>
  <div class="pa-2">
    <v-card class="ma-1">
      <v-card-text>
        <v-switch v-model="topMode" label="Top Mode" class="mt-0"/>
        <v-text-field v-model="query" label="Search" outlined dense hide-details/>
        <v-layout justify-space-between align-center>
          <v-switch v-model="mutliDelete" label="Multi Delete" @change="(v) => this.deleteItems = []" hide-details/>
          <v-btn v-if="mutliDelete" color="green" @click="selectAllDelete" outlined>
            {{ this.deleteItems.length === this.results.length ? 'UNSELECT ALL' : 'SELECT ALL' }}
          </v-btn>
        </v-layout>
        <v-btn v-if="mutliDelete" width="100%" :disabled="deleteItems.length <= 0" color="red" rounded
               @click="deleteMulti" class="mt-1">Delete
        </v-btn>
        <v-btn v-if="mutliDelete" width="100%" :disabled="!questionId" color="orange darken-2" rounded
               @click="deleteThisQuestion" class="mt-1">Delete of this Question
        </v-btn>
        <v-btn v-if="questionId" @click="questionId = null" outlined small class="mt-2" color="red">{{ questionId }}
        </v-btn>
      </v-card-text>
    </v-card>
    <div v-if="topMode" ref="el">
      <v-card v-for="(item, i) in results" :key="i" class="mt-1">
        <v-card-text class="green--text">Total Reported times {{ item.count }}</v-card-text>
        <v-divider/>
        <v-card-text
            v-html="item.question.question['en'] ? item.question.question['en'].content : item.question.question[Object.keys(item.question.question)[0]].content"/>
        <v-card-text
            v-if="item.question.question['en'] ? item.question.question['en'].options : item.question.question[Object.keys(item.question.question)[0]].options"
            v-for="(option, ii) in item.question.question['en'] ? item.question.question['en'].options : item.question.question[Object.keys(item.question.question)[0]].options"
            :key="ii">
          <div class="headline pb-2">Option {{ option.identifier }}</div>
          <div v-html="option.content"/>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn text color="green" @click="questionId = item.question.question_id">Filter</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-layout wrap v-if="!topMode">
      <v-flex xs12 md6 lg4 v-for="(item, i) in results" :key="i" class="pa-1">
        <v-card class="mb-1">
          <v-layout justify-space-between align-center class="pa-2">
            <div>
              <v-checkbox v-if="mutliDelete" hide-details :value="deleteItems.indexOf(item._id) >= 0"
                          @click="checkBox(item)" style="display: inline"/>
              <span class="caption">Time: {{
                  isNaN(item.timestamp) ? new Date(item.timestamp).toLocaleString() : new Date(item.timestamp * 1000).toLocaleString()
                }}</span>
            </div>
            <v-chip small>{{ item.appPackage }}</v-chip>
          </v-layout>
          <v-divider/>
          <v-card-text style="height: 80px">
            {{ item.details || 'No Details Found' }}
          </v-card-text>
          <v-divider/>
          <v-card-actions>
            <v-btn
                text outlined
                @click="()=> item.show = !item.show"
            >
              DEVICE INFO
              <v-icon>keyboard_arrow_{{ item.show ? 'up' : 'down' }}</v-icon>
            </v-btn>
            <v-spacer/>
            <v-btn text small outlined color="purple" target="_blank" :to="'/report/past/'+item._id">VIEW REPORT</v-btn>
            <v-btn text small outlined color="red" @click="deleteReportId = item._id">DELETE</v-btn>
            <v-btn text small outlined color="green" @click="questionId = item.question_id">FILTER</v-btn>
          </v-card-actions>
          <v-expand-transition>
            <div :style="{display: item.show ? 'block' : 'none'}">
              <v-divider/>
              <v-card-text>
                <h4>Device</h4>
                <v-layout align-center justify-space-between class="mb-1">
                  <div>Brand: {{ item.DEVICE_BRAND || item.device.brand }}</div>
                  <div>Model: {{ item.DEVICE_MODEL || item.device.model }}</div>
                </v-layout>
                <v-layout align-center justify-space-between class="mb-1">
                  <div>Manufacturer: {{ item.DEVICE_MANUFACTURER || item.device.manufacturer }}</div>
                  <div>Board: {{ item.DEVICE_BOARD || item.device.board }}</div>
                </v-layout>
                <v-layout align-center justify-space-between>
                  <div>Android OS: {{ item.OS_VERSION || item.device.os }}</div>
                  <div>Android SDK: {{ item.SDK || item.device.sdk }}</div>
                </v-layout>
              </v-card-text>
            </div>
          </v-expand-transition>
        </v-card>
      </v-flex>
    </v-layout>
    <v-card-text>
      <v-layout align-center justify-center>
        <v-progress-circular indeterminate color="blue" v-if="loading && !noMore"/>
        <div v-if="!loading && noMore">No Reports Found</div>
        <v-btn v-if="!loading && !noMore" @click="getList">LOAD MORE</v-btn>
      </v-layout>
    </v-card-text>
    <v-dialog v-model="deleteDialog" max-width="350" :persistent="deletingReport">
      <v-card>
        <v-card-text>Do you want to delete report by id <b>{{ deleteReportId }}</b>? It can't be undone.</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="deleteReport" flat color="green" :disabled="deletingReport" :loading="deletingReport">YES
          </v-btn>
          <v-btn @click="deleteDialog = false" flat color="red" :disabled="deletingReport">NO</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "AllReports",
  computed: {
    deleteDialog: {
      set(v) {
        this.deleteReportId = null;
      },
      get() {
        return this.deleteReportId != null;
      }
    }
  },
  methods: {
    checkBox(item) {
      const i = this.deleteItems.indexOf(item._id)
      if (i >= 0) {
        this.deleteItems.splice(i, 1)
      } else {
        this.deleteItems.push(item._id)
      }
    },
    selectAllDelete() {
      if (this.deleteItems.length === this.results.length) {
        this.deleteItems = []
      } else {
        this.deleteItems = Array.from(new Set(this.results.map(el => el._id)))
      }
    },
    deleteThisQuestion() {
      this.$pqhttp.delete("/report/q/" + this.questionId)
          .then(res => {
            if (res.data.statusCode === 0) {
              this.$message.success('Successful')
              this.questionId = null
              this.deleteItems = []
              this.page = 0
              this.getList()
            } else {
              this.$message.error("Failed delete");
            }
          }).catch(err => {
        this.loading = false;
        this.$message.error(err.toLocaleString());
      })
    },
    deleteMulti() {
      this.$pqhttp.post("/report/deleteMulti", this.deleteItems)
          .then(res => {
            if (res.data.statusCode === 0) {
              this.$message.success('Successful')
              this.deleteItems = []
              this.page = 0
              this.getList()
            } else {
              this.$message.error("Failed delete");
            }
          }).catch(err => {
        this.loading = false;
        this.$message.error(err.toLocaleString());
      })
    },
    getList() {
      if (this.page === 0) this.results = []
      this.loading = true;
      this.noMore = false;
      if (this.topMode) {
        this.$pqhttp.get('/report/list/top', {params: {page: this.page}})
            .then(res => {
              this.loading = false;
              if (res.data.statusCode === 0) {
                this.results = [...this.results, ...res.data.results.map(el => {
                  return {...el, question: el.data[0]}
                })];
                if (res.data.results.length === 20) {
                  this.page += 1;
                } else {
                  this.noMore = true;
                }
                this.$mathJaxRefresh(this.$refs.el)
              } else {
                this.$message.error("Failed to Load List");
              }
            }).catch(err => {
          this.loading = false;
          this.$message.error(err.toLocaleString());
        })
      } else {
        this.$pqhttp.get("/report/list", {params: {page: this.page, q: this.query, questionId: this.questionId}})
            .then(res => {
              this.loading = false;
              if (res.data.statusCode === 0) {
                this.results = [...this.results, ...res.data.results.map(el => {
                  return {...el, show: false}
                })];
                if (res.data.results.length === 20) {
                  this.page += 1;
                } else {
                  this.noMore = true;
                }
              } else {
                this.$message.error("Failed to Load List");
              }
            }).catch(err => {
          this.loading = false;
          this.$message.error(err.toLocaleString());
        })
      }
    },
    deleteReport() {
      if (this.deletingReport) return;
      this.deletingReport = true;
      this.$pqhttp.delete("/report/" + this.deleteReportId)
          .then(res => {
            this.deletingReport = false;
            if (res.data.statusCode === 0) {
              const id = this.deleteReportId;
              this.deleteReportId = null;
              this.$message.success("Successfully deleted report");
              this.results.splice(this.results.indexOf(this.results.filter(el => el._id === id)[0]), 1);
            } else {
              this.$message.error("Failed to delete report");
            }
          }).catch(error => {
        this.deletingReport = false;
        this.$message.error(error.toLocaleString());
      });
    },
  },
  data() {
    return {
      deletingReport: false,
      deleteReportId: null,
      results: [],
      loading: false,
      noMore: false,
      page: 0,
      query: null,
      questionId: null,
      mutliDelete: false,
      deleteItems: [],
      topMode: false
    }
  },
  watch: {
    query(newValue, oldValue) {
      this.page = 0
      this.getList()
    },
    questionId(newValue) {
      this.page = 0
      this.topMode = false
      this.getList()
    },
    topMode(newValue) {
      this.results = []
      this.page = 0
      this.getList()
      if (!newValue) window.MathJax.typesetClear([this.$refs.el])
    }
  },
  mounted() {
    this.getList();
  },
  beforeDestroy() {
    window.MathJax.typesetClear([this.$refs.el])
  }
}
</script>

<style scoped>

</style>
