<template>
  <div class="pa-1">
    <div class="text-right">
      <v-btn to="/subscriptions/new" color="primary">CREATE NEW</v-btn>
    </div>
    <div class="pa-2" v-if="error">
      <h4>{{error}}</h4>
      <v-btn @click="loadProducts">Retry</v-btn>
    </div>
    <div class="text-center" v-if="isLoading"><v-progress-circular indeterminate color="red" /></div>
    <v-layout wrap>
      <v-flex xs12 lg4 v-for="(item, i) in products" :key="i" class="pa-1">
        <v-card>
          <h4 class="pa-2">{{item['title']}}</h4>
          <v-divider/>
          <v-card-actions>
            <v-spacer />
            <v-btn outlined text color="green" :to="'/subscriptions/'+ item.productId">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      products: [],
      isLoading: false,
      error: null
    }
  },
  methods: {
    async loadProducts() {
      this.products = []
      this.isLoading = true
      this.error = null
      try {
        const {data} = await this.$subsHttp.get("/products")
        if(data['statusCode'] === 0){
          this.products = data['results']
        }else {
          this.error = data['error'] ? data.error.message : "Unknown Error"
        }
      } catch (e) {
        this.error = e.toString()
      }
      this.isLoading = false
    }
  },
  mounted() {
    this.loadProducts()
  }
}
</script>

<style scoped>

</style>