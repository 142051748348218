<template>
  <div class="pa-1">
    <PastQuestionsMetadata ref="meta" v-model="q" @papers="(v) => papers = v"/>
    <h3 class="mx-2 mt-2">Update</h3>
    <v-layout wrap class="mt-1">
      <v-flex xs12 lg8>
        <v-card class="mt-1">
          <v-card-text>
            <v-layout wrap>
              <v-flex xs12 lg6>
                <v-select outlined dense hide-details label="Paper" :items="papers" v-model="u.paperId" @input="(b) => { u.paperTitle = papers.filter(el => el.value === b)[0].text }"/>
              </v-flex>
              <v-flex xs12 lg6 class="pl-1">
                <v-text-field label="Paper Title" dense outlined disabled hide-details v-model="u.paperTitle" />
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <Platform v-model="u" />
      </v-flex>
      <v-flex xs12 lg4 class="pa-1">
        <v-card>
          <v-card-text>
            <v-select outlined dense hide-details v-model="u.status" :items="status" label="Status"/>
          </v-card-text>
        </v-card>
        <v-card class="mt-1" dark>
          <h3 class="pa-3">Section Specification</h3>
          <v-divider/>
          <v-container>
            <v-layout align-center>
              <v-flex xs6>
                <v-checkbox
                    dense
                    v-model="u.section"
                    label="Chapter"
                    color="red"
                    value="chapter"
                    multiple
                    hide-details
                ></v-checkbox>
              </v-flex>
              <v-flex xs6>
                <v-checkbox
                    dense
                    v-model="u.section"
                    label="Paper"
                    color="red"
                    value="paper"
                    multiple
                    hide-details
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pa-2 text-center">
      <v-btn large outlined color="red" :disabled="isSubmitting || !isValidInput" @click="submit">SUMIT</v-btn>
    </div>
  </div>
</template>

<script>
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";
import Platform from "@/components/PlatformComponent";

export default {
  name: "batchUpdate",
  components: {Platform, PastQuestionsMetadata},
  data() {
    return {
      q: {
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        paperId: null
      },
      u: {
        paperTitle: null,
        paperId: null,
        platform: [],
        section: [],
        status: 'private'
      },
      status: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Review",
          value: "review",
        },
        {
          text: "Private",
          value: "private",
        }
      ],
      papers: [],
      isSubmitting: false
    }
  },
  methods: {
    async submit() {
      this.isSubmitting = true
      try {
        const u = {...this.u}
        if(!u.paperId) delete u.paperId
        if(!u.paperTitle) delete u.paperTitle
        const {data} = await this.$pqhttp.post("/batchUpdate", {
          query: this.q,
          update: u
        })
        if (data.statusCode === 0) {
          this.$message.success(data['message'] || "Successful")
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : 'Unknown Error Occurred!')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isSubmitting = false
    }
  },
  computed: {
    isValidInput() {
      return this.q.country && this.q.examGroup && this.q.exam && this.q.paperId
    }
  },
}
</script>

<style scoped>

</style>
