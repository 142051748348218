<template>
  <v-card :color="$vuetify.theme.dark ? '' : 'blue'" dark class="pa-1">
    <v-card-text>
      <slot name="header"></slot>
      <v-layout wrap>
        <v-flex xs12 md6 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="$availCountries"
              v-model="q.country"
              @input="()=>{ resetField('examGroup', 'exam', 'subject', 'chapterGroup', 'chapter', 'topic', 'paperId'); exam_groups = subject_groups = []; subjects = []; chapter_groups = []; chapters = []; topics = []; getExamGroups();}"
              label="Select Country"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="exam_groups"
              v-model="q.examGroup"
              @input="()=>{ resetField('exam', 'subject', 'chapterGroup', 'chapter', 'topic', 'paperId'); subjects = []; chapter_groups = []; chapters = []; topics = []; if(q.hasOwnProperty('exam')) { getExams(); }}"
              label="Select Exam Group"
          ></v-select>
          <v-progress-circular v-if="examGroupGetting" indeterminate color="white"></v-progress-circular>
          <v-btn
              v-if="examGroupGettingError"
              outlined
              text
              color="white"
              rounded
              @click="getExamGroups"
          >Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1" v-if="q.hasOwnProperty('exam')">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="exams"
              v-model="q.exam"
              @input="()=>{ resetField('subject', 'chapterGroup', 'chapter', 'topic', 'paperId'); papers = subject_groups = []; subjects = []; chapter_groups = [];  chapters = []; topics = []; getPapers(); getSubjects();}"
              label="Select Exam"
          ></v-select>
          <v-progress-circular v-if="examGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="examGettingError" outlined text color="white" class="mt-1" rounded @click="getExams">Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1" v-if="q.hasOwnProperty('subject')">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="subjects"
              v-model="q.subject"
              @input="()=>{ resetField('chapterGroup', 'chapter', 'topic'); chapter_groups = []; chapters = []; topics = []; getChapterGroups(); getChapters();}"
              label="Select Subject"
          ></v-select>
          <v-progress-circular v-if="subGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="subGettingError" outlined text color="white" rounded @click="getSubjects">Retry</v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1" v-if="q.hasOwnProperty('chapterGroup')">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="chapter_groups"
              v-model="q.chapterGroup"
              @input="()=>{ resetField('chapter', 'topic'); chapters = []; topics = []; getChapters(); }"
              label="Select Chapter Group"
          ></v-select>
          <v-progress-circular v-if="chapgGetting" indeterminate color="white"></v-progress-circular>
          <v-btn
              v-if="chapgGettingError"
              outlined
              text
              color="white"
              rounded
              @click="getChapterGroups"
          >Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1" v-if="q.hasOwnProperty('chapter')">
          <v-select
              outlined
              hide-details
              dense
              :items="chapters"
              v-model="q.chapter"
              @input="() => {resetField('topic'); getChapterTopics()}"
              label="Select Chapter"
          ></v-select>
          <v-progress-circular v-if="chapGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="chapGettingError" outlined color="white" rounded @click="getChapters">Retry</v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1" v-if="q.hasOwnProperty('marks') || q.hasOwnProperty('negMarks')">
          <v-layout>
            <v-flex style="margin-right: 4px" v-if="q.hasOwnProperty('marks')">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="q.marks"
                  type="number"
                  @input="()=>{if(q.marks.length === 0) return;q.marks = parseFloat(Number(parseFloat(q.marks)).toFixed(2)); q.negMarks = parseFloat(Number(parseFloat(q.marks) / 3).toFixed(2))}"
                  label="Enter Marks"
              ></v-text-field>
            </v-flex>
            <v-flex style="margin-right: 4px" v-if="q.hasOwnProperty('negMarks')">
              <v-text-field
                  v-model="q.negMarks"
                  outlined
                  hide-details
                  dense
                  type="number"
                  @input="()=>{if(q.negMarks.length === 0) return;q.negMarks = parseFloat(Number(parseFloat(q.negMarks)).toFixed(2));}"
                  label="Enter Neg Marks"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex v-if="!hideQuestionType && q.hasOwnProperty('type')" xs12 md6 lg4>
          <v-select
              outlined
              hide-details
              dense
              class="ma-1"
              label="Question Type"
              :items="question_types"
              v-model="q.type"
              @change="$emit('type-change')"
          />
        </v-flex>
        <v-flex xs12 md6 lg4 v-if="q.hasOwnProperty('year')">
          <v-text-field outlined
                        hide-details
                        dense class="ma-1" label="Question Year" disabled type="number" v-model="q.year"/>
        </v-flex>
        <v-flex xs12 md6 lg4 v-if="q.hasOwnProperty('paperId')">
          <v-select outlined
                    hide-details
                    dense class="ma-1" :items="papers" label="Select Paper" v-model="q.paperId"
                    @input="(v) => {
                      const item = this.papers.filter(el=> el.value === v)[0];
                      if(q.hasOwnProperty('paperTitle')) q.paperTitle = item.text;
                      if(q.hasOwnProperty('year')) q.year = item.year;
                    }"/>
          <v-progress-circular v-if="paperGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="paperGettingError" outlined color="white" rounded @click="getPapers">Retry</v-btn>
        </v-flex>
        <v-flex xs12 md6 lg3 v-if="q.hasOwnProperty('difficulty')">
          <v-select
              outlined
              hide-details
              dense
              class="ma-1"
              :items="difficulty_levels"
              label="Difficulty Level"
              v-model="q.difficulty"
          />
        </v-flex>
        <v-flex xs12 md6 lg4 v-if="q.hasOwnProperty('topic')">
          <v-select outlined
                    hide-details
                    dense class="ma-1" :items="topics" label="Topic Name" v-model="q.topic"/>
          <v-progress-circular v-if="chapTopicGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="chapTopicGettingError" outlined color="white" rounded @click="getChapterTopics">Retry</v-btn>
        </v-flex>
        <v-flex xs12 md6 lg5 v-if="q.hasOwnProperty('timeAllotted') || q.hasOwnProperty('status')">
          <v-layout wrap>
            <v-flex xs12 md12 lg6 v-if="q.hasOwnProperty('timeAllotted')">
              <v-text-field
                  v-model="q.timeAllotted"
                  type="number"
                  outlined
                  hide-details
                  dense
                  class="ma-1"
                  label="Time Allotted"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md12 lg6 v-if="q.hasOwnProperty('status')">
              <v-select
                  outlined
                  hide-details
                  dense
                  class="ma-1"
                  :items="q_status"
                  label="Select Status"
                  v-model="q.status"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <slot/>
    </v-card-text>
  </v-card>
</template>

<script>
import Metadata from "../../pages/pastquestions/lib/metadata";

export default {
  name: "past-questions-metadata",
  props: {
    value: {
      type: Object,
      required: true,
    },
    hideQuestionType: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    resetField() {
      for (let i = 0; i < arguments.length; i++) {
        if (this.q.hasOwnProperty(arguments[i])) this.q[arguments[i]] = null
      }
    },
    async getExamGroups() {
      await Metadata.getExamGroups(
          this,
          {
            country: this.q.country,
          },
          (data) => {
            this.exam_groups = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.examGroupGetting = true;
            this.examGroupGettingError = false;
          },
          () => {
            this.examGroupGetting = false;
          },
          () => {
            this.examGroupGettingError = true;
          }
      );
    },
    async getExams() {
      await Metadata.getExams(
          this,
          {
            country: this.q.country,
            examGroup: this.q.examGroup,
          },
          (data) => {
            this.exams = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.examGetting = true;
            this.examGettingError = false;
          },
          () => {
            this.examGetting = false;
          },
          () => {
            this.examGettingError = true;
          }
      );
    },
    async getPapers() {
      await Metadata.getPapers(
          this,
          {
            country: this.q.country,
            examGroup: this.q.examGroup,
            exam: this.q.exam,
          },
          (data) => {
            this.papers = data.map((el) => {
              return {value: el.key, text: el.title['en'], date: el.date, year: el.year};
            });
          },
          () => {
            this.paperGetting = true;
            this.paperGettingError = false;
          },
          () => {
            this.paperGetting = false;
          },
          () => {
            this.paperGettingError = true;
          }
      );
    },
    async getSubjects() {
      const params = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
      };
      await Metadata.getSubjects(
          this,
          params,
          (data) => {
            this.subjects = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.subGetting = true;
            this.subGettingError = false;
          },
          () => {
            this.subGetting = false;
          },
          () => {
            this.subGettingError = true;
          }
      );
    },
    async getChapterGroups() {
      this.chapgGetting = true;
      this.chapgGettingError = false;
      const params = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
        subject: this.q.subject,
      };
      await Metadata.getChapterGroups(
          this,
          params,
          (data) => {
            this.chapter_groups = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.chapgGetting = true;
            this.chapgGettingError = false;
          },
          () => {
            this.chapgGetting = false;
          },
          () => {
            this.chapgGettingError = true;
          }
      );
    },
    async getChapters() {
      const posb = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
        subject: this.q.subject,
      };
      if (this.q.chapterGroup) {
        posb["chapterGroup"] = this.q.chapterGroup;
      }
      await Metadata.getChapters(
          this,
          posb,
          (data) => {
            this.chapters = data.map((el) => {
              return {value: el.key, text: el.title['en'], topics: el.topics || []};
            });
          },
          () => {
            this.chapGetting = true;
            this.chapGettingError = false;
          },
          () => {
            this.chapGetting = false;
          },
          () => {
            this.chapGettingError = true;
          }
      );
    },
    async getChapterTopics() {
      const posb = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
        subject: this.q.subject,
        chapter: this.q.chapter
      };
      if (this.q.chapterGroup) {
        posb["chapterGroup"] = this.q.chapterGroup;
      }
      await Metadata.getChapterTopics(
          this,
          posb,
          (data) => {
            this.topics = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.chapTopicGetting = true;
            this.chapTopicGettingError = false;
          },
          () => {
            this.chapTopicGetting = false;
          },
          () => {
            this.chapTopicGettingError = true;
          }
      );
    },
    populate() {
      if (this.q.country) {
        this.getExamGroups();
      }
      if (this.q.examGroup) {
        this.getExams();
      }
      if (this.q.exam) {
        this.getPapers();
        this.getSubjects();
      }
      if (this.q.subject) {
        this.getChapterGroups();
        this.getChapters();
      }
      if (this.q.chapter) {
        this.getChapterTopics()
      }
    },
  },
  computed: {
    q() {
      return this.value;
    }
  },
  data: () => {
    return {
      topics: [],
      //State of Networking
      examGroupGetting: false,
      examGroupGettingError: false,
      examGetting: false,
      examGettingError: false,
      subGetting: false,
      subGettingError: false,
      chapgGetting: false,
      chapgGettingError: false,
      chapGetting: false,
      chapGettingError: false,
      paperGetting: false,
      paperGettingError: false,
      chapTopicGetting: false,
      chapTopicGettingError: false,
      papers: [],
      q_status: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Review",
          value: "review",
        },
        {
          text: "Private",
          value: "private",
        },
      ],
      difficulty_levels: [
        {
          text: "Easy",
          value: "easy",
        },
        {
          text: "Medium",
          value: "medium",
        },
        {
          text: "Hard",
          value: "hard",
        },
      ],
      exam_groups: [],
      exams: [],
      subjects: [],
      chapter_groups: [],
      chapters: [],
      question_types: [
        {
          text: "MCQ",
          value: "mcq",
        },
        {
          text: "MCQ Multiple",
          value: "mcqm",
        },
        {
          text: "Numerical",
          value: "integer",
        },
        {
          text: "Fill in the Blanks",
          value: "fill-blanks",
        },
        {
          text: "Subjective",
          value: "subjective",
        },
        {
          text: "True or False",
          value: "t/f",
        },
      ],
      defaultVal: {
        'in-jee-jee-main': {
          time: 144000,
          marks: 4,
          negMarks: 1
        },
        'in-medical-neet': {
          time: 60000,
          marks: 4,
          negMarks: 1
        }
      }
    };
  },
  mounted() {
    this.populate()
  },
  watch: {
    papers(newValue, oldValue) {
      this.$emit("papers", newValue)
    },
    'q.exam'(newValue) {
       if(newValue) {
         const id = [this.q.country, this.q.examGroup, this.q.exam].join("-")
         if(this.q.hasOwnProperty('timeAllotted') && !this.q.timeAllotted) this.q.timeAllotted = this.defaultVal[id] ? this.defaultVal[id].time : 120000
         if(this.q.hasOwnProperty('marks') && !this.q.marks) this.q.marks = this.defaultVal[id] ? this.defaultVal[id].marks : 4
         if(this.q.hasOwnProperty('negMarks') && !this.q.negMarks && this.defaultVal[id]) this.q.negMarks = this.defaultVal[id].negMarks
       }
    }
  },
};
</script>

<style>
</style>
