<template>
  <v-card v-if="question" ref="el">
    <v-card-text>
      <v-layout row wrap>
        <v-flex xs6>
          Exam: {{ question.exam }}
        </v-flex>
        <v-flex xs6 v-if="question.subjectGroup">
          Subject Group: {{ question.subjectGroup }}
        </v-flex>
        <v-flex xs6>
          Subject: {{ question.subject }}
        </v-flex>
        <v-flex xs6>
          Chapter: {{ question.chapter }}
        </v-flex>
        <v-flex xs6 v-if="question.chapterGroup">
          Chapter Group: {{ question.chapterGroup }}
        </v-flex>
        <v-flex xs6>
          Paper: {{ question.paperTitle }}
        </v-flex>
        <v-flex xs6>
          Question Type: {{ question.type }}
        </v-flex>
      </v-layout>
    </v-card-text>
    <v-divider/>
    <v-layout class="pa-2">
      <v-btn v-for="item in question.languages" :key="item" small outlined rounded @click="selectedLang = item"
             :disabled="selectedLang === item">{{ $getLangName(item) }}
      </v-btn>
    </v-layout>
    <v-divider/>
    <v-card-text class="content">
      <h2>Content</h2>
      <div class="mb-1 mt-1" v-html="q.content"/>
      <v-layout row wrap v-if="q.options && q.options.length > 0">
        <v-flex xs6 v-for="(op, i) in q.options" :key="i">
          <h3>Option {{ op.identifier }}</h3>
          <div class="mt-1 mb-1" v-html="op.content"/>
        </v-flex>
      </v-layout>
      <div v-if="q.correct_options && q.correct_options.length > 0">
        <h2>Correct Option</h2>
        <div v-html="q.correct_options.join(', ')"/>
      </div>
      <div v-if="q.answer">
        <h2>Answer</h2>
        <div v-html="q.answer"/>
      </div>
      <div v-if="q.explanation">
        <h2>Explanation</h2>
        <div v-html="q.explanation"/>
      </div>
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <v-btn small outlined color="blue" @click="() => $mathJaxRefresh($refs.el)">Load MathJAX</v-btn>
      <v-btn small outlined color="green" :to="'/pastquestions/'+question.question_id">EDIT QUESTION</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SinglePastQuestionInfo",
  props: {
    question: {
      type: Object,
      default: null
    },
  },
  computed: {
    q() {
      return this.question.question[this.selectedLang] || this.question.question[Object.keys(this.question.question)[0]];
    }
  },
  data() {
    return {
      selectedLang: 'en'
    }
  },
  watch: {
    question(newValue, oldValue) {
      if (newValue) this.$nextTick(() => this.$mathJaxRefresh(this.$refs.el.$el))
    },
    selectedLang(newV, Old) {
      if (newV) this.$nextTick(() => this.$mathJaxRefresh(this.$refs.el.$el))
    }
  },
  beforeDestroy() {
    this.$mathJaxClear(this.$refs.el.$el)
  }
}
</script>

<style scoped>
.content img {
  max-height: 300px !important;
}
</style>
