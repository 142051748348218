<template>
  <v-container fill-height>
    <Login/>
  </v-container>
</template>

<script>
import Login from "../../components/Login";
export default {
  name: "index",
  components: {
    Login
  }
};
</script>
<style scoped>
</style>