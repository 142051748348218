<template>
  <div class="pa-2">
    <v-layout wrap>
      <v-flex v-for="item in results" :key="item.mapId" xs12 lg3>
        <v-card>
          <v-card-text>
            {{item.mapId}}
          </v-card-text>
          <v-card-actions>
            <v-btn :to="'/test-id-map/'+item.mapId">UPDATE</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div v-if="getting" class="text-center pa-3">
     <v-progress-circular indeterminate color="primary" />
    </div>
  </div>
</template>

<script>
export default {
  name: "list",
  data() {
    return {
      results: [],
      getting: false,
    }
  },
  methods: {
    async getTestsMaps() {
      this.getting = true
      try {
        const {data} = await this.$testHttp.get('/test-id-maps/list')
        this.results = data['results']
      }catch (e) {
        this.$message.error(e.toString())
      }
      this.getting = false
    },
  },
  mounted() {
    this.getTestsMaps()
  }
}
</script>

<style scoped>

</style>
