<template>
  <v-card :color="$vuetify.theme.dark ? '': 'primary'" dark>
    <v-card-text>
      <v-text-field v-if="find" v-model="q.q" @input="()=> {$emit('onChange');}" outlined dense label="Search"
                    class="ma-1" hide-details/>
      <v-layout wrap>
        <v-flex xs12 md6 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="$availCountries"
              v-model="q.country"
              @input="()=>{ $emit('onChange'); q.examGroup = q.exam = q.subjectGroup = null; exam_groups = subject_groups = []; q.subject = null; subjects = []; q.chapterGroup = null; chapter_groups = []; q.chapter = null; chapters = []; if(!find) { q.topic = null; topics = []; } getExamGroups();}"
              label="Select Country"
          ></v-select>
        </v-flex>
        <v-flex xs12 md6 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="exam_groups"
              v-model="q.examGroup"
              @input="()=>{ $emit('onChange'); q.exam = q.subject = null; subjects = []; q.chapterGroup = null; chapter_groups = []; q.chapter = null; chapters = []; if(!find) { q.topic = null; topics = []; } getExams();}"
              label="Select Exam Group"
          ></v-select>
          <v-progress-circular v-if="examGroupGetting" indeterminate color="white"></v-progress-circular>
          <v-btn
              v-if="examGroupGettingError"
              outlined
              text
              color="white"
              rounded
              @click="getExamGroups"
          >Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1">
          <v-select
              outlined
              dense
              hide-details
              height="20"
              :items="exams"
              v-model="q.exam"
              @input="()=>{$emit('onChange'); q.subjectGroup = null; subject_groups = []; q.subject = null; subjects = []; q.chapterGroup = null; chapter_groups = []; q.chapter = null; chapters = []; if(!find) { q.topic = null; topics = []; } getSubjectGroups(); getSubjects();}"
              label="Select Exam"
          ></v-select>
          <v-progress-circular v-if="examGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="examGettingError" outlined text color="white" class="mt-1" rounded @click="getExams">Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="subject_groups"
              v-model="q.subjectGroup"
              @input="()=>{$emit('onChange'); q.subject = null; subjects = []; q.chapterGroup = null; chapter_groups = []; q.chapter = null; chapters = []; if(!find) { q.topic = null; topics = []; } getSubjects();}"
              label="Select Subject Group"
          ></v-select>
          <v-progress-circular v-if="subgGetting" indeterminate color="white"></v-progress-circular>
          <v-btn
              v-if="subgGettingError"
              outlined
              color="white"
              rounded
              @click="getSubjectGroups"
          >Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="subjects"
              v-model="q.subject"
              @input="()=>{ $emit('onChange'); q.chapterGroup = null; chapter_groups = []; q.chapter = null; chapters = []; if(!find) { q.topic = null; topics = []; } getChapterGroups(); getChapters();}"
              label="Select Subject"
          ></v-select>
          <v-progress-circular v-if="subGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="subGettingError" outlined text color="white" rounded @click="getSubjects">Retry</v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              height="20"
              :items="chapter_groups"
              v-model="q.chapterGroup"
              @input="()=>{ $emit('onChange'); q.chapter = null; chapters = []; if(!find) { q.topic = null; topics = []; } getChapters(); }"
              label="Select Chapter Group"
          ></v-select>
          <v-progress-circular v-if="chapgGetting" indeterminate color="white"></v-progress-circular>
          <v-btn
              v-if="chapgGettingError"
              outlined
              text
              color="white"
              rounded
              @click="getChapterGroups"
          >Retry
          </v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              :items="chapters"
              v-model="q.chapter"
              label="Select Chapter"
              @input="() => { $emit('onChange'); if(!find) { q.topic = null; topics = []; getChapterTopics() } }"
          ></v-select>
          <v-progress-circular v-if="chapGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="chapGettingError" outlined color="white" rounded @click="getChapters">Retry</v-btn>
        </v-flex>
        <v-flex v-if="!find" xs12 md6 lg4 class="pa-1">
          <v-select
              outlined
              hide-details
              dense
              :items="topics"
              v-model="q.topic"
              label="Select Topic"
              @input="() => $emit('onChange') "
          ></v-select>
          <v-progress-circular v-if="chapTopicGetting" indeterminate color="white"></v-progress-circular>
          <v-btn v-if="chapTopicGettingError" outlined color="white" rounded @click="getChapterTopics">Retry</v-btn>
        </v-flex>
        <v-flex xs12 md6 lg4>
          <v-layout wrap>
            <v-flex xs12 m12 lg6>
              <v-select
                  outlined
                  hide-details
                  dense
                  class="ma-1"
                  :items="status"
                  label="Select Status"
                  v-model="q.status"
                  @input="() => $emit('onChange')"
              />
            </v-flex>
            <v-flex xs12 m12 lg6 v-if="!find">
              <v-text-field
                  outlined
                  hide-details
                  dense
                  class="ma-1"
                  type="number"
                  label="Order"
                  v-model="q.order"
              />
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 md6 lg4>
          <v-switch label="Premium" v-model="q.isPremium" hide-details style="margin-top: 0.5em"/>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import Metadata from "@/pages/pastquestions/lib/metadata";

export default {
  name: "notes-metadata",
  props: {
    value: {
      type: Object,
      required: true,
    },
    find: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    async getExamGroups() {
      await Metadata.getExamGroups(
          this,
          {
            country: this.q.country,
          },
          (data) => {
            this.exam_groups = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.examGroupGetting = true;
            this.examGroupGettingError = false;
          },
          () => {
            this.examGroupGetting = false;
          },
          () => {
            this.examGroupGettingError = true;
          }
      );
    },
    async getExams() {
      await Metadata.getExams(
          this,
          {
            country: this.q.country,
            examGroup: this.q.examGroup,
          },
          (data) => {
            this.exams = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.examGetting = true;
            this.examGettingError = false;
          },
          () => {
            this.examGetting = false;
          },
          () => {
            this.examGettingError = true;
          }
      );
    },
    async getSubjectGroups() {
      const params = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
      };
      await Metadata.getSubjectGroups(
          this,
          params,
          (data) => {
            this.subject_groups = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.subgGetting = true;
            this.subgGettingError = false;
          },
          () => {
            this.subgGetting = false;
          },
          () => {
            this.subgGettingError = true;
          }
      );
    },
    async getSubjects() {
      const params = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
      };
      if (this.q.subjectGroup) {
        params["subjectGroup"] = this.q.subjectGroup;
      }
      await Metadata.getSubjects(
          this,
          params,
          (data) => {
            this.subjects = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.subGetting = true;
            this.subGettingError = false;
          },
          () => {
            this.subGetting = false;
          },
          () => {
            this.subGettingError = true;
          }
      );
    },
    async getChapterGroups() {
      this.chapgGetting = true;
      this.chapgGettingError = false;
      const params = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
        subject: this.q.subject,
      };
      if (this.q.subjectGroup) {
        params["subjectGroup"] = this.q.subjectGroup;
      }
      await Metadata.getChapterGroups(
          this,
          params,
          (data) => {
            this.chapter_groups = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.chapgGetting = true;
            this.chapgGettingError = false;
          },
          () => {
            this.chapgGetting = false;
          },
          () => {
            this.chapgGettingError = true;
          }
      );
    },
    async getChapters() {
      const posb = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
        subject: this.q.subject,
      };
      if (this.q.subjectGroup) {
        posb["subjectGroup"] = this.q.subjectGroup;
      }
      if (this.q.chapterGroup) {
        posb["chapterGroup"] = this.q.chapterGroup;
      }
      await Metadata.getChapters(
          this,
          posb,
          (data) => {
            this.chapters = data.map((el) => {
              return {value: el.key, text: el.title['en'], topics: el.topics || []};
            });
          },
          () => {
            this.chapGetting = true;
            this.chapGettingError = false;
          },
          () => {
            this.chapGetting = false;
          },
          () => {
            this.chapGettingError = true;
          }
      );
    },
    async getChapterTopics() {
      const posb = {
        country: this.q.country,
        examGroup: this.q.examGroup,
        exam: this.q.exam,
        subject: this.q.subject,
        chapter: this.q.chapter
      };
      if (this.q.subjectGroup) {
        posb["subjectGroup"] = this.q.subjectGroup;
      }
      if (this.q.chapterGroup) {
        posb["chapterGroup"] = this.q.chapterGroup;
      }
      await Metadata.getChapterTopics(
          this,
          posb,
          (data) => {
            this.topics = data.map((el) => {
              return {value: el.key, text: el.title['en']};
            });
          },
          () => {
            this.chapTopicGetting = true;
            this.chapTopicGettingError = false;
          },
          () => {
            this.chapTopicGetting = false;
          },
          () => {
            this.chapTopicGettingError = true;
          }
      );
    },
    populate() {
      if (this.q.country) {
        this.getExamGroups();
      }
      if (this.q.examGroup) {
        this.getExams();
      }
      if (this.q.exam) {
        this.getSubjects();
        this.getSubjectGroups();
      }
      if (this.q.subject) {
        this.getChapterGroups();
        this.getChapters();
      }
      if (this.q.chapter) {
        this.getChapterTopics()
      }
    }
  },
  data() {
    return {
      examGroupGetting: false,
      examGroupGettingError: false,
      examGetting: false,
      examGettingError: false,
      subgGetting: false,
      subgGettingError: false,
      subGetting: false,
      subGettingError: false,
      chapgGetting: false,
      chapgGettingError: false,
      chapGetting: false,
      chapTopicGetting: false,
      chapGettingError: false,
      chapTopicGettingError: false,
      status: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Review",
          value: "review",
        },
        {
          text: "Private",
          value: "Private",
        },
      ],
      exam_groups: [],
      exams: [],
      subject_groups: [],
      subjects: [],
      chapter_groups: [],
      chapters: [],
      topics: [],
    }
  },
  computed: {
    q() {
      return this.value;
    },
  },
}
</script>

<style scoped>

</style>
