<template>
  <v-dialog v-model="ipsDialog" max-width="600">
    <v-card>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>IP Info</v-list-item-title>
          <v-list-item-subtitle>{{ip}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar>
          <img v-if="this.info"
               :src="`https://flagcdn.com/216x162/${this.info.country_code.toString().toLowerCase()}.webp`" alt="Country"/>
        </v-list-item-avatar>
      </v-list-item>
      <v-divider/>
      <v-card-text>
        <v-layout class="pt-3" align-center justify-center v-if="!info">
          <v-btn @click="getInfo" v-if="!loading && !info">LOAD INFO</v-btn>
          <v-progress-circular indeterminate v-if="loading && !info"/>
        </v-layout>
        <v-layout class="pt-4" column v-if="info">
          <v-layout align-center justify-space-between>
            <div>City</div>
            <div>{{ info.city }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>As</div>
            <div>{{ info.as || info.organization }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>Country</div>
            <div>{{ info.country }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>Country Code</div>
            <div>{{ info.country_code }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>Organigation</div>
            <div>{{ info.org || info.organization_name }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>Region</div>
            <div>{{ info.region }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>Timezone</div>
            <div>{{ info.timezone }}</div>
          </v-layout>
          <v-layout align-center justify-space-between>
            <div>Lat, Lon</div>
            <div>{{ info.lat || info.latitude }}, {{ info.lon || info.longitude }}</div>
          </v-layout>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn outlined small color="red" @click="ipsDialog = false">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "IPInfoDialog",
  computed: {
    ipsDialog: {
      set(v) {
        if (!v) {
          this.$store.commit('dialog/setIpToInfo', null);
          this.info = null;
        }
      },
      get() {
        return this.$store.state.dialog.ipToGetInfo !== null
      }
    },
    ip() {
      return this.$store.state.dialog.ipToGetInfo;
    }
  },
  data() {
    return {
      loading: false,
      info: null
    }
  },
  methods: {
    getInfo() {
      this.loading = true;
      this.info = null;
      this.$http.get("/security/ipinfo/".concat(this.ip))
          .then(res => {
            this.loading = false;
            if (res.data.statusCode === 0) {
              this.info = res.data['data'];
            } else {
              this.$message.error('Error Occurred!');
            }
          }).catch(err => {
        this.loading = false;
        this.$message.error(err.toLocaleString());
      });
    }
  },
  watch: {
    ipsDialog(newValue, oldValue) {
      if (newValue !== oldValue && newValue) {
        this.getInfo()
      }
    }
  },
}
</script>

<style scoped>

</style>
