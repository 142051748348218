<template>
  <div>
    <v-layout wrap>
      <v-flex class="pa-1" xs12 md6 lg3 v-for="item in results" :key="item.jobId">
        <v-card>
           <v-card-text>{{item.title}}</v-card-text>
           <v-card-actions>
             <v-spacer/>
             <v-btn small outlined text :to="`/vision/${item.jobId}`">VIEW</v-btn>
           </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="text-center pa-3">
      <h2 v-if="loadingError">{{loadingError}}</h2>
      <v-btn v-if="loadingError" @click="getResults">RETRY</v-btn>
      <v-progress-circular indeterminate v-if="loading"/>
    </div>
  </div>
</template>

<script>
export default {
name: "list",
  methods: {
    async getResults() {
      this.loading = true
      this.loadingError = null
      try {
        const {data} = await this.$http.get("/vision/pdf")
        if(data.statusCode === 0){
          this.results = data['results']
        }else {
          this.loadingError = data.error ? data.error.message : "Unknown Error"
        }
      }catch (e){
        this.loadingError = e.toString()
        this.$message.error(e.toString())
      }
      this.loading = false;
    }
  },
  data() {
    return {
      loadingError: null,
      loading: false,
      results: []
    }
  },
  mounted() {
    this.getResults();
  }
}
</script>

<style scoped>

</style>