<template>
  <div class="pa-1">
    <v-card v-if="job">
      <v-card-title>{{job.title}}</v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex xs12 lg6>
            Destination: {{job.destination}}
          </v-flex>
          <v-flex xs12 lg6>
            Source: {{job.source}}<br/>
            Created At: {{new Date(job.createdAt).toLocaleString()}}<br/>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn @click="parse" :loading="parsing" :disabled="parsing" depressed color="red" dark>PARSE</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-1" v-if="job && job.parsed">
      <v-tabs
          v-model="tab"
          background-color="primary"
          dark
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
      >
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <v-tab
            v-for="i  in tabs"
            :key="i"
        >
          Page {{ i }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
            v-for="item in tabs"
            :key="item"
        >
          <v-card-text><pre v-html="job.parsed[item]"/></v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <div class="text-center pa-3">
      <h2 v-if="loadingError">{{loadingError}}</h2>
      <v-btn v-if="loadingError" @click="getJob">RETRY</v-btn>
      <v-progress-circular indeterminate v-if="loading"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "vision-view",
  computed: {
    tabs() {
      return this.job ? this.job.parsed ? Object.keys(this.job.parsed).sort((a, b) => a - b) : [] : [];
    }
  },
  methods: {
    async getJob() {
      this.loading = true
      this.loadingError = null
      try {
        const {data} = await this.$http.get("/vision/pdf/"+this.$route.params.id)
        if(data.statusCode === 0){
          this.job = data['data']
        }else {
          this.loadingError = data.error ? data.error.message : "Unknown Error"
        }
      }catch (e){
        this.loadingError = e.toString()
        this.$message.error(e.toString())
      }
      this.loading = false;
    },
    async parse() {
      this.parsing = true
      try {
        const {data} = await this.$http.get("/vision/pdf/"+this.$route.params.id+"/parse")
        if(data.statusCode === 0){
          this.$message.success("Success")
          this.getJob();
        }else {
          this.$message.error(data.error ? data.error.message : "Unknown Error")
        }
      }catch (e){
        this.$message.error(e.toString())
      }
      this.parsing = false;
    }
  },
  data() {
    return {
      loadingError: null,
      loading: false,
      parsing: false,
      job: null,
      tab: null
    }
  },
  mounted() {
    this.getJob();
  }
}
</script>

<style scoped>

</style>