<template>
  <div class="pa-2">
    <v-card>
      <v-card-text>
        <v-layout justify-space-between align-center style="gap: 16px">
          <v-select v-model="note.status" :items="['public', 'private', 'review']" outlined hide-details dense rounded/>
          <v-switch v-model="note.isPremium" hide-details dense label="Premium" style="height: 24px;margin-top: 0"/>
        </v-layout>
      </v-card-text>
    </v-card>
    <h2 class="pa-2">Metadata</h2>
    <template v-for="(item, i) in note.metadata">
      <PastQuestionsMetadata v-model="note.metadata[i]" :key="i" ref="meta" :class="{'mt-2': i > 0}">
        <div class="text-right mt-2">
          <v-btn outlined @click="note.metadata.splice(i, 1)" color="red">Remove</v-btn>
        </div>
      </PastQuestionsMetadata>
    </template>
    <div class="text-right pa-2">
      <v-btn outlined color="green" @click="addMetadata">ADD METADATA</v-btn>
    </div>
    <v-card>
      <v-layout class="pa-1">
        <v-flex style="flex-grow: 1;">
          <v-btn
              color="primary"
              rounded
              outlined
              class="mr-1"
              @click="currentLang = lang"
              :key="lang"
              v-for="lang in Object.keys(note.theory)"
              :disabled="currentLang === lang"
          >
            {{ $getLangName(lang) }}
            <v-btn
                small
                color="red"
                text
                style="height: 20px"
                @click.stop="removeLang(lang)"
                v-if="Object.keys(note.theory).length > 1 && lang !== 'en'"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-btn>
        </v-flex>
        <div>
          <v-dialog v-model="langDialog" width="400">
            <template v-slot:activator="{on}">
              <v-btn v-on="on" fab outlined small color="red">
                <v-icon>add</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                  :key="item.value"
                  @click="addLang(item.value)"
                  v-for="item in $availLanguages.filter((el)=> Object.keys(note.theory).indexOf(el.value) === -1)"
              >
                <v-list-item-title>{{ $getLangName(item.value) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-dialog>
        </div>
      </v-layout>
      <v-divider/>
      <v-card-text ref="el">
        <v-text-field v-model="note.theory[currentLang].title" outlined dense label="Title (Required)"/>
        <v-layout wrap>
          <v-flex xs12 lg7>
            <v-card color="grey darken-4" dark>
              <v-layout wrap class="pa-2 green darken-2">
                <v-select class="mb-1" label="Headline" outlined dense hide-details :items="['h1', 'h2', 'h3', 'h4']"
                          @input="el => change(el, true)"/>
                <v-btn small outlined @click.stop="change('section', true)" class="ml-1">&lt;section&gt;</v-btn>
                <v-btn small outlined @click.stop="change('p', true)" class="ml-1">&lt;p&gt;</v-btn>
                <v-btn small outlined @click.stop="change('div', true)" class="ml-1">&lt;div&gt;</v-btn>
                <v-btn small outlined @click.stop="change('ul', true)" class="ml-1">&lt;ul&gt;</v-btn>
                <v-btn small outlined @click.stop="change('ol', true)" class="ml-1">&lt;ol&gt;</v-btn>
                <v-btn small outlined @click.stop="change('li', true)" class="ml-1">&lt;li&gt;</v-btn>
                <v-btn small outlined @click.stop="change('sub', true)" class="ml-1">&lt;sub&gt;</v-btn>
                <v-btn small outlined @click.stop="change('sup', true)" class="ml-1">&lt;sup&gt;</v-btn>
                <v-btn small outlined @click.stop="change('br', false)" class="ml-1">&lt;/br&gt;</v-btn>
                <v-btn small outlined @click.stop="change('b', true)" class="ml-1">&lt;b&gt;</v-btn>
                <v-btn small outlined @click.stop="change('aside', true)" class="ml-1">&lt;aside&gt;</v-btn>
                <MathTypeButton :text-area="() => $refs['prism'].$refs.textarea"/>
              </v-layout>
              <v-card-text>
                <prism-editor
                    style="min-height: 300px;"
                    ref="prism"
                    v-model="note.theory[currentLang].content"
                    :highlight="highlighter"
                    @input="mathJaxRefresh"
                    line-numbers
                ></prism-editor>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex xs12 lg5 class="pl-lg-2">
            <div style="border: 1px solid #d1d1d1">
              <h2 class="pa-4" style="border-bottom: 1px solid #f1f1f1">Preview</h2>
              <div ref="content" class="theory-container html-content" v-html="note.theory[currentLang].content"/>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <div class="text-sm-center pa-2">
      <v-btn @click="create" :loading="loading" min-width="200" :disabled="loading" large rounded color="primary">
        SUBMIT
      </v-btn>
    </div>
    <v-dialog max-width="400" v-model="fetching" persistent>
      <v-card dark color="green">
        <v-card-text class="pt-4">
          <p>Please stand by</p>
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog max-width="400" v-model="fetchingError" persistent>
      <v-card dark color="red">
        <v-card-text class="pt-4"><p>Fetch Error! Try Again</p></v-card-text>
        <v-card-text class="text-sm-center">
          <v-btn outlined @click="fetch">RETRY</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NotesMetadata from "@/components/notes/Metadata";
import {PrismEditor} from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import RichTextEditor from "@/components/RichTextEditor";

import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism-tomorrow.css";
import MathTypeButton from "@/components/MathTypeButton";
import Platform from "@/components/PlatformComponent";
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata"; // import syntax highlighting styles
const prettify = require('html-prettify');
export default {
  name: "create",
  components: {PastQuestionsMetadata, Platform, MathTypeButton, RichTextEditor, NotesMetadata, PrismEditor},
  methods: {
    addMetadata() {
      this.note.metadata.push(this.getMetadataModel())
    },
    highlighter(code) {
      return highlight(code, languages.markup, "html"); //returns html
    },
    change(tag, iend, only) {
      const textarea = this.$refs['prism'].$refs.textarea;
      textarea.focus();
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const sel = textarea.value.substring(start, end);
      let replace = "";
      if (!only) {
        if (!iend) {
          replace = sel.concat("<".concat(tag).concat("/>"));
        } else {
          replace = "<"
              .concat(tag)
              .concat(">")
              .concat(sel)
              .concat("</")
              .concat(tag)
              .concat(">");
        }
      } else {
        replace = sel.concat(tag);
      }
      document.execCommand("insertText", false, replace);
      this.mathJaxRefresh();
    },
    mathJaxRefresh() {
      this.$mathJaxRefresh(this.$refs.content);
    },
    async create() {
      this.loading = true
      try {
        const response = await this.$notesHttp.post("/createOrUpdate", this.note)
        if (response.data.statusCode === 0) {
          if (!this.$route.params.id) {
            this.currentLang = "en"
            this.$set(this.note, "theory", {
              en: {
                title: null,
                content: "<section>\n\n</section>"
              }
            })
          }
          this.$message.success(this.$route.params.id ? "Update Successful" : "Successful")
        } else {
          this.$message.error(response.data.error ? response.data.error.message : "Unknown Error Occurred!")
        }
      } catch (e) {
        this.$message.error(e.response && e.response.data && e.response.data['error'] ? e.response.data.error.message : e.toString())
      }
      this.loading = false
    },
    removeLang(n) {
      if (!this.note.theory[n]) return false;
      this.$delete(this.note.theory, n);
      if (this.currentLang === n) this.currentLang = Object.keys(this.note.theory)[0];
    },
    addLang(v) {
      this.langDialog = false;
      if (!this.note.theory[v]) this.$set(this.note.theory, v, {title: null, content: null});
    },
    getMetadataModel() {
      return {
        country: null,
        examGroup: null,
        exam: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        topic: null,
        order: 0
      }
    },
    getModel() {
      return {
        theoryId: null,
        metadata: [this.getMetadataModel()],
        status: "review",
        theory: {
          en: {
            title: null,
            content: "<section>\n\n</section>"
          }
        },
        isPremium: false
      }
    },
    async fetch() {
      this.fetching = true;
      this.fetchingError = false;
      try {
        const {data} = await this.$notesHttp.get("/" + this.$route.params.id)
        if (data.statusCode === 0 && data.data) {
          this.$message.success("Successful")
          this.$set(this, "note", data.data)
          for (const meta of this.$refs.meta) setTimeout(meta.populate, 1000);
          this.$mathJaxRefresh(this.$refs.content);
        } else {
          this.$message.error(data.error ? data.error.message : data.data ? "Unknown Error! Try Again" : "Note Not Found")
          this.fetchingError = true;
        }
      } catch (e) {
        this.$message.error(e.toString())
        this.fetchingError = true;
      }
      this.fetching = false;
    }
  },
  mounted() {
    if (this.$route.params.id !== "create") this.fetch()
    this.$refs['prism'].$refs.textarea.onkeydown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 83) {
        for (const lang in this.note.theory) {
          this.note.theory[lang].content = prettify(this.note.theory[lang].content)
        }
        this.mathJaxRefresh()
        return false;
      }
    };
  },
  data() {
    return {
      loading: false,
      langDialog: false,
      currentLang: 'en',
      fetching: false,
      fetchingError: false,
      note: this.getModel()
    }
  },
  beforeDestroy() {
    this.$mathJaxClear(this.$refs.content)
  }
}
</script>

<style>
@import url("https://static.cdn.examgoal.net/css/themes/v1/theory.min.css");
</style>

<style>
.prism-editor__textarea:focus {
  outline: none;
}

.html-content {
  overflow-x: auto;
  padding-top: 8px;
}
</style>
