<template>
  <div class="pa-2">
    <v-card>
      <v-card-text>
        <v-text-field v-model="id" outlined dense full-width label="End Point Id" hide-details/>
      </v-card-text>
    </v-card>
    <PastQuestionsMetadata class="mt-2" v-model="q">
      <div class="text-right">
        <v-btn class="mr-2" @click="getQuestions" :disabled="!q.paperId || loading || applying"
               :loading="applying || loading">Load Questions
        </v-btn>
        <v-btn color="green" dark @click="applyAll" :disabled="questions.length <= 0 || applying" :loading="applying">
          Apply All
        </v-btn>
      </div>
    </PastQuestionsMetadata>
    <PastQuestionsMetadata class="mt-2" v-model="predict_upon">
      <template v-slot:header>
        <div class="mb-2">Predict Group</div>
      </template>
    </PastQuestionsMetadata>
    <div v-if="loading" class="pa-4 py-8 text-center">
      <v-progress-circular indeterminate color="green"/>
    </div>
    <div class="mt-2" style="display: flex; flex-direction: column;gap: 4px">
      <v-card rounded v-for="(question, i) in questions" :key="question.question_id">
        <div class="px-4 pt-4">
          <div>Chapter Group: <b>{{ question.chapterGroup }}</b>, Chapter: <b>{{ question.chapter }}</b></div>
        </div>
        <QuestionComponent class="mt-2" v-model="questions[i]"/>
        <v-card-text class="pb-0">
          <h4>Prediction Result</h4>
          <v-layout class="mt-2" wrap style="gap: 4px">
            <div v-for="Class in question.prediction.Classes" :key="Class.Name" class="rounded px-4 py-2"
                 style="border: 1px solid lightgray">
              <div>{{ Class.Name.split("&")[0].toUpperCase() }}: {{ Class.Name.split("&")[1].toUpperCase() }}</div>
              <div class="mt-1 caption red--text">{{ (Class.Score * 100).toFixed(2) }}%</div>
            </div>
          </v-layout>
        </v-card-text>
        <v-card-text>
          <h4>Chapters Found</h4>
          <div class="mt-2" style="display: flex; flex-wrap: wrap; gap: 4px;row-gap: 8px">
            <div class="px-4 py-2 rounded"
                 :class="{'green white--text': chapter.metaId === question.prediction.chapter.metaId}"
                 v-for="chapter in question.prediction.chapters" :key="chapter.metaId"
                 @click="question.prediction.chapter = chapter" style="cursor: pointer;border: 1px solid lightgray">
              <div>{{ chapter.title['en'] }}</div>
              <div class="mt-1 caption">{{ chapter.subject }}</div>
            </div>
          </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="red" text outlined min-width="200" target="_blank" @click="questions.splice(i, 1)">REMOVE
          </v-btn>
          <v-btn color="yellow" min-width="200" target="_blank" :to="`/pastquestions/${question.question_id}`">VIEW
            QUESTION
          </v-btn>
          <v-btn color="green" dark min-width="200" @click="applyPrediction(question)" :disabled="applying"
                 :loading="applying">APPLY PREDICTION
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";
import QuestionComponent from "@/components/QuestionComponent";

export default {
  name: "PYQ-Chapter-Classify",
  components: {QuestionComponent, PastQuestionsMetadata},
  data() {
    return {
      id: null,
      q: {
        country: 'in',
        examGroup: null,
        exam: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        paperId: null,
        status: null
      },
      predict_upon: {
        country: 'in',
        examGroup: null,
        exam: null,
        subject: null
      },
      loading: false,
      applying: false,
      questions: []
    }
  },
  methods: {
    async getQuestions() {
      this.loading = true
      this.questions = []
      try {
        const {data} = await this.$pqhttp
            .post("/chapter-classifier/classify-gcp", {
              id: this.id || undefined,
              base: {...this.q},
              predict_upon: this.predict_upon
            })
        this.questions = data.results || []
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.loading = false
    },
    async applyAll() {
      this.applying = true
      try {
        const {data} = await this.$pqhttp
            .post("/chapter-classifier/apply", {
              questions: this.questions.map(q => {
                return {
                  question_id: q.question_id,
                  metaId: q.prediction.chapter.metaId
                }
              })
            })
        const appliedQuestions = data.data || []
        this.questions.filter(q => appliedQuestions.indexOf(q.question_id) >= 0).forEach(el => {
          this.questions.splice(this.questions.indexOf(el), 1)
        })
        this.$message.success('Successful')
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.applying = false
    },
    async applyPrediction(q) {
      this.applying = true
      try {
        const {data} = await this.$pqhttp
            .post("/chapter-classifier/apply", {
              questions: [
                {
                  question_id: q.question_id,
                  metaId: q.prediction.chapter.metaId
                }
              ]
            })
        const appliedQuestions = data.data || []
        if (appliedQuestions.indexOf(q.question_id) >= 0) this.questions.splice(this.questions.indexOf(q), 1)
        this.$message.success('Successful')
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.applying = false
    }
  }
}
</script>

<style scoped>

</style>
