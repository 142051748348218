<template>
  <div class="pa-2">
    <div class="text-right">
      <v-btn @click="importQuestions" large color="red" :dark="!importing" class="mr-1" :loading="importing" :disabled="importing">IMPORT
        QUESTIONS
      </v-btn>
    </div>
    <v-card class="ma-1">
      <v-card-text>
        <v-text-field v-model="query" @input="search" outlined dense hide-details label="Enter Search Query" />
      </v-card-text>
    </v-card>
    <v-layout wrap>
      <v-flex xs12 md12 lg6 v-for="question in searchedQuestions" :key="question.questionId">
        <v-card color="green" dark class="ma-1">
          <div>
            <div class="pa-4" style="height: 100px;overflow: hidden;font-size: 12px">{{ question.question.en.content }}</div>
            <v-divider/>
            <div class="pa-4"><b>Subject:</b> {{ question.subject }}, <b>Chapter:</b> {{ question.chapter }}</div>
            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-btn :to="`/freelance/${$route.params.id}/test-question/${question.questionId}`" text small outlined>
                UPDATE
              </v-btn>
            </v-card-actions>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pa-4 text-center">
      <h3 v-if="loadingError">{{ loadingError }}</h3>
      <v-btn @click="getQuestions" v-if="loadingError">Retry</v-btn>
    </div>
  </div>
</template>

<script>
import PastQuestionMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "pastQuestions",
  components: {
    PastQuestionMetadata
  },
  methods: {
    search() {
      if(this.query){
         this.searchedQuestions = this.questions.filter(el=> el.question['en'].content.indexOf(this.query) >= 0)
      }else {
        this.searchedQuestions = this.questions;
      }
    },
    async getQuestions() {
      this.loading = true;
      try {
        const {data} = await this.$http.get("/freelance/" + (this.$route.params.id) + "/test-questions");
        if (data.statusCode === 0) {
          this.questions = data.results;
          this.search();
        } else {
          this.loadingError = data.error ? data.error.message : "Unknown Error";
        }
      } catch (e) {
        this.loadingError = e.toString();
      }
      this.loading = false;
    },
    async importQuestions() {
      this.importing = true;
      try {
        const {data} = await this.$http.post("/freelance/" + (this.$route.params.id) + "/import-test-questions");
        if (data.statusCode === 0) {
          this.$message.success("Imported Successfully");
          this.questions = [];
        } else {
          this.$message.error(data.error ? data.error.message : "Unknown Error");
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.importing = false;
    }
  },
  data() {
    return {
      importing: false,
      loading: false,
      loadingError: null,
      questions: [],
      searchedQuestions: [],
      query: null
    }
  },
  mounted() {
    this.getQuestions();
  }
}
</script>

<style scoped>

</style>
