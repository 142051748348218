<template>
  <v-dialog max-width="1920" v-model="dialog">
    <v-card>
      <v-tabs dark background-color="green darken-2" right>
        <v-tab>Questions</v-tab>
        <v-tab>Past Year Questions</v-tab>
        <v-tab-item>
          <All select @onSelect="(v) => { $emit('onSelect', {data: v, marks: 0, negMarks: 0, timeAllotted: 0, questionId: v['questionId']}) }"/>
        </v-tab-item>
        <v-tab-item>
          <pq-all :on-select="(v) => $emit('onSelect', {data: v, marks: 0, negMarks: 0, timeAllotted: 0, questionId: v['question_id'], from: 'pq'})"/>
        </v-tab-item>
      </v-tabs>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn text color="red" @click="dialog = false">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PqAll from "@/pages/pastquestions/all";
import All from "@/pages/tests/questions/list";
export default {
  name: "QuestionSelectDialog",
  components: {All, PqAll},
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    async findQuestions() {

    },
    async findPastQuestions() {

    }
  },
  computed: {
    dialog: {
      set(v) {
        this.$emit("input", v)
      },
      get() {
        return this.value
      }
    }
  },
}
</script>

<style scoped>

</style>
