<template>
  <v-card>
    <v-layout align-center justify-space-between class="pl-2 pr-2">
      <v-switch v-model="bar" label="Bar Chart"/>
      <v-layout align-center justify-end>
        <v-btn text outlined class="mr-1" :disabled="mode === 'last1Hour'" @click="()=> changeMode('last1Hour')">Last 60
          Minutes
        </v-btn>
        <v-btn text outlined class="mr-1" :disabled="mode === 'last24Hours'" @click="()=> changeMode('last24Hours')">
          Last 24 Hours
        </v-btn>
        <v-btn text outlined class="mr-1" :disabled="mode === 'last7Days'" @click="()=> changeMode('last7Days')">Last 7 Days
        </v-btn>
      </v-layout>
    </v-layout>
    <v-card-text v-if="dataCollection">
      <BarChartJs v-if="bar" :chart-data="dataCollection" :options="options" style="position: relative;height: 400px"/>
      <LineChartJs v-else :chart-data="dataCollection" :options="options" style="position: relative;height: 400px"/>
    </v-card-text>
  </v-card>
</template>

<script>
import LineChartJs from "../chart/LineChartJs";
import BarChartJs from "../chart/BarChartJs";

export default {
  name: "RequestStats",
  components: {BarChartJs, LineChartJs},
  data() {
    return {
      bar: false,
      mode: 'last1Hour',
      modes: {
        last1Hour: {
          ago2: '120m',
          ago: '60m',
          bin: '1m'
        },
        last24Hours: {
          ago2: '48h',
          ago: '24h',
          bin: '1h'
        },
        last7Days: {
          ago2: '14d',
          ago: '7d',
          bin: '1d'
        }
      },
      loading: false,
      intervalId: null,
      dataCollection: null,
      options: {
        scales: {
          xAxes: [{
            display: false,
          }]
        },
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  computed: {
    query() {
      const bin = this.modes[this.mode].bin
      const ago = this.modes[this.mode].ago
      const ago2 = this.modes[this.mode].ago2
      return [`SELECT BIN(time, ${bin}) as time, SUM(measure_value::bigint) as "count" FROM "examgoal"."IPRequestLogs" WHERE time between ago(${ago}) and now() GROUP BY BIN(time, ${bin}) ORDER BY time DESC`, `SELECT BIN(time, ${bin}) as time, SUM(measure_value::bigint) as "count" FROM "examgoal"."IPRequestLogs" WHERE time between ago(${ago2}) and ago(${ago}) GROUP BY BIN(time, ${bin}) ORDER BY time DESC`]
    }
  },
  methods: {
    changeMode(mode) {
      this.mode = mode;
      this.refresh();
    },
    async refresh() {
      if (this.loading) return;
      this.loading = true;
      try {
        const res = await this.$http.post("/security/timeStream", {query: this.query[0]})
        const res2 = await this.$http.post("/security/timeStream", {query: this.query[1]})
        this.dataCollection = {
          labels: res.data.results.map(el => new Date(el.Data[0].ScalarValue).toLocaleString()),
          datasets: [
            {
              label: 'Current Period',
              backgroundColor: 'rgba(127, 80, 209, 0.6)',
              data: res.data.results.map(el => Number(el.Data[1].ScalarValue))
            },
            {
              label: 'Previous Period',
              backgroundColor: 'rgba(62, 163, 134, 0.3)',
              data: res2.data.results.map(el => Number(el.Data[1].ScalarValue))
            }
          ]
        };
      }catch (e) {
        console.log(e)
        this.$message.error(e.toLocaleString());
      }
      this.loading = false;
    },
    prepareInterval() {
      this.clearInterval();
      this.intervalId = setInterval(this.refresh, 60000);
    },
    clearInterval() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    }
  },
  mounted() {
    this.refresh();
    this.prepareInterval();
  },
  beforeDestroy() {
    this.clearInterval();
  }
}
</script>

<style scoped>

</style>
