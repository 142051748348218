const state = {
    ipToBlock: null,
    ipToGetInfo: null,
    ips: null
};

const mutations = {
    setIps(state, ips){
        state.ips = ips;
    },
    setIpToBlock(state, ip){
       state.ipToBlock = ip;
    },
    setIpToInfo(state, ip){
        state.ipToGetInfo = ip;
    }
};

const actions = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}