<template>
  <div class="pa-1">
    <TestMetadata v-model="test"/>
    <TestSections v-model="test" ref="sections"/>
    <KeyValuePairEditor v-model="test.keyValuePairs"/>
    <v-combobox
        class="mt-1"
        v-model="test.tags"
        chips
        hide-details
        outlined
        label="Tags"
        multiple
        solo
    >
      <template v-slot:selection="{ attrs, item, selected }">
        <v-chip
            v-bind="attrs"
            :input-value="selected"
            close
            @click:close="test.tags.splice(test.tags.indexOf(item), 1)"
        >
          <strong>{{ item }}</strong>
        </v-chip>
      </template>
    </v-combobox>
    <div class="text-center pa-8">
      <v-btn large :loading="submitting" :disabled="submitting" @click="submit" color="primary">SUBMIT</v-btn>
    </div>
  </div>
</template>
<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import KeyValuePairEditor from "@/components/KeyValuePairEditor";
import Platform from "@/components/PlatformComponent";
import TestSections from "@/components/tests/TestSections";
import TestMetadata from "@/components/tests/TestMetadata";

export default {
  components: {TestMetadata, TestSections, Platform, KeyValuePairEditor, LangFieldEditor},
  methods: {
    async submit() {
      this.submitting = true;
      try {
        const tb = JSON.parse(JSON.stringify(this.test))
        tb.sections.forEach(el => {
          el.questions.forEach(el => {
            delete el.data;
          })
        })
        const {data} = await this.$testHttp.post("/test", tb)
        if (data.statusCode === 0) {
          this.$message.success("Successful")
          if (this.$route.params.id === "create") {
            this.$set(this, 'test', this.getModel())
          }
        } else this.$message.error("Unknown error")
      } catch (e) {
        this.$message.error(e.response ? e.response.data['error']['message'] : e.toString())
      }
      this.submitting = false;
    },
    async fetchTest() {
      this.loading = true;
      this.fetchingError = false;
      try {
        const {data} = await this.$testHttp.get("/test/" + this.$route.params.id)
        if (data.statusCode === 0) {
          this.test = {...this.getModel(), ...data['data']}
        } else {
          this.fetchingError = true;
        }
      } catch (e) {
        this.$message.error(e.response ? e.response.data['error']['message'] : e.toString())
        this.fetchingError = true;
      }
      this.loading = false;
    },
    getModel() {
      return {
        title: {en: null},
        description: {en: null},
        instructions: {en: null},
        maxAttempt: 1,
        isAnalysisDeletable: false,
        maxMarks: 0,
        maxNegMarks: 0,
        timeAllotted: 0,
        totalQuestions: 0,
        languages: ['en'],
        defaultLanguage: 'en',
        sections: [],
        difficulty: null,
        syllabus: {en: null},
        policy: null,
        status: "review",
        layout: "default",
        liveAt: null,
        isPremium: false,
        tags: [],
        keyValuePairs: {}
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== "create") {
      this.fetchTest()
    }
  },
  data() {
    return {
      loading: false,
      fetchingError: false,
      submitting: false,
      test: this.getModel()
    }
  },
}
</script>

<style scoped>

</style>
