import { render, staticRenderFns } from "./LangQuestionComponent.vue?vue&type=template&id=5c21f2b7&scoped=true&"
import script from "./LangQuestionComponent.vue?vue&type=script&lang=js&"
export * from "./LangQuestionComponent.vue?vue&type=script&lang=js&"
import style0 from "./LangQuestionComponent.vue?vue&type=style&index=0&id=5c21f2b7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c21f2b7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VBtn,VDivider,VFlex,VLayout,VSelect})
