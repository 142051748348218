<template>
  <div ref="el" class="pa-1">
    <v-card v-if="q">
      <v-card-title>{{ q.title }}</v-card-title>
      <v-card-text v-html="q.excerpt"/>
    </v-card>
    <v-card class="mt-1" v-if="q">
      <v-card-actions>
        <v-btn :disabled="selectedLang === lang" @click="selectedLang = lang" v-for="lang in Object.keys(q.content)"
               :key="lang" outlined small>{{ $getLangName(lang) }}
        </v-btn>
        <v-spacer/>
        <v-btn v-if="q.content[selectedLang].hasOwnProperty('direction')" small outlined
               color="green"
               @click="() => { Object.keys(q.content).forEach(el => { q.content[el].direction = '';  }) }"
               :disabled="q.content[selectedLang].direction != null">ADD DIRECTION
        </v-btn>
        <v-btn v-if="q.content[selectedLang].hasOwnProperty('direction')" small outlined
               color="red"
               @click="() => { Object.keys(q.content).forEach(el => { q.content[el].direction = null;  }) }"
               :disabled="q.content[selectedLang].direction == null">REMOVE DIRECTION
        </v-btn>
        <v-btn v-if="q.content[selectedLang].hasOwnProperty('comprehension')" small outlined
               color="green"
               @click="() => { Object.keys(q.content).forEach(el => { q.content[el].comprehension = '';  }) }"
               :disabled="q.content[selectedLang].comprehension != null">ADD COMPREHENSION
        </v-btn>
        <v-btn v-if="q.content[selectedLang].hasOwnProperty('comprehension')" small outlined
               color="red"
               @click="() => { Object.keys(q.content).forEach(el => { q.content[el].comprehension = null;  }) }"
               :disabled="q.content[selectedLang].comprehension == null">REMOVE DIRECTION
        </v-btn>
      </v-card-actions>
      <v-divider/>
      <v-layout wrap
                v-if="q.content[selectedLang].hasOwnProperty('direction') && q.content[selectedLang].direction != null">
        <v-flex xs12 lg7 class="pa-1">
          <RichTextEditor
              outline
              v-model="q.content[selectedLang].direction"
              label="Direction"
              @input="mathJaxRefresh"
          />
        </v-flex>
        <v-flex xs12 lg5 class="pa-1">
          <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
            <h2 class="text-center">Direction Preview</h2>
            <v-divider/>
            <div
                ref="explain"
                v-html="q.content[selectedLang].direction || 'Demo Content'"
                class="pa-1 text-content font-math html-content"
            ></div>
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap v-if="q.content[selectedLang].hasOwnProperty('comprehension') && q.content[selectedLang].comprehension != null">
        <v-flex xs12 lg7 class="pa-1">
          <RichTextEditor
              outline
              v-model="q.content[selectedLang].comprehension"
              label="Comprehension"
              @input="mathJaxRefresh"
          />
        </v-flex>
        <v-flex xs12 lg5 class="pa-1">
          <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
            <h2 class="text-center">Comprehension Preview</h2>
            <v-divider/>
            <div
                ref="explain"
                v-html="q.content[selectedLang].comprehension || 'Demo Content'"
                class="pa-1 text-content font-math html-content"
            ></div>
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap v-if="q.content[selectedLang].hasOwnProperty('content')">
        <v-flex xs12 lg7 class="pa-1">
          <RichTextEditor
              outline
              v-model="q.content[selectedLang].content"
              label="Body"
              @input="mathJaxRefresh"
          />
        </v-flex>
        <v-flex xs12 lg5 class="pa-1">
          <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
            <h2 class="text-center">Content Preview</h2>
            <v-divider/>
            <div
                ref="explain"
                v-html="q.content[selectedLang].content || 'Demo Content'"
                class="pa-1 text-content font-math html-content"
            ></div>
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap v-if="q.content[selectedLang].hasOwnProperty('options')"
                v-for="(option, i) in q.content[selectedLang].options" :key="i">
        <v-flex xs12 lg7 class="pa-1">
          <RichTextEditor
              outline
              v-model="q.content[selectedLang].options[i].content"
              label="Option Content"
              @input="mathJaxRefresh"
          />
        </v-flex>
        <v-flex xs12 lg5 class="pa-1">
          <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
            <h2 class="text-center">Option {{ option.identifier }} Preview</h2>
            <v-divider/>
            <div
                ref="explain"
                v-html="q.content[selectedLang].options[i].content || 'Demo Content'"
                class="pa-1 text-content font-math html-content"
            ></div>
          </div>
        </v-flex>
      </v-layout>
      <v-layout wrap v-if="q.content[selectedLang].hasOwnProperty('explanation')">
        <v-flex xs12 lg7 class="pa-1">
          <RichTextEditor
              outline
              v-model="q.content[selectedLang].explanation"
              label="Explanation"
              @input="mathJaxRefresh"
          />
        </v-flex>
        <v-flex xs12 lg5 class="pa-1">
          <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
            <h2 class="text-center">Explanation Preview</h2>
            <v-divider/>
            <div
                ref="explain"
                v-html="q.content[selectedLang].explanation || 'Demo Content'"
                class="pa-1 text-content font-math html-content"
            ></div>
          </div>
        </v-flex>
      </v-layout>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn :disabled="submitting" :loading="submitting" @click="submitQuestion" dark color="green">SUBMIT</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-if="loading">
      <v-card>
        <v-card-text>
          <v-progress-linear color="white" indeterminate/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import RichTextEditor from "@/components/RichTextEditor";

export default {
  name: "update",
  components: {RichTextEditor},
  computed: {
    docId() {
      return this.$route.params.qid
    }
  },
  methods: {
    async getExplanation() {
      this.loading = true
      try {
        const {data} = await this.$http.get('/freelance/' + this.$route.params.id + '/explanation/' + this.docId)
        if (data['statusCode'] === 0) {
          this.selectedLang = data['data']['content'].hasOwnProperty('en') ? 'en' : Object.keys(data['data']['content'])[0]
          this.q = data['data']
          this.mathJaxRefresh()
        } else {
          this.$message.error('Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.loading = false
    },
    async submitQuestion() {
      this.submitting = true
      try {
        const {data} = await this.$http.post('/freelance/' + this.$route.params.id + '/explanation/update', this.q)
        if (data['statusCode'] === 0) {
          this.$message.success("Successful")
        } else {
          this.$message.error('Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.submitting = false
    },
    mathJaxRefresh() {
      setTimeout(() => {
        if (window.MathJax) {
          window.MathJax.typesetPromise([this.$refs.el])
        }
      }, 200);
    }
  },
  data() {
    return {
      loading: false,
      submitting: false,
      q: null,
      selectedLang: 'en'
    }
  },
  mounted() {
    this.getExplanation()
  },
  beforeDestroy() {
    window.MathJax.typesetClear([this.$refs.el])
  }
}
</script>

<style scoped>
.html-content {
  overflow-x: auto;
}
</style>
