<template>
  <v-dialog
    transition="dialog-bottom-transition"
    v-model="dialog"
    :scrollable="false"
    max-width="1200"
  >
    <v-card style="overflow-x: hidden">
      <v-layout justify-space-between class="pa-3 blue white--text">
        <v-flex>
          <h2 class="px-4">Upload Studio</h2>
        </v-flex>
        <v-flex style="text-align: end">
          <v-btn fab small dark text outlined @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-container>
        <div class="pa-2">
          <ImageDrawingUpload/>
        </div>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ImageUpload from "../ImageUpload";
import ImageDrawingUpload from "@/components/ImageDrawingUpload";

export default {
  name: "UploadDialogComponent",
  components: {ImageDrawingUpload, ImageUpload },
  computed: {
    dialog: {
      set(val) {
        this.$store.commit("setUploadDialogState", val);
      },
      get() {
        return this.$store.state.uploadDialog;
      }
    }
  },
  data() {
    return {
      activeTab: 0
    };
  }
};
</script>

<style scoped>
</style>
