<template>
  <v-card class="ma-2 pt-2 pb-2">
    <v-layout>
      <v-flex xs12 lg6 class="pa-1">
        <v-text-field type="text" label="Title" v-model="doc.title"  dense outlined hide-details/>
        <RichTextEditor v-model="doc.content" label="Enter Content"/>
      </v-flex>
      <v-flex xs12 lg6 class="pa-1">
        <h2>Preview</h2>
        <div class="html-content" v-html="doc.content"/>
      </v-flex>
    </v-layout>
    <div class="text-center">
      <v-btn large :loading="submitting" :disabled="submitting" :dark="!submitting" color="red" @click="submitDoc">UPDATE</v-btn>
    </div>
  </v-card>
</template>

<script>
import RichTextEditor from "@/components/RichTextEditor";
export default {
  name: "createOrUpdate",
  components: {RichTextEditor},
  methods: {
    async submitDoc() {
      this.submitting = true;
      try {
        const {data} = await this.$http.patch("/freelance/" + (this.$route.params.id) + "/docs/"+this.$route.params.docId, this.doc);
        if(data.statusCode === 0){
          this.$message.success("Successful");
        }else {
          this.$message.error(data.error ? data.error.message : "Unknown Error");
        }
      }catch (e) {
        this.$message.error(e.toString())
      }
      this.submitting = false
    },
    async loadDoc() {
      this.loading = true;
      this.loadingError = null;
      try {
        const {data} = await this.$http.get("/freelance/" + (this.$route.params.id) + "/docs/"+this.$route.params.docId);
        if(data.statusCode === 0){
          this.doc = data.data
        }else {
          this.loadingError = data.error ? data.error.message : "Unknown Error";
        }
      }catch (e) {
        this.loadingError = e.toString()
      }
      this.loading = false
    }
  },
  computed: {
    loadingErrorDialog: {
      set(v) { this.loadingError = null },
      get() { return this.loadingError != null }
    }
  },
  data() {
    return {
      loading: false,
      loadingError: null,
      submitting: false,
      doc: {
        title: null,
        content: null
      }
    }
  },
  mounted() {
    this.loadDoc();
  }
}
</script>

<style scoped>
.html-content {
  overflow-x: auto;
}
</style>
