<template>
  <div>
    <div class="ma-1" :key="i" v-for="(session, i) in sessions">
      <h2
        v-if="i === 0 || new Date(sessions[i - 1].time * 1000).toDateString() !== new Date(session.time * 1000).toDateString()"
      >{{new Date(session.time * 1000).toDateString()}}</h2>
      <v-card class="pa-1">
        <h2 class="green--text" v-if="session.status === 'open'">ACTIVE</h2>
        <v-layout row wrap>
          <v-flex xs6>
            <b>IP Address:</b>
            {{session.ip}}
          </v-flex>
          <v-flex xs6>
            <b>Source:</b>
            {{session.session_source}}
          </v-flex>
          <v-flex xs6>
            <b>Time:</b>
            {{new Date(session.time * 1000).toDateString()}}
            {{new Date(session.time * 1000).toLocaleTimeString()}}
          </v-flex>
          <v-flex xs6>
            <b>Status:</b>
            {{session.status}}
          </v-flex>
        </v-layout>
      </v-card>
    </div>
    <div v-if="get" class="pa-4 text-xs-center">
      <v-progress-circular color="blue"/>
    </div>
    <div v-if="err" class="pa-4 text-xs-center">
      <v-btn dark color="blue" @click="getSessions">Retry</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sessions",
  methods: {
    getSessions() {
      this.get = true;
      this.err = false;
      this.$http
        .get("/account/sessions")
        .then(res => {
          this.get = false;
          let json = res.data;
          if (json.err_code === 0) {
            this.sessions = json.results;
          } else {
            this.err = false;
          }
        })
        .catch(err => {
          this.get = false;
          this.err = true;
        });
    }
  },
  mounted() {
    this.getSessions();
  },
  data() {
    return {
      get: false,
      err: false,
      sessions: []
    };
  }
};
</script>

<style scoped>
</style>