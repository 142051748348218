<template>
  <div>
    <PastQuestionsMetadata v-model="search" class="ma-1"/>
    <v-layout wrap v-if="results.length > 0">
      <v-flex xs12 class="pa-1" :key="i" v-for="(item, i) in results">
        <v-card>
          <v-card-title>{{ item.theory.en.title }}</v-card-title>
          <v-card-text v-html="item.theory.en.content.slice(0, 240)"/>
          <v-card-actions>
            <v-spacer/>
            <v-btn small :to="'/theory/'+item.theoryId" text outlined color="green">UPDATE</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="pa-4 text-sm-center" v-if="loading">
      <v-progress-circular indeterminate color="red"/>
    </div>
    <div class="pa-4 text-sm-center" v-if="!loading && !noMore && results.length > 0">
      <v-btn color="primary" @click="()=> fetch(page + 1)">LOAD MORE</v-btn>
    </div>
  </div>
</template>

<script>
import NotesMetadata from "@/components/notes/Metadata";
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";

export default {
  name: "update",
  components: {PastQuestionsMetadata, NotesMetadata},
  methods: {
    async fetch(page) {
      if (this.search.country && this.search.examGroup && this.search.exam) {
        this.loading = true;
        try {
          this.page = page || 0
          if (this.page === 0) {
            this.results = []
            this.noMore = false
          }
          const {data} = await this.$notesHttp.get("/list", {params: {...this.search, page: this.page}})
          if (data.statusCode === 0) {
            this.results = [...this.results, ...data.results]
            if (data.results.length < 10) {
              this.noMore = true
            }
          } else {
            this.$message.error(data.error ? data.error.message : "Unknown Error Occurred! Try Again")
          }
        } catch (e) {
          this.$message.error(e.toString())
        }
        this.loading = false;
      }
    }
  },
  data() {
    return {
      loading: false,
      results: [],
      noMore: false,
      page: 0,
      search: {
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        status: null,
        topic: null,
        q: null
      }
    }
  },
  watch: {
    search: {
      deep: true,
      handler(newValue) {
        if (newValue.country && newValue.examGroup && newValue.exam) {
          this.fetch(0);
        }
      }
    }
  },
}
</script>

<style scoped>

</style>
