<template>
  <v-layout align-center justify-center :reverse="$vuetify.breakpoint.mdAndDown">
    <v-flex xs12 lg8>
      <v-text-field v-if="!textArea" dense outlined hide-details :label="label" v-model="val[df]" @input="onChange"/>
      <v-textarea rows="3" v-else dense outlined hide-details :label="label" v-model="val[df]" @input="onChange"/>
    </v-flex>
    <v-flex xs12 lg4 class="pl-1">
      <v-select dense outlined hide-details v-model="lang" :items="$availLanguages"
                :label="label ? label + ' Language' : null"/>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: "LangFieldEditor",
  props: {
    label: {
      type: String
    },
    textArea: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      required: true
    },
    defaultLang: {
      type: String,
      default: 'en'
    }
  },
  computed: {
    val() {
      return this.value;
    },
    df() {
      return this.lang;
    }
  },
  data() {
    return {
      lang: this.defaultLang || 'en'
    }
  },
  methods: {
    onChange(v) {
      this.$emit('textChange', v)
    }
  },
}
</script>

<style scoped>

</style>
