<template>
  <v-card style="margin: 0 auto; text-align: center">
    <div class="white--text blue pa-3">
      <img
        src="https://gateclass.static.cdn.examgoal.com/JCb6Gy35nIrxE0ctS/lweAftlmw3lUyXwxxjRTk74BAPOS9/CFvHwXkmr1EcYaKDCsMMVS/login.svg"
        style="height: 50px;"
      >
      <div style="font-size: 30px">Login to Your Account</div>
    </div>
    <div>
      <v-alert
        style="margin: 10px 16px"
        v-if="err"
        transition="scale-transition"
        :value="true"
        :color="errColor"
        :icon="errIcon"
        outlined
      >{{err}}</v-alert>
      <v-form>
        <v-text-field
          style="margin: 10px 16px"
          outlined
          v-model="name"
          :error-messages="nameErr"
          label="Enter User Name"
          required
          @input="nameErr = []"
        ></v-text-field>

        <v-text-field
          style="margin: 5px 16px"
          outlined
          type="password"
          v-model="password"
          :error-messages="passErr"
          label="Enter Password"
          required
          @input="passErr = []"
        ></v-text-field>
      </v-form>

      <v-btn
        large
        text
        outlined
        color="blue"
        :disabled="checking"
        :loading="checking"
        @click="onSubmit"
      >Submit</v-btn>
    </div>
    <div class="text-xs-center pa-2">&copy; {{new Date().getFullYear()}}</div>
  </v-card>
</template>

<script>
export default {
  name: "Login",
  data: () => {
    return {
      checking: false,
      name: null,
      password: null,
      valid: false,
      nameErr: [],
      passErr: [],
      err: null,
      errColor: null,
      errIcon: "warning"
    };
  },
  methods: {
    onSubmit() {
      this.nameErr = [];
      this.passErr = [];
      if (this.name == null || this.name.length === 0) {
        this.nameErr.push("Enter Your Username");
      } else if (this.password == null || this.password.length === 0) {
        this.passErr.push("Enter Password");
      } else {
        this.onCheck();
      }
    },
    onCheck() {
      this.checking = true;
      this.err = null;
      this.errColor = null;
      this.errIcon = null;
      this.$http
        .post("/account/login", {
          userName: this.name,
          password: this.password
        })
        .then(res => {
          this.checking = false;
          if (res.data.statusCode === 0) {
            this.err = "Authorization Successful! Redirecting";
            this.errColor = "success";
            this.errIcon = "check_circle";
            this.$emit("loggedIn");
          } else {
            this.err = "Check Username or Password";
            this.errColor = "error";
            this.errIcon = "info";
          }
        })
        .catch(err => {
          this.checking = false;
          this.err = "Check your Internet Connection";
          this.errColor = "error";
          this.errIcon = "info";
        });
    }
  }
};
</script>

<style scoped>
</style>