<template>
  <v-dialog v-model="dialog" max-width="1100px">
    <v-card>
      <v-card-title>HTML Editor</v-card-title>
      <VueEditor v-model="html"/>
      <div class="text-center pa-2">
        <v-btn @click="copy">COPY <v-icon right>content_copy</v-icon></v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {VueEditor} from "vue2-editor";
import Quill from 'quill'

export default {
  name: "HTMLEditorDialog",
  components: {VueEditor},
  methods: {
    async copy() {
      if (window.navigator) {
        await window.navigator.clipboard.writeText(this.html);
      }
    }
  },
  data() {
    return {
      html: null
    }
  },
  computed: {
    dialog: {
      set(val) {
        this.$store.commit("setHTMLDialog", val);
      },
      get() {
        return this.$store.state.htmlDialog;
      }
    }
  },
  mounted() {
    let AlignStyle = Quill.import('attributors/style/align')
    let BackgroundStyle = Quill.import('attributors/style/background')
    let ColorStyle = Quill.import('attributors/style/color')
    let DirectionStyle = Quill.import('attributors/style/direction')
    let FontStyle = Quill.import('attributors/style/font')
    let SizeStyle = Quill.import('attributors/style/size')

    Quill.register(AlignStyle, true);
    Quill.register(BackgroundStyle, true);
    Quill.register(ColorStyle, true);
    Quill.register(DirectionStyle, true);
    Quill.register(FontStyle, true);
    Quill.register(SizeStyle, true);
  }
}
</script>

<style scoped>

</style>
