<template>
  <div class="pa-2">
    <div class="text-right">
      <v-btn :to="'/notification-email-template/create'" large color="green" dark>CREATE TEMPLATE</v-btn>
    </div>
    <v-card class="mt-1">
      <v-card-title>Campaigns</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-list-item v-for="message in results" :key="message.Name" class="mt-1 mb-1">
          <v-list-item-content>
            <v-list-item-title>{{message.Name}}</v-list-item-title>
            <v-list-item-subtitle>{{new Date(message.CreatedTimestamp).toString()}}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn small outlined :to="'/notification-email-template/'+message.Name">EDIT</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "templates",
  methods: {
   async getTemplates() {
      this.isLoading = true
      try {
        const {data} = await this.$notiHttp.get('/email/template')
        if (data['statusCode'] === 0) {
          this.results = data['results']
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : "Unknown Error Occurred!")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isLoading = false
    }
  },
  data() {
    return {
      isLoading: false,
      results: []
    }
  },
  mounted() {
    this.getTemplates()
  }
}
</script>

<style scoped>

</style>
