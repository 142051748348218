<template>
    <v-dialog width="500" :persistent="true" v-model="me">
        <v-card v-if="checking">
            <v-layout align-center justify-center style="height: 300px">
                <div class="text-center">
                    <v-progress-circular color="red" indeterminate size="50" class="mb-4"/>
                    <h2>Checking Login Status</h2>
                </div>
            </v-layout>
        </v-card>
        <v-card v-if="failedToCheck">
            <v-layout align-center justify-center style="height: 300px">
                <div class="text-center">
                    <h2>Failed to Refresh Login Status</h2>
                    <v-btn class="mt-2" flat rounded outline large color="purple" @click="checkLoginStatus">Retry</v-btn>
                </div>
            </v-layout>
        </v-card>
        <Login v-if="loginDialog" @loggedIn="checkLoginStatus"/>
    </v-dialog>
</template>

<script>
    import Login from "../Login";
    export default {
        name: "LoginDialog",
        components: { Login },
        computed: {
            loginDialog: {
                set(val) {
                },
                get() {
                    return this.$store.state.user === null && this.checking === false && this.failedToCheck === false;
                }
            },
            me: {
                set(v){

                },
                get(){
                    return this.$store.state.user === null;
                }
            }
        },
        methods: {
            checkLoginStatus() {
                this.checking = true;
                this.failedToCheck = false;
                this.$http.get("account/me")
                    .then(res=>{
                        let json = res.data;
                        if (json.statusCode === 0) {
                            this.$store.commit("setUser", json.data);
                        }
                        this.checking = false;
                    }).catch(err=>{
                    this.checking = false;
                    if(!err.message) {
                        this.failedToCheck = true;
                        return;
                    }
                    if(err.message.indexOf("Session expired") !== -1){
                        this.$message.error("Session Expired! Please Login Again");
                    }
                    this.failedToCheck = err.message.indexOf("Session expired") === -1;
                });
            }
        },
        mounted(){
            this.checkLoginStatus();
        },
        data() {
            return {
                checking: true,
                failedToCheck: false,
            }
        },
    };
</script>

<style scoped>
</style>