<template>
  <v-card class="ma-4">
    <v-card-title>Chapter</v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex xs8>
          <v-layout wrap>
            <v-flex xs4>
              <v-text-field disabled outlined hide-details v-model="find.country" label="Country" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field disabled outlined hide-details v-model="find.examGroup" label="Exam Group" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field disabled outlined hide-details v-model="find.exam" label="Exam" dense/>
            </v-flex>
            <v-flex xs4 class="mt-2">
              <v-text-field disabled outlined hide-details v-model="find.subject" label="Subject" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1 mt-2">
              <v-text-field disabled outlined hide-details v-model="find.chapterGroup" label="Chapter Group (Optional)"
                            dense/>
            </v-flex>
            <v-flex xs4 class="pl-1 mt-2">
              <v-text-field type="number" outlined hide-details v-model="create.order" label="Enter Order" dense/>
            </v-flex>
            <v-flex xs8 class="mt-2">
              <v-text-field v-model="create.key" label="Enter Key" hide-details outlined dense/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.title" label="Title" @textChange="suggestChapter"/>
              <div v-if="autoComplete.length > 0" class="mt-2" style="display: flex; flex-wrap: wrap; gap: 4px">
                <v-card v-for="data in autoComplete" :key="data.key" outlined
                        @click="create.title.en = data.title; create.key = data.key" :dark="create.key === data.key" :color="create.key === data.key ? 'green' : undefined" min-width="200">
                  <div class="px-4 py-2">
                    <div>{{ data.title }}</div>
                    <div class="caption mt-1" style="opacity: 80%">{{ data.key }}</div>
                  </div>
                </v-card>
              </div>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <v-text-field v-model="create.image" label="Image (Optional)" hide-details outlined dense/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.description" text-area label="Description (Optional)"/>
            </v-flex>
          </v-layout>
          <KeyValuePairEditor v-model="create.keyValuePairs"/>
          <div class="text-center pa-4">
            <v-btn outlined color="primary" :disabled="!create.title || !create.key || !find.country || !find.examGroup"
                   @click="createOrUpdate">CREATE OR UPDATE
            </v-btn>
            <v-btn outlined color="red" :disabled="create.id == null" @click="deleteDialog = true">DELETE</v-btn>
          </div>
        </v-flex>
        <v-flex xs4 class='pl-1'>
          <v-select outlined hide-details v-model="find.country" label="Select Country"
                    @input="()=> {findRequest(); null;create.id = null; }" :items="$availCountries" dense/>
          <v-select outlined hide-details v-model="find.examGroup" :loading="find.loading"
                    @input="(v) => {findRequest2();}" class="mt-2" :items="groupItems" label="Select Exam Group" dense/>
          <v-select outlined hide-details v-model="find.exam" :loading="find.loading"
                    @input="(v) => { findRequest4();}" class="mt-2" :items="examItems"
                    label="Select Exam" dense/>
          <v-select outlined hide-details v-model="find.subject" :loading="find.loading"
                    @input="(v) => {findRequest5(); findRequest6();}" class="mt-2" :items="subItems"
                    label="Select Subject" dense/>
          <v-select outlined hide-details v-model="find.chapterGroup" :loading="find.loading"
                    @input="(v) => {findRequest6();}" class="mt-2" :items="chapterGroupItems"
                    label="Select Chapter Group" dense/>
          <v-select outlined hide-details v-model="find.chapter" :loading="find.loading" @input="(v) => {
const item = this.find.chapters.filter(el=> el.key === v)[0];
this.create.key = item.key;
this.create.title = typeof item.title === 'object' ? item.title : {en: item.title};
 this.create.description = typeof item.description === 'object' && item.description ? item.description : {en: item.description};
this.create.id = item.metaId;
 this.create.keyValuePairs = item.keyValuePairs || {};
   this.create.image = item.image || null;
this.create.order = item.order || 0}" class="mt-2" :items="chapterItems" label="Select Chapter" dense/>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" :max-width="600">
        <v-card class="pa-4">
          <v-card-text>
            Do you want to delete?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text outlined color="green" @click="deleteDialog = false">CANCEL</v-btn>
            <v-btn text outlined color="red" @click="deleteRequest">DELETE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>

</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import KeyValuePairEditor from "@/components/KeyValuePairEditor";

export default {
  components: {KeyValuePairEditor, LangFieldEditor},
  methods: {
    async suggestChapter(value) {
      try {
        const {data} = await this.$metaHttp.post(
            "/chapter/suggest",
            {value},
        );
        if (data.statusCode === 0) {
          this.autoComplete = data['results']
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
    resetLevel(level) {
      if (level === 0) {
        this.find.examGroups = [];
        this.find.examGroup = null;
      }
      if (level <= 1) {
        this.find.exams = [];
        this.find.exam = null;
      }
      if (level <= 3) {
        this.find.subjects = [];
        this.find.subject = null;
      }
      if (level <= 4) {
        this.find.chapterGroups = [];
        this.find.chapterGroup = null;
      }
      if (level <= 5) {
        this.find.chapters = [];
        this.find.chapter = null;
      }
    },
    async findRequest() {
      this.find.loading = true;
      this.resetLevel(0);
      try {
        const {data} = await this.$metaHttp.get("/examGroup", {
          params: {
            country: this.find.country,
          },
        });
        if (data.statusCode === 0) {
          this.find.examGroups = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest2() {
      this.find.loading = true;
      this.resetLevel(1);
      try {
        const {data} = await this.$metaHttp.get("/exam", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
          },
        });
        if (data.statusCode === 0) {
          this.find.exams = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest4() {
      this.find.loading = true;
      this.resetLevel(3);
      try {
        const {data} = await this.$metaHttp.get("/subject", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            exam: this.find.exam,
          },
        });
        if (data.statusCode === 0) {
          this.find.subjects = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest5() {
      this.find.loading = true;
      this.resetLevel(4);
      try {
        const {data} = await this.$metaHttp.get("/chapterGroup", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            subject: this.find.subject,
            exam: this.find.exam,
          },
        });
        if (data.statusCode === 0) {
          this.find.chapterGroups = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest6() {
      this.find.loading = true;
      this.resetLevel(5);
      try {
        const {data} = await this.$metaHttp.get("/chapter", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            subject: this.find.subject,
            chapterGroup: this.find.chapterGroup,
            exam: this.find.exam,
          },
        });
        if (data.statusCode === 0) {
          this.find.chapters = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async createOrUpdate() {
      this.create.loading = false;
      try {
        const {data} = await this.$metaHttp.post(
            "/chapter",
            {
              country: this.find.country,
              examGroup: this.find.examGroup,
              exam: this.find.exam,
              subject: this.find.subject,
              chapterGroup: this.find.chapterGroup,
              description: this.create.description,
              title: this.create.title,
              image: this.create.image,
              key: this.create.key,
              keyValuePairs: this.create.keyValuePairs,
              order: this.create.order
            }
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.create.loading = false;
    },
    async deleteRequest() {
      this.deleteDialog = false;
      try {
        const {data} = await this.$metaHttp.delete("/chapter", {params: {id: this.create.id}});
        if (data.statusCode === 0) {
          this.$message.success("Successful");
          this.create.id = null;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  computed: {
    groupItems() {
      return this.find.examGroups.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    subGroupItems() {
      return this.find.subjectGroups.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    chapterGroupItems() {
      return this.find.chapterGroups.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    chapterItems() {
      return this.find.chapters.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    subItems() {
      return this.find.subjects.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    examItems() {
      return this.find.exams.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
  },
  data: () => {
    return {
      deleteDialog: false,
      autoComplete: [],
      create: {
        id: null,
        loading: false,
        title: {en: null},
        image: null,
        key: null,
        description: {en: null},
        keyValuePairs: {},
        order: 0
      },
      find: {
        country: null,
        examGroup: null,
        exam: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        loading: false,
        chapterGroups: [],
        chapters: [],
        subjects: [],
        examGroups: [],
        exams: [],
      },
    };
  },
};
</script>

<style>
</style>
