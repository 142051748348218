export default {
    FULL_SYSTEM: 0,

    SYS_USER_MODIFY: 1,
    SYS_USER_WRITE: 2,
    SYS_USER_READ: 3,
    SYS_USER_DELETE: 4,

    ADMIN_USER_MODIFY: 5,
    ADMIN_USER_WRITE: 6,
    ADMIN_USER_READ: 7,
    ADMIN_USER_DELETE: 8,

    PAST_QUESTION_READ: 9,
    PAST_QUESTION_MODIFY: 10,
    PAST_QUESTION_WRITE: 11,
    PAST_QUESTION_DELETE: 12,

    FORMULA_READ: 13,
    FORMULA_MODIFY: 14,
    FORMULA_WRITE: 15,
    FORMULA_DELETE: 16,

    SYLLABUS_READ: 17,
    SYLLABUS_MODIFY: 18,
    SYLLABUS_WRITE: 19,
    SYLLABUS_DELETE: 20,

    MEDICAL_PAST_QUESTION_READ: 23,
    MEDICAL_PAST_QUESTION_MODIFY: 24,
    MEDICAL_PAST_QUESTION_WRITE: 25,
    MEDICAL_PAST_QUESTION_DELETE: 26,

    JEE_PAST_QUESTION_READ: 27,
    JEE_PAST_QUESTION_MODIFY: 28,
    JEE_PAST_QUESTION_WRITE: 29,
    JEE_PAST_QUESTION_DELETE: 30,

    BANKING_PAST_QUESTION_READ: 31,
    BANKING_PAST_QUESTION_MODIFY: 32,
    BANKING_PAST_QUESTION_WRITE: 33,
    BANKING_PAST_QUESTION_DELETE: 34,

    EXAM_META_EDIT: 35,
    MEDICAL_EXAM_META_EDIT: 36,
    JEE_EXAM_META_EDIT: 37,
    BANKING_EXAM_META_EDIT: 38,
    RAILWAYS_EXAM_META_EDIT: 89,
    SSC_EXAM_META_EDIT: 90,
    DEFENCE_EXAM_META_EDIT: 91,

    QUESTION_READ: 51,
    QUESTION_WRITE: 52,
    QUESTION_MODIFY: 53,
    QUESTION_DELETE: 54,

    JEE_QUESTION_READ: 39,
    JEE_QUESTION_WRITE: 40,
    JEE_QUESTION_MODIFY: 41,
    JEE_QUESTION_DELETE: 42,

    MEDICAL_QUESTION_READ: 43,
    MEDICAL_QUESTION_WRITE: 44,
    MEDICAL_QUESTION_MODIFY: 45,
    MEDICAL_QUESTION_DELETE: 46,

    BANKING_QUESTION_READ: 47,
    BANKING_QUESTION_WRITE: 48,
    BANKING_QUESTION_MODIFY: 49,
    BANKING_QUESTION_DELETE: 50,

    COMPOSE_NOTIFICATION: 21,
    READ_NOTIFICATION: 22,
    UPDATE_NOTIFICATION: 55,
    DELETE_NOTIFICATION: 56,

    GATE_PAST_QUESTION_READ: 57,
    GATE_PAST_QUESTION_WRITE: 58,
    GATE_PAST_QUESTION_MODIFY: 59,
    GATE_PAST_QUESTION_DELETE: 60,

    GATE_QUESTION_READ: 61,
    GATE_QUESTION_WRITE: 62,
    GATE_QUESTION_MODIFY: 63,
    GATE_QUESTION_DELETE: 64,

    SSC_PAST_QUESTION_READ: 65,
    SSC_PAST_QUESTION_WRITE: 66,
    SSC_PAST_QUESTION_MODIFY: 67,
    SSC_PAST_QUESTION_DELETE: 68,

    SSC_QUESTION_READ: 69,
    SSC_QUESTION_WRITE: 70,
    SSC_QUESTION_MODIFY: 71,
    SSC_QUESTION_DELETE: 72,

    RAILWAYS_PAST_QUESTION_READ: 73,
    RAILWAYS_PAST_QUESTION_WRITE: 74,
    RAILWAYS_PAST_QUESTION_MODIFY: 75,
    RAILWAYS_PAST_QUESTION_DELETE: 76,

    RAILWAYS_QUESTION_READ: 77,
    RAILWAYS_QUESTION_WRITE: 78,
    RAILWAYS_QUESTION_MODIFY: 79,
    RAILWAYS_QUESTION_DELETE: 80,

    DEFENCE_PAST_QUESTION_READ: 81,
    DEFENCE_PAST_QUESTION_WRITE: 82,
    DEFENCE_PAST_QUESTION_MODIFY: 83,
    DEFENCE_PAST_QUESTION_DELETE: 84,

    DEFENCE_QUESTION_READ: 85,
    DEFENCE_QUESTION_WRITE: 86,
    DEFENCE_QUESTION_MODIFY: 87,
    DEFENCE_QUESTION_DELETE: 88,


}