<template>
  <div>
    <TestQuestionMetadata ref="meta" v-model="q" @type-change="prepareOptions"/>
    <v-card class="ma-1">
      <v-layout class="pa-1">
        <v-flex style="flex-grow: 1">
          <v-btn
              class="mr-1"
              color="primary"
              rounded
              outlined
              @click="currentLang = lang"
              :key="lang"
              v-for="lang in q.languages"
              :disabled="currentLang === lang"
          >
            {{ $getLangName(lang) }}
            <v-btn
                small
                color="red"
                text
                style="height: 20px;width: 0px"
                @click.stop="removeLang(lang)"
                v-if="q.languages.length > 1 && lang !== 'en'"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-btn>
        </v-flex>
        <div>
          <v-dialog v-model="langDialog" width="400">
            <template v-slot:activator="{on}">
              <v-btn v-on="on" text fab outlined small color="red">
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  :key="item.value"
                  @click="addLang(item.value)"
                  v-for="item in $availLanguages.filter((el)=> q.languages.indexOf(el.value) === -1)"
              >
                <v-list-item-title>{{ $getLangName(item.value) }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-dialog>
        </div>
      </v-layout>
      <v-divider/>
      <LangQuestionComponent
          ref="langQuestion"
          :key="i"
          v-for="(n, i) in q.languages"
          :type="q.type"
          :lang="n"
          :question="q.question"
          v-bind:style="currentLang === n ? {display: 'block'} : {display : 'none'}"
      />
      <v-divider/>
      <div class="text-center pa-4">
        <v-btn
            large
            color="red"
            class="white--text"
            :loading="sending"
            :disabled="sending"
            @click="submit"
        >Submit
        </v-btn>
      </div>
    </v-card>
    <v-snackbar top right v-model="snackBar">
      {{ snackBarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            outlined
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="questionGetting" persistent width="300">
      <v-card color="red" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="questionGettingError" persistent width="300">
      <v-card color="red" dark>
        <v-card-text class="text-xs-center">
          <h2>Unknown Error Occurred</h2>
          <v-btn class="ma-2" flat outline @click="getQuestion">Retry</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LangQuestionComponent from "@/components/LangQuestionComponent";
import PastQuestionMetadata from "@/components/metadata/PastQuestionMetadata";
import TestQuestionMetadata from "@/components/tests/TestQuestionMetadata";

export default {
  components: {
    TestQuestionMetadata,
    LangQuestionComponent,
    PastQuestionMetadata,
  },
  name: "Update",
  methods: {
    removeLang(n) {
      if (this.q.languages.length === 1 || this.q.languages.indexOf(n) === -1)
        return false;
      let pos = this.q.languages.indexOf(n);
      this.q.languages.splice(pos, 1);
      this.$delete(this.q.question, n);
      if (this.currentLang === n) {
        this.currentLang = this.q.languages[0];
      }
    },
    addLang(v) {
      this.langDialog = false;
      if (this.q.languages.indexOf(v) === -1) {
        this.q.languages.push(v);
      }
      this.q.languages.forEach((el) => {
        if (!this.q.question[el]) {
          this.$set(this.q.question, el, {
            content: null,
            options: [],
            correct_options: [],
            answer: null,
            explanation: null,
          });
          this.prepareOptions();
        }
      });
    },
    prepareOptions() {
      if (["mcq", "mcqm", "t/f"].indexOf(this.q.type) !== -1) {
        for (let key in this.q.question) {
          if (
              this.q.question[key].options.length === 0 ||
              this.q.type === "t/f"
          ) {
            this.q.question[key].options = [];
            switch (this.q.type) {
              case "t/f":
                this.q.question[key].options.push(
                    {
                      identifier: "A",
                      content:
                          key === "hi" ? "सच" : key === "bn" ? "সত্য" : "TRUE",
                    },
                    {
                      identifier: "B",
                      content:
                          key === "hi"
                              ? "असत्य"
                              : key === "bn"
                                  ? "মিথ্যা"
                                  : "FALSE",
                    }
                );
                break;
              default:
                this.q.question[key].options.push(
                    {
                      identifier: "A",
                      content: null,
                    },
                    {
                      identifier: "B",
                      content: null,
                    },
                    {
                      identifier: "C",
                      content: null,
                    },
                    {
                      identifier: "D",
                      content: null,
                    }
                );
            }
          }
        }
      } else {
        for (let key in this.q.question) {
          if (
              !this.q.question[key].options ||
              this.q.question[key].options.length !== 0
          ) {
            this.q.question[key].options = [];
          }
        }
      }
    },
    submit() {
      this.snackBar = false;
      this.snackBarMessage = null;
      this.sending = true;
      this.$http
          .patch("/freelance/" + this.$route.params.id + "/test-question", {...this.q})
          .then((res) => {
            this.sending = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.$message.success('Successful')
            } else {
              this.snackBar = true;
              this.snackBarMessage = "Unknown Error!";
            }
          })
          .catch((err) => {
            this.snackBar = true;
            this.snackBarMessage = "Connection Error!";
            this.sending = false;
          });
    },
    getQuestion() {
      this.questionGetting = true;
      this.questionGettingError = false;
      this.$http
          .get("/freelance/" + this.$route.params.id + "/test-questions/" + this.$route.params.qId)
          .then((res) => {
            this.questionGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.snackBar = true;
              this.snackBarMessage = "Success!";
              this.$set(this, "q", {...json.data, topic: json.data.topic || null});
              this.$refs.meta.populating = true
              setTimeout(this.$refs["meta"].populate, 1000);
              setTimeout(() => {
                this.$refs.langQuestion.forEach(elm => {
                  elm.mathJaxRefresh();
                })
              }, 1000);
            } else {
              this.questionGettingError = true;
            }
          })
          .catch((err) => {
            this.questionGetting = false;
            this.questionGettingError = true;
          });
    },
    reset() {
      this.questionGetting = false;
      this.questionGettingError = false;
      this.sending = false;
      this.sendingError = false;
      this.snackBar = false;
      this.snackBarMessage = null;
      this.langDialog = false;
      this.currentLang = "en";
      this.q = {
        question_id: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        year: null,
        paperTitle: null,
        languages: ["en"],
        difficulty: null,
        topic: null,
        type: null,
        question: {
          en: {
            content: null,
            options: [],
            correct_options: [],
            answer: null,
            explanation: null,
          },
        }
      };
    },
  },
  mounted() {
    this.getQuestion();
  },
  data() {
    return {
      questionGetting: false,
      questionGettingError: false,
      sending: false,
      sendingError: false,
      snackBar: false,
      snackBarMessage: null,
      langDialog: false,
      currentLang: "en",
      q: {
        status: "review",
        question_id: null,
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        year: null,
        paperId: null,
        paperTitle: null,
        marks: 0.0,
        negMarks: 0.0,
        languages: ["en"],
        difficulty: null,
        topic: null,
        type: null,
        question: {
          en: {
            content: null,
            options: [],
            correct_options: [],
            answer: null,
            explanation: null,
          },
        },
      }
    };
  },
  watch: {
    "$route.params.qid": function (val) {
      this.reset();
      this.getQuestion();
    },
  },
};
</script>

<style scoped>
.text-content {
  word-wrap: break-word;
}
</style>
