<template>
  <v-card class="ma-4">
    <v-card-title>Paper</v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex xs8>
          <v-layout wrap>
            <v-flex v-if="create.id" xs4>
              <v-text-field disabled outlined hide-details v-model="create.id" label="Meta Id" dense/>
            </v-flex>
            <v-flex xs4>
              <v-text-field disabled outlined hide-details v-model="find.country" label="Country" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field disabled outlined hide-details v-model="find.examGroup" label="Exam Group" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field disabled outlined hide-details v-model="find.exam" label="Exam" dense/>
            </v-flex>
            <v-flex xs4 class="mt-2">
              <v-switch class="mt-0" hide-details dense label="Online Paper" v-model="create.isOnline"/>
            </v-flex>
            <v-flex xs4 class="pl-1 mt-2">
              <v-text-field v-model="create.year" type="number" label="Enter Year" hide-details outlined dense/>
            </v-flex>
            <v-flex xs4 class="pl-1 mt-2">
              <v-select :items="$availLanguages" multiple class="mt-0" hide-details dense label="Languages" v-model="create.languages" outlined/>
            </v-flex>
            <v-flex xs6 class="mt-2">
              <v-text-field v-model="create.key" label="Enter Key" hide-details outlined dense/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.title" label="Title"/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.description" text-area label="Description (Optional)"/>
            </v-flex>
          </v-layout>
          <Platform class="mt-2" v-model="create"/>
          <h4 class="mt-2">Exam Date</h4>
          <DateTimeField class="mt-2" v-model="create.date"/>
          <h4 class="mt-2">Live At (Optional)</h4>
          <DateTimeField class="mt-2" v-model="create.liveAt"/>
          <KeyValuePairEditor class="mt-2" v-model="create.keyValuePairs"/>
          <div class="text-center pa-4">
            <v-btn outlined color="primary" :disabled="!create.title || !create.key || !find.country || !find.examGroup"
                   @click="createOrUpdate">CREATE OR UPDATE
            </v-btn>
            <v-btn outlined color="red" :disabled="create.id == null" @click="deleteDialog = true">DELETE</v-btn>
          </div>
        </v-flex>
        <v-flex xs4 class='pl-1'>
          <v-select outlined hide-details v-model="find.country" label="Select Country"
                    @input="()=> { find.papers = find.examGroups = find.exams = []; find.examGroup = find.exam = find.paper = null; create.country = find.country; create.date = create.id = null; findRequest();}"
                    :items="$availCountries" dense/>
          <v-select outlined hide-details v-model="find.examGroup" :loading="find.loading"
                    @input="(v) => { find.exam = find.paper = null; findRequest2();}" class="mt-2" :items="groupItems"
                    label="Select Exam Group" dense/>
          <v-select outlined hide-details v-model="find.exam" :loading="find.loading"
                    @input="(v) => { find.paper = null; findRequest3();}" class="mt-2" :items="examItems"
                    label="Select Exam" dense/>
          <v-select outlined hide-details v-model="find.paper" :loading="find.loading" @input="(v) => {
  const item = this.find.papers.filter(el=> el.key === v)[0];
  this.create.key = item.key;
  this.create.id = item.metaId;
  this.create.title = typeof item.title === 'object' ? item.title : {en: item.title};
  this.create.description = typeof item.description === 'object' && item.description ? item.description : {en: item.description};
  this.create.date = item.date || null;
  this.create.liveAt = item.liveAt || null;
  this.create.keyValuePairs = item.keyValuePairs || {};
  this.create.year = item.year || 0;
  this.create.isOnline = item.isOnline;
  this.create.platform = item.platform || [];
  this.create.testId = item.testId || null;
  this.create.languages = item.languages || [];
}" class="mt-2" :items="paperItems" label="Select Paper" dense/>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" :max-width="600">
        <v-card class="pa-4">
          <v-card-text>
            Do you want to delete?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text outlined color="green" @click="deleteDialog = false">CANCEL</v-btn>
            <v-btn text outlined color="red" @click="deleteRequest">DELETE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>

</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import KeyValuePairEditor from "@/components/KeyValuePairEditor";
import DateTimeField from "@/components/DateTimeField";
import Platform from "@/components/PlatformComponent";

export default {
  components: {Platform, DateTimeField, KeyValuePairEditor, LangFieldEditor},
  methods: {
    async findRequest() {
      this.find.loading = true;
      try {
        const {data} = await this.$metaHttp.get("/examGroup", {
          params: {
            country: this.find.country,
          },
        });
        if (data.statusCode === 0) {
          this.find.examGroups = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest2() {
      this.find.loading = true;
      try {
        const {data} = await this.$metaHttp.get("/exam", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
          },
        });
        if (data.statusCode === 0) {
          this.find.exams = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest3() {
      this.find.loading = true;
      try {
        const {data} = await this.$metaHttp.get("/paper", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            exam: this.find.exam,
          },
        });
        if (data.statusCode === 0) {
          this.find.papers = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async createOrUpdate(id) {
      this.create.loading = false;
      try {
        const {data} = await this.$metaHttp.post(
            "/paper",
            {
              country: this.find.country,
              examGroup: this.find.examGroup,
              exam: this.find.exam,
              description: this.create.description,
              title: this.create.title,
              date: this.create.date,
              liveAt: this.create.liveAt,
              year: this.create.year,
              isOnline: this.create.isOnline,
              keyValuePairs: this.create.keyValuePairs,
              platform: this.create.platform,
              languages: this.create.languages,
              key: this.create.key
            }
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.create.loading = false;
    },
    async deleteRequest() {
      this.deleteDialog = false;
      try {
        const {data} = await this.$metaHttp.delete(
            "/paper", {params: {id: this.create.id}}
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
          this.create.id = null;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  computed: {
    groupItems() {
      return this.find.examGroups.map((el) => {
        return {value: el.key, text: el.title['en'] || el.name};
      });
    },
    paperItems() {
      return this.find.papers.map((el) => {
        return {value: el.key, text: el.title['en'] || el.name};
      });
    },
    examItems() {
      return this.find.exams.map((el) => {
        return {value: el.key, text: el.title['en'] || el.name};
      });
    },
  },
  data: () => {
    return {
      deleteDialog: false,
      create: {
        id: null,
        loading: false,
        title: {en: null},
        key: null,
        date: null,
        year: new Date().getFullYear(),
        isOnline: false,
        liveAt: null,
        description: {en: null},
        platform: [],
        languages: [],
        keyValuePairs: {}
      },
      find: {
        country: null,
        examGroup: null,
        exam: null,
        paper: null,
        loading: false,
        papers: [],
        examGroups: [],
        exams: [],
      },
    };
  },
};
</script>

<style>
</style>
