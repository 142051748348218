<template>
  <v-dialog v-model="ipsDialog" max-width="600">
    <v-card>
      <div class="pa-2 px-4">
        <v-text-field outlined dense hide-details label="Enter IP Address" v-model="search" @input="searchNow"/>
      </div>
      <v-list style="max-height: 60vh;overflow-y: auto">
        <div v-for="(item, i) in searchedData" :key="i">
          <v-divider inset v-if="i !== 0"/>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ item[0] }}</v-list-item-title>
              <v-list-item-subtitle>Total Request: {{ item[1] }}</v-list-item-subtitle>
            </v-list-item-content>
            <button class="green--text mr-2" @click="()=> $store.commit('dialog/setIpToInfo', item[0])">Info</button>
            <button class="red--text" @click="()=> $store.commit('dialog/setIpToBlock', item[0])">Block</button>
          </v-list-item>
        </div>
      </v-list>
      <v-card-text v-if="!noMore">
        <v-layout align-center justify-center class="pa-1">
          <v-btn flat outlined rounded @click="refresh" v-if="!noMore && !loading">LOAD MORE</v-btn>
          <v-progress-circular indeterminate color="white" v-if="loading"/>
        </v-layout>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-spacer/>
        <v-btn flat color="red" @click="ipsDialog = false">CLOSE</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "IPListDialog",
  computed: {
    ipsDialog: {
      set(v) {
        if (!v) {
          this.$store.commit('dialog/setIps', null);
          this.data = [];
          this.searchedData = [];
          this.search = null;
          this.noMore = false;
          this.page = 0;
        }
      },
      get() {
        return this.$store.state.dialog.ips !== null
      }
    },
    ip() {
      return this.$store.state.dialog.ips;
    }
  },
  data() {
    return {
      page: 0,
      data: [],
      searchedData: [],
      search: null,
      loading: false,
      noMore: false,
      errorOccurred: false
    }
  },
  methods: {
    refresh() {
      this.loading = true;
      this.search = null;
      this.$http.post("/security/timeStream", {query: this.$store.state.dialog.ips, nextToken: this.page || null})
          .then(res => {
            this.loading = false;
            if (res.data.statusCode === 0) {
              this.data = [...this.data, ...res.data.results.map(el => [el.Data[0].ScalarValue, Number(el.Data[1].ScalarValue)])];
              this.searchedData = [...this.searchedData, ...res.data.results.map(el => [el.Data[0].ScalarValue, Number(el.Data[1].ScalarValue)])];
              if (this.data.length > 0) this.page = res.data.pagination.nextToken;
              if (!res.data.pagination.pagination) this.noMore = true;
            } else {
              this.errorOccurred = true;
            }
          }).catch(err => {
        this.loading = false;
        this.errorOccurred = true;
      });
    },
    searchNow() {
      this.searchedData = this.data.filter(el => new RegExp(this.search).test(el[0]));
    }
  },
}
</script>

<style scoped>

</style>
