<template>
  <div ref="el">
    <v-layout wrap class="pa-1">
      <v-flex xs12 md6 lg3 class="pa-1">
        <v-select
            outlined
            dense
            hide-details
            label="Option Identifier Mode"
            :items="optionIdentifierModes"
            v-model="optionIdentifierMode"
            @input="onChangeOptionMode"
            :disabled="type !== 'mcq' && type !== 'mcqm' && type !== 't/f'"
        />
      </v-flex>
      <v-flex xs12 md6 lg3 class="pa-1">
        <v-select
            outlined
            dense
            hide-details
            multiple
            :disabled="type !== 'mcq' && type !== 'mcqm' && type !== 't/f'"
            label="Correct Option"
            :items="question[lang].options ? question[lang].options.map((el)=>{return el.identifier}) : null"
            v-model="question[lang].correct_options"
            @change="checkOptionMode"
        />
      </v-flex>
      <v-flex xs12 md6 lg3 class="pa-1 text-center">
        <v-btn
            color="green"
            outlined
            :disabled="type !== 'mcq' && type !== 'mcqm'"
            @click="addNewOption"
        >Add Option
        </v-btn>
      </v-flex>
      <v-flex xs12 md6 lg3 class="pa-1 text-center">
        <v-btn
            color="red"
            outlined
            :disabled="type !== 'mcq' && type !== 'mcqm'"
            @click="removeOption"
        >Remove Option
        </v-btn>
      </v-flex>
      <v-layout xs12 lg6>
        <v-flex v-if="type === 'mcq' || type === 'mcqm' || type === 't/f'" xs12 lg4 class="pa-1 text-center">
          <v-btn
              color="pink"
              outlined
              @click="copyOptionsToOtherLanguages"
          >Copy Options</v-btn>
        </v-flex>
        <v-flex xs12 lg4 class="pa-1 text-center">
          <v-btn
              color="yellow"
              outlined
              :disabled="showDirection"
              @click="()=> { this.showDirection = true}"
          >ADD DIRECTION
          </v-btn>
        </v-flex>
        <v-flex xs12 lg4 class="pa-1 text-center">
          <v-btn
              color="red"
              outlined
              :disabled="!showDirection"
              @click="()=> {Object.keys(question).forEach(el=> question[el].direction = null); this.showDirection = false }"
          >REMOVE DIRECTION
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout xs12 lg6>
        <v-flex xs12 lg6 class="pa-1 text-center">
          <v-btn
              color="yellow"
              outlined
              :disabled="showComprehension"
              @click="()=> { this.showComprehension = true}"
          >ADD COMPREHENSION
          </v-btn>
        </v-flex>
        <v-flex xs12 lg6 class="pa-1 text-center">
          <v-btn
              color="red"
              outlined
              :disabled="!showComprehension"
              @click="()=> { Object.keys(question).forEach(el => question[el].comprehension = null)}"
          >REMOVE COMPREHENSION
          </v-btn>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout v-if="showComprehension">
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outlined
            v-model="question[lang].comprehension"
            label="Comprehension Body"
            @input="mathJaxRefresh"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div style="border: 1px solid #d1d1d1;border-radius: 4px">
          <h2 class="text-center">Comprehension Preview</h2>
          <v-divider/>
          <div class="pa-1 text-content font-math" v-html="question[lang].comprehension"></div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-if="showDirection">
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outlined
            v-model="question[lang].direction"
            label="Direction Body"
            @input="mathJaxRefresh"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div style="border: 1px solid #d1d1d1;border-radius: 4px">
          <h2 class="text-center">Direction Preview</h2>
          <v-divider/>
          <div class="pa-1 text-content font-math" v-html="question[lang].direction"></div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outlined
            v-model="question[lang].content"
            label="Question Body"
            @input="mathJaxRefresh"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div style="border: 1px solid #d1d1d1;border-radius: 4px">
          <h2 class="text-center">Body Preview</h2>
          <v-divider/>
          <div class="pa-1 text-content font-math html-content" ref="content" v-html="question[lang].content"></div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout :key="i" v-for="(option, i) in question[lang].options">
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outline
            :disabled="type === 't/f'"
            @input="mathJaxRefresh"
            v-model="option.content"
            v-bind:label="'Option '+option.identifier"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div class="pa-1" style="border: 1px solid #d1d1d1;border-radius: 4px">
          <h2 class="text-center">Option {{ option.identifier }} Preview</h2>
          <v-divider/>
          <div
              ref="option"
              class="pa-1 text-content font-math html-content"
              v-html="option.content ? option.content : 'Demo Content'"
          ></div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout v-if="type !== 'mcq' && type !== 'mcqm'">
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outline
            v-model="question[lang].answer"
            label="Question Answer"
            @input="mathJaxRefresh"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div style="border: 1px solid #d1d1d1; border-radius: 4px">
          <h2 class="text-center">Answer Preview</h2>
          <v-divider/>
          <div
              class="pa-1 text-content font-math html-content"
              v-html="question[lang].answer ? question[lang].answer : 'Demo Content'"
          ></div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outline
            v-model="question[lang].explanation"
            label="Explanation"
            @input="mathJaxRefresh"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
          <h2 class="text-center">Explanation Preview</h2>
          <v-divider/>
          <div
              ref="explain"
              v-html="question[lang].explanation ? question[lang].explanation : 'Demo Content'"
              class="pa-1 text-content font-math html-content"
          ></div>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs6 class="pa-1">
        <RichTextEditor
            outline
            v-model="question[lang].hiddenExplanation"
            label="Explanation"
            @input="mathJaxRefresh"
        />
      </v-flex>
      <v-flex xs6 class="pa-1">
        <div style="border: 1px solid #d1d1d1; margin-top: 8px; border-radius: 4px">
          <h2 class="text-center">Hidden Explanation Preview</h2>
          <v-divider/>
          <div
              ref="hidden_explain"
              v-html="question[lang].hiddenExplanation ? question[lang].hiddenExplanation : 'Demo Content'"
              class="pa-1 text-content font-math html-content"
          ></div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import RichTextEditor from "./RichTextEditor";

export default {
  name: "LangQuestionComponent",
  components: {RichTextEditor},
  props: {
    lang: {
      type: String,
      required: true
    },
    type: {
      type: String | null,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },
  methods: {
    checkOptionMode() {
      if (this.question[this.lang].options.length === 0) {
        return;
      }
      let i = this.question[this.lang].options[0].identifier;
      if (i === "A") {
        this.optionIdentifierMode = "letter";
      }
      if (i === "1") {
        this.optionIdentifierMode = "number";
      }
      if (i === "I") {
        this.optionIdentifierMode = "roman";
      }
    },
    copyOptionsToOtherLanguages() {
      for (const lang in this.question) {
        if (lang !== this.lang) {
            this.$set(this.question, lang, {
              ...this.question[lang],
              options: this.question[lang].options.map((el, i) => {
                return {...el, content: this.question[this.lang].options[i].content}
              })
            })
        }
      }
      this.$message.success('Successful')
    },
    removeOption() {
      if (this.question[this.lang].options.length === 0) {
        return;
      }
      this.question[this.lang].options.splice(
          this.question[this.lang].options.length - 1,
          1
      );
    },
    romanize(num) {
      let lookup = {
            M: 1000,
            CM: 900,
            D: 500,
            CD: 400,
            C: 100,
            XC: 90,
            L: 50,
            XL: 40,
            X: 10,
            IX: 9,
            V: 5,
            IV: 4,
            I: 1
          },
          roman = "",
          i;
      for (i in lookup) {
        while (num >= lookup[i]) {
          roman += i;
          num -= lookup[i];
        }
      }
      return roman;
    },
    onChangeOptionMode() {
      let mode = this.optionIdentifierMode;
      this.question[this.lang].options.forEach((el, i) => {
        el.identifier =
            mode === "number"
                ? i + 1 + ""
                : mode === "roman"
                    ? this.romanize(i + 1)
                    : this.letters[i];
      });
      this.question[this.lang].correct_options = [];
    },
    addNewOption() {
      let a = this.getNextOptionID();
      this.question[this.lang].options.push({identifier: a, content: null});
    },
    getNextOptionID() {
      let mode = this.optionIdentifierMode;
      return mode === "number"
          ? this.question[this.lang].options.length + 1 + ""
          : mode === "roman"
              ? this.romanize(this.question[this.lang].options.length + 1)
              : this.letters[this.question[this.lang].options.length];
    },
    mathJaxRefresh() {
      setTimeout(() => {
        if (window.MathJax) {
          window.MathJax.typesetClear([this.$refs.el])
          window.MathJax.typesetPromise([this.$refs.el])
        }
      }, 200);
    },
    changeImageUrl() {
      this.$el.querySelectorAll(".html-content img").forEach(el => {
        el.src = el.src.replace(/@width/g, window.screen.width);
      })
    }
  },
  created() {
    this.$watch('question', () => {
      setTimeout(this.changeImageUrl, 1000)
    }, {deep: true})
  },
  mounted() {
    setTimeout(() => {
      this.checkOptionMode();
    }, 800);
  },
  data() {
    return {
      showDirection: false,
      showComprehension: false,
      optionIdentifierMode: "letter",
      optionIdentifierModes: [
        {
          text: "Letter",
          value: "letter"
        },
        {
          text: "Number",
          value: "number"
        },
        {
          text: "Roman",
          value: "roman"
        }
      ],
      letters: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    };
  },
  watch: {
    question: {
      deep: true,
      immediate: true,
      handler(newValue) {
        this.showDirection = newValue && newValue[this.lang].direction != null
        this.showComprehension = newValue && newValue[this.lang].comprehension != null
        this.mathJaxRefresh()
      }
    },
    lang(newValue) {
      if(newValue) this.mathJaxRefresh()
    }
  },
  beforeDestroy() { window.MathJax.typesetClear([this.$refs.el]) }
};
</script>

<style scoped>
.html-content {
  overflow-x: auto;
}

.text-content {
  word-wrap: break-word;
}

.font-math {
  font-family: "Times New Roman", Times, serif;
  font-size: 18px;
}

code {
  box-shadow: none !important;
}
</style>
