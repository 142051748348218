<template>
  <div>
    <v-card max-width="900px" style="margin: 10px auto">
      <v-card-title class="blue white--text">{{ productId === "new" ? "Create" : "Update" }}</v-card-title>
      <v-card-text class="pt-4">
        <v-text-field v-model="product.productId" :disabled="productId !== 'new'" label="Product Id" outlined dense
                      hide-details/>
        <v-text-field v-model="product.title" label="Title" outlined dense hide-details class="pt-1"/>
        <v-textarea no-resize class="mt-1" v-model="product.description" label="Description" outlined dense
                    hide-details/>
        <h4 class="pt-1">Price</h4>
        <v-layout v-for="(item, i) in product.price" :key="i" class="pt-2">
          <v-flex xs12 lg5 class="pr-1">
            <v-select outlined dense hide-details label="Currency" v-model="product.price[i].currency"
                      :items="currency"/>
          </v-flex>
          <v-flex xs12 lg3 class="pl-1">
            <v-text-field outlined dense hide-details label="Amount (should be times 100)" hint=""
                          v-model="product.price[i].amount" type="number"/>
          </v-flex>
          <v-flex xs12 lg3 class="pl-1">
            <v-text-field outlined dense hide-details label="Mrp Amount (should be times 100)" hint=""
                          v-model="product.price[i].mrp" type="number"/>
          </v-flex>
          <v-flex xs12 lg1 class="pl-1">
            <v-btn fab small outlined @click="() => { product.price.splice(i, 1) }">
              <v-icon>close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
        <div class="text-right pt-2">
          <v-btn small outlined @click="() => product.price.push({currency: null, mrp: 0, amount: 0})">Add Currency</v-btn>
        </div>
        <div class="px-2 pt-2">
          <h3 class="pt-1">Entitlement</h3>
          <h4 class="pt-1">Limits</h4>
          <v-layout wrap align-center v-for="id in Object.keys(product.entitlement.limit)" :key="id" class="mb-1">
            <v-flex xs12 lg5>{{ id }}</v-flex>
            <v-flex xs12 lg6><v-text-field outlined dense hide-details v-model="product.entitlement.limit[id]" label="Limit" type="number"/></v-flex>
            <v-flex xs12 lg1 class="pl-1">
              <v-btn fab small outlined @click="() => { $delete(product.entitlement.limit, id) }">
                <v-icon>close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <div class="text-right pt-2">
            <v-btn small outlined @click="addLimit">New Limit</v-btn>
          </div>
          <h4 class="pt-1">Features</h4>
          <v-layout wrap style="margin: 4px -4px">
            <v-flex xs12 lg4 v-for="(item, i) in product.entitlement.features" :key="i" class="pa-1">
              <v-text-field v-model="product.entitlement.features[i]" outlined dense hide-details label="Feature Id"/>
              <v-btn small outlined @click="() => product.entitlement.features.splice(i, 1)" class="mt-1">Remove</v-btn>
            </v-flex>
          </v-layout>
          <div class="text-right pt-2">
            <v-btn small outlined @click="() => product.entitlement.features.push(null)">New Feature</v-btn>
          </div>
        </div>
        <div class="pt-2 px-2">
          <h3>Entitlement Listing</h3>
          <h4>Limits</h4>
          <v-layout wrap align-center v-for="(id, i) in product.entitlementListing.limit" :key="id" class="mb-1">
            <v-flex xs12 lg7 class="pr-1"><v-text-field outlined dense hide-details v-model="product.entitlementListing.limit[i].title" label="Title"/></v-flex>
            <v-flex xs12 lg4><v-text-field outlined dense hide-details v-model="product.entitlementListing.limit[i].count" label="Count" type="number"/></v-flex>
            <v-flex xs12 lg1 class="pl-1">
              <v-btn fab small outlined @click="() => { product.entitlementListing.limit.splice(i, 1) }">
                <v-icon>close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <div class="text-right pt-2">
            <v-btn small outlined @click="product.entitlementListing.limit.push({title: null, count: 0})">New Limit</v-btn>
          </div>
          <h4>Features</h4>
          <v-layout wrap style="margin: 4px -4px">
            <v-flex xs12 lg4 v-for="(item, i) in product.entitlementListing.features" :key="i" class="pa-1">
              <v-text-field v-model="product.entitlementListing.features[i]" outlined dense hide-details label="Feature Id"/>
              <v-btn small outlined @click="() => product.entitlementListing.features.splice(i, 1)" class="mt-1">Remove</v-btn>
            </v-flex>
          </v-layout>
          <div class="text-right pt-2">
            <v-btn small outlined @click="() => product.entitlementListing.features.push(null)">New Feature</v-btn>
          </div>
        </div>
        <v-layout wrap class="pt-2">
          <v-flex xs12 mg6 lg4>
            <v-text-field v-model="product.days" label="Days" type="number" outlined dense hide-details />
          </v-flex>
          <v-flex xs12 mg6 lg4 class="pl-1">
            <v-select :items="types" v-model="product.type" label="Type" outlined dense hide-details/>
          </v-flex>
          <v-flex xs12 mg6 lg4 class="pl-1">
            <v-select :items="status" v-model="product.status" label="Status" outlined dense hide-details />
          </v-flex>
        </v-layout>
        <div class="text-center pt-2">
          <v-btn @click="submit" :loading="isSubmitting" :disabled="isSubmitting">Submit</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="isLoading" max-width="500" persistent>
      <v-card color="blue" dark>
        <v-card-title>Loading</v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="isLoadError" max-width="500" persistent>
      <v-card color="red" dark>
        <v-card-text class="pt-4">{{ loadError }}</v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="loadProduct">Retry</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KeyValuePairEditor from "@/components/KeyValuePairEditor";

export default {
  name: "createOrUpdate",
  components: {KeyValuePairEditor},
  data() {
    return {
      isLoading: false,
      loadError: null,
      isSubmitting: false,
      product: this.getModel(),
      currency: [
        {text: "Indian Ruppes", value: "INR"},
        {text: "United States Dollar", value: "USD"},
      ],
      types: [
        {
          text: "Subscription",
          value: "subscription",
        },
        {
          text: "ADS Subscription",
          value: "ads_subscription"
        },
      ],
      status: [
        {
          text: "Public",
          value: "public",
        },
        {
          text: "Private",
          value: "private"
        },
      ]
    }
  },
  methods: {
    addLimit() {
       const id = prompt("Limit Id")
       if(id) this.$set(this.product.entitlement.limit, id, 0)
    },
    async loadProduct() {
      this.isLoading = true
      this.loadError = null
      try {
        const {data} = await this.$subsHttp.get("/products/" + this.productId)
        if (data['statusCode'] === 0 && data['data']) {
          this.product = {...this.getModel(), ...data['data']}
        } else {
          this.loadError = data['error'] ? data['error']['message'] : "Unknown Error"
        }
      } catch (e) {
        this.loadError = e.toString()
      }
      this.isLoading = false
    },
    async submit() {
      this.isSubmitting = true
      try {
        const {data} = await this.$subsHttp.post("/createOrUpdate", this.product)
        if (data['statusCode'] === 0) {
          this.$message.success("Successful")
          if (this.productId === "new") this.$set(this, 'product', this.getModel())
        } else this.$message.error(data['error'] ? data['error']['message'] : 'Unknown Error')
      } catch (e) {
        this.$message.error(e.toString())
      }
      this.isSubmitting = false
    },
    getModel() {
      return {
        productId: null,
        title: null,
        description: null,
        entitlement: {
          limit: {},
          features: []
        },
        entitlementListing: {
          limit: [],
          features: []
        },
        price: [
          {currency: "INR", amount: 1000, mrp: 1000}
        ],
        type: 'subscription',
        days: 30,
        status: "private"
      }
    }
  },
  computed: {
    productId() {
      return this.$route.params['id']
    },
    isLoadError: {
      set(v) {
        this.loadError = null
      },
      get() {
        return this.loadError != null
      }
    }
  },
  mounted() {
    if (this.productId !== "new") this.loadProduct()
  }
}
</script>

<style scoped>

</style>
