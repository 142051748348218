<template>
  <PermissionComponent
    :permissions="['syllabus.editor']"
  >
  <PastQuestionMetadata ref="meta" v-model="syllabus"/>
  <SyllabusEditor ref="editor" v-model="syllabus"/>
  <div class="text-center pa-1">
      <v-btn dark color="blue" large :disabled="submitting" :loading="submitting" @click="submit" rounded min-width="200">SUBMIT</v-btn>
  </div>
   <v-dialog v-model="loadingErrorDialog" max-width="500" persistent>
      <v-card color="red" dark>
          <v-card-title>{{loadingError || 'Unknown Error Occurred'}}</v-card-title>
          <v-card-text>
              <v-btn text outlined dark>Try Again</v-btn>
          </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="loadingDialog" max-width="500" persistent>
      <v-card color="red" dark>
          <v-card-title>Please wait...</v-card-title>
          <v-progress-linear color="white" indeterminate/>
      </v-card>
  </v-dialog>
  <v-snackbar multi-line v-model="snackbar" top right>
    {{message}}
    <v-btn text outlined @click="snackbar = null">Close</v-btn>
  </v-snackbar>
  </PermissionComponent>
</template>

<script>
import PastQuestionMetadata from "@/components/SyllabusMetadata";
import SyllabusEditor from "@/components/SyllabusEditor";
import PermissionComponent from "../../components/PermissionComponent";
export default {
  components: {
    PastQuestionMetadata,
    PermissionComponent,
    SyllabusEditor,
  },
  computed: {
    loadingErrorDialog: {
      set(v) {
        this.loadingError = null;
      },
      get() {
        return this.loadingError != null;
      },
    },
    snackbar: {
      set(v) {
        this.message = null;
      },
      get() {
        return this.message != null;
      },
    },
  },
  methods: {
    async getFormula() {
      this.message = null;
      this.loadingDialog = true;
      try {
        const { data } = await this.$http.get(
          "/syllabus/" + this.$route.params.id
        );
        if (data.statusCode === 0) {
          this.message = "Success";
          if (data.data == null) {
            this.loadingError = "Not Found";
            return;
          }
          this.syllabus = data.data;
          setTimeout(this.$refs['meta'].populate, 1000);
        } else {
          this.message = data.error.message;
        }
      } catch (error) {
        this.message = error.toString();
      }
      this.loadingDialog = false;
    },
    async submit() {
      const {
        title,
        country,
        examGroup,
        exam,
        chapterGroups,
        chapters,
      } = this.syllabus;
      if (
        !title ||
        !country ||
        !examGroup ||
        !exam ||
        (chapterGroups.length === 0 && chapters.length === 0)
      ) {
        this.message = "Enter Required fields";
        return;
      }
      this.message = null;
      this.submitting = true;
      try {
        const { data } = await this.$http.post(
          "/syllabus/update",
          this.syllabus
        );
        if (data.statusCode === 0) {
          this.message = "Success";
        } else {
          this.message = data.error.message;
        }
      } catch (error) {
        this.message = error.toString();
      }
      this.submitting = false;
    },
  },
  data: () => {
    return {
      loadingDialog: true,
      loadingError: null,
      message: null,
      submitting: false,
      syllabus: {
        title: null,
        country: null,
        examGroup: null,
        exam: null,
        chapterGroups: [],
        chapters: [],
      },
    };
  },
  mounted() {
    this.getFormula();
  },
};
</script>

<style>
</style>
