<template>
  <v-card class="mt-1" dark outlined>
    <v-card-title>{{title}}</v-card-title>
    <v-list-item dense class="ma-0" v-for="item in Object.keys(q)" :key="item">
      <v-list-item-content>
        <h4 class="mt-2 mb-2">{{ item }}</h4>
        <LangFieldEditor v-model="q[item]" text-area label="Value"/>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn small dark color="red" fab @click="() => {$delete(q, item)}">
          <v-icon>delete</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
       <v-btn outlined @click="addField">ADD NEW FIELD</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
export default {
name: "KeyValuePairEditor",
  components: {LangFieldEditor},
  props: {
    title: {
      type: String,
      default: "KEY VALUE PAIRS"
    },
    value: {
      type: Object,
      required: true
    },
  },
  methods: {
    addField() {
      const input = prompt("Enter Field name")
      if(input){
        this.$set(this.q, input, {en: null})
      }
    }
  },
  computed: {
    q() {
      return this.value;
    },
  }
}
</script>

<style scoped>

</style>