<template>
  <div class="pa-2">
    <h2>Notification Composer</h2>
    <v-card>
      <v-card-title>Campaign Info</v-card-title>
      <v-card-text>
        <v-layout style="gap: 8px">
          <v-flex xs12 lg8>
            <v-text-field dense hide-details v-model="message.title" label="Title" outlined/>
          </v-flex>
          <v-flex xs12 lg4>
            <v-select dense hide-details v-model="message.appId" :items="allApps" label="App" outlined
                      @select="() => { message.fcm.android.restrictedPackageName = null }"/>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 lg6 class="pa-1">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Draft</v-list-item-title>
                <v-list-item-subtitle>Save message as draft</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="message.isDraft"/>
              </v-list-item-action>
            </v-list-item>
            <v-select label="Status" v-model="message.status" hide-details dense outlined :items="status"/>
          </v-flex>
          <v-flex xs12 lg6 class="pa-1">
            <v-list-item>
              <v-list-item-title>Scheduled At</v-list-item-title>
              <v-list-item-subtitle>{{ new Date(message.scheduledAt).toString() }}</v-list-item-subtitle>
            </v-list-item>
            <v-layout wrap>
              <date-time-field v-model="message.scheduledAt"/>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-card class="mt-1">
      <v-card-title>Firebase Messaging</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-layout wrap>
          <v-flex xs12 lg6 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.topic" label="Topic" outlined/>
          </v-flex>
          <v-flex xs12 lg6 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.condition" label="Condition" outlined/>
          </v-flex>
          <v-flex xs12 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.token" label="Token" outlined/>
          </v-flex>
        </v-layout>
        <h4 class="pa-1">Message</h4>
        <v-text-field dense hide-details v-model="message.fcm.data.title" label="Title" outlined class="ma-1"/>
        <v-textarea dense hide-details v-model="message.fcm.data.body" label="Body" outlined class="ma-1"/>
        <v-layout wrap class="mt-2">
          <v-flex xs12 lg4 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.data.avatar" label="Avatar Url (Optional)" outlined/>
          </v-flex>
          <v-flex xs12 lg4 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.data.image" label="Image Url (Optional)" outlined/>
          </v-flex>
          <v-flex xs12 lg4 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.data.link" label="Deep Link (Optional)" outlined/>
          </v-flex>
          <v-flex xs12 lg4 class="pa-1">
            <v-select dense hide-details v-model="message.fcm.data.channelId" label="Channel" :items="fcmChannels"
                      outlined/>
          </v-flex>
          <v-flex xs12 lg4 class="pa-1">
            <v-layout style="gap: 8px">
              <v-checkbox label="Login Required" hide-details v-model="message.fcm.data.isLogin"/>
              <v-checkbox label="Premium Required" hide-details v-model="message.fcm.data.isPremium"/>
            </v-layout>
          </v-flex>
        </v-layout>
        <h4 class="pa-1">Message Actions</h4>
        <v-layout v-for="(action, i) in message.fcm.data.actions" :key="i" wrap>
          <v-flex xs12 lg4 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.data.actions[i].title" label="Title" outlined/>
          </v-flex>
          <v-flex xs12 lg8 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.data.actions[i].link" label="Link" outlined/>
            <v-btn small outlined @click="message.fcm.data.actions.splice(i, 1)">Remove</v-btn>
          </v-flex>
        </v-layout>
        <v-btn small outlined @click="message.fcm.data.actions.push({title: null, link: null})">ADD ACTION</v-btn>
        <h4 class="pa-1">Android Specification</h4>
        <v-layout wrap class="mt-2">
          <v-flex xs12 lg4 class="pa-1">
            <v-select dense hide-details v-model="message.fcm.android.priority" label="Priority" :items="priorities"
                      outlined/>
          </v-flex>
          <v-flex xs12 lg4 class="pa-1">
            <v-text-field dense hide-details v-model="message.fcm.android.ttl" label="TTL (Time to Live)" outlined
                          type="number"/>
          </v-flex>
        </v-layout>
        <h4 class="pa-1">FCM Options</h4>
        <v-text-field dense hide-details v-model="message.fcm.fcmOptions.analyticsLabel" label="Analytics Label"
                      outlined/>
      </v-card-text>
      <v-divider/>
      <v-card-actions>
        <v-btn outlined text color="green" :disabled="isTesting || isSubmitting || isDryRunInProgress"
               @click="testMessage">TEST
        </v-btn>
        <v-btn outlined text :disabled="isDryRunInProgress || isTesting || isSubmitting" @click="dryRunMessage">DRY
          RUN
        </v-btn>
        <v-spacer/>
        <v-btn outlined text color="green" @click="save" :disabled="isSubmitting || isDryRunInProgress || isTesting">
          SAVE
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="linkHelpers.length > 0" class="mt-1">
      <v-card-title>Links</v-card-title>
      <v-card-text>
        <v-layout wrap v-for="(link, i) in linkHelpers" :key="i">
          <v-flex xs11 class="pa-1">
            {{ link }}
          </v-flex>
          <v-flex xs1 class="pa-1">
            <v-btn small outlined @click="() => { copyText(link) }">COPY</v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
    <v-dialog persistent v-model="isFetching" max-width="600px">
      <v-card color="green" dark>
        <v-card-title>Please wait</v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate color="white"/>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="fetchingErrorDialog" max-width="600px">
      <v-card color="red" dark>
        <v-card-title>Error</v-card-title>
        <v-card-text>
          {{ fetchingError }}
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateTimeField from "@/components/DateTimeField";

export default {
  name: "create",
  components: {DateTimeField},
  methods: {
    async testMessage() {
      const packageName = this.message.appId
      if (!packageName) {
        this.$message.error("Please select a specific app to test!")
        return
      }
      this.isTesting = true
      try {
        const m = JSON.parse(JSON.stringify(this.message))
        delete m['fcm']['condition']
        delete m['fcm']['token']
        m['fcm']['topic'] = packageName + ".debug"
        m['scheduledAt'] = new Date(m['scheduledAt']).getTime()
        const {data} = await this.$notiHttp.post('/test', m)
        if (data['statusCode'] === 0) this.$message.success("Successful")
      } catch (e) {
        if (e.response) {
          this.$message.error(e.response['data']['error'] ? e.response['data']['error']['message'] : e.toString())
        } else {
          this.$message.error(e.toString())
        }
      }
      this.isTesting = false
    },
    async dryRunMessage() {
      const packageName = this.message.appId
      if (!packageName) {
        this.$message.error("Please select a specific app to test!")
        return
      }
      this.isDryRunInProgress = true
      try {
        console.log(this.message.scheduledAt)
        const {data} = await this.$notiHttp.post('/dry-run', {
          ...this.message,
          scheduledAt: new Date(this.message.scheduledAt).getTime()
        })
        if (data['statusCode'] === 0) this.$message.success("Successful")
      } catch (e) {
        if (e.response) {
          this.$message.error(e.response['data']['error'] ? e.response['data']['error']['message'] : e.toString())
        } else {
          this.$message.error(e.toString())
        }
      }
      this.isDryRunInProgress = false
    },
    async save() {
      const packageName = this.message.appId
      if (!packageName) {
        this.$message.error("Please select a specific app to test!")
        return
      }
      this.isSubmitting = true
      try {
        const {data} = await this.$notiHttp.post('/createOrUpdate', {
          ...this.message,
          scheduledAt: new Date(this.message.scheduledAt).getTime()
        })
        if (data['statusCode'] === 0) {
          this.$message.success("Successful")
          if (!this.$route.params.id) this.message = this.getModel()
        }
      } catch (e) {
        if (e.response) {
          this.$message.error(e.response['data']['error'] ? e.response['data']['error']['message'] : e.toString())
        } else {
          this.$message.error(e.toString())
        }
      }
      this.isSubmitting = false
    },
    async loadMessage() {
      if (this.$route.params.id) {
        this.fetchingError = null
        this.isFetching = true
        try {
          const {data} = await this.$notiHttp.get('/message/' + this.$route.params.id)
          if (data['statusCode'] === 0 && data['data']) {
            this.message = {
              ...data['data'],
              scheduledAt: new Date(data['data']['scheduledAt']).getTime()
            }
          } else {
            this.fetchingError = 'Not Found'
          }
        } catch (e) {
          this.fetchingError = e.toString()
        }
        this.isFetching = false
      }
    },
    async getApps() {
      try {
        const {data} = await this.$notiHttp.get('/apps')
        this.apps = data['results']
      } catch (e) {
        this.$message.error(e.toString())
      }
    },
    copyText(link) {
      window.navigator.clipboard.writeText(link)
    },
    getModel() {
      return {
        fcm: {
          data: {
            title: null,
            body: null,
            avatar: null,
            image: null,
            link: null,
            channelId: 'general',
            backgroundAction: null,
            actions: [],
            versionCodes: null,
            isLogin: false,
            isPremium: false
          },
          android: {
            priority: 'normal',
            ttl: 3600
          },
          fcmOptions: {
            analyticsLabel: null
          },
          token: null,
          topic: null,
          condition: null
        },
        title: null,
        isDraft: true,
        scheduledAt: new Date().toISOString(),
        status: "PROGRESS",
        appId: null
      }
    }
  },
  data() {
    return {
      isFetching: false,
      fetchingError: null,
      isTesting: false,
      isDryRunInProgress: false,
      isSubmitting: false,
      fcmChannels: ['general', 'accounts', 'subscriptions'],
      apps: [],
      priorities: ['normal', 'high'],
      status: ["COMPLETE", "PROGRESS"],
      message: this.getModel()
    }
  },
  computed: {
    allApps() {
      return this.apps.map(el => {
        return {
          text: el.title,
          value: el.appId
        }
      })
    },
    fcmApps() {
      return this.apps.filter(el => el.appId === this.message.appId).map(el => el.appId)
    },
    fetchingErrorDialog: {
      set(v) {
        this.fetchingError = null
      },
      get() {
        return this.fetchingError != null
      }
    },
    linkHelpers() {
      const packageName = this.message.appId
      if (!packageName) return []
      return [
        `examgoal://${packageName}/past-questions`,
        `examgoal://${packageName}/past-questions/chapters?subject=physics&title=Physics`,
        `examgoal://${packageName}/past-questions/subjects`
      ]
    }
  },
  mounted() {
    this.loadMessage()
    this.getApps()
  }
}
</script>

<style scoped>

</style>
