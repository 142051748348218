<template>
  <div class="pa-1">
    <PastQuestionsMetadata v-model="map"/>
    <Platform v-model="map"/>
    <TestMappingSections v-model="map"/>
    <div class="pt-2 text-center">
      <v-btn large color="red" :disabled="isSubmitting" :loading="isSubmitting" @click="submit">SUBMIT</v-btn>
    </div>
  </div>
</template>

<script>
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";
import Platform from "@/components/PlatformComponent";
import LangFieldEditor from "@/components/LangFieldEditor";
import List from "@/pages/tests/list";
import draggable from 'vuedraggable'
import TestMappingSections from "@/components/tests/TestMappingSections";

export default {
  name: "testMap",
  components: {TestMappingSections, List, LangFieldEditor, Platform, PastQuestionsMetadata, draggable},
  methods: {
    addNewSection() {
      this.map.sections.push({
        title: {en: null},
        groups: []
      })
    },
    addNewGroup(groups) {
      groups.push({
        title: {en: null},
        tests: []
      })
    },
    async getMap() {
      if (!this.map.examGroup || !this.map.exam || !this.map.country) return
      this.mapGetting = true
      this.mapGettingError = false
      try {
        const {data} = await this.$testHttp.get('/test-maps', {
          params: {
            country: this.map.country,
            examGroup: this.map.examGroup,
            exam: this.map.exam,
            version: this.map.version
          }
        })
        if (data['statusCode'] === 0) {
          if (data['data']) this.$set(this, 'map', data['data'])
          this.$message.success('Successful')
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : 'Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.toString())
        this.mapGettingError = true
      }
      this.mapGetting = false
    },
    async submit() {
      this.isSubmitting = true
      try {
        const map = JSON.parse(JSON.stringify(this.map))
        map.sections.forEach(el => {
          el.groups = el.groups.map(e => {
            return {...e, tests: e.tests.map(el => el.testId)}
          });
        })
        const {data} = await this.$testHttp.post('/test-maps', map)
        if (data['statusCode'] === 0) {
          this.$message.success('Successful')
        } else {
          this.$message.error(data['error'] ? data['error']['message'] : 'Unknown Error')
        }
      } catch (e) {
        this.$message.error(e.response ? (e.response.data['error'] ? e.response.data['error']['message'] : 'Unknown Error') : e.toString())
      }
      this.isSubmitting = false
    }
  },
  data() {
    return {
      selectTestDialog: false,
      currentSection: [],
      map: {
        country: null,
        examGroup: null,
        exam: null,
        status: 'review',
        sections: [],
        platform: []
      },
      mapGetting: false,
      isSubmitting: false,
      mapGettingError: false
    }
  },
  watch: {
    'map.country'(newValue, oldValue) {
      this.getMap()
    },
    'map.examGroup'(newValue, oldValue) {
      this.getMap()
    },
    'map.exam'(newValue, oldValue) {
      this.getMap()
    },
  },
}
</script>

<style scoped>

</style>
