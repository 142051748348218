<template>
   <v-card class="pa-1 ma-1">
       <v-card-text>
           <v-card-title v-if="syllabus.chapterGroups.length > 0">Chapter Groups</v-card-title>
           <ChapterGroup v-for="(item, i) in syllabus.chapterGroups" @remove="syllabus.chapterGroups.splice(i, 1)" v-model="syllabus.chapterGroups[i]" :key="i"/>
           <v-card-title v-if="syllabus.chapters.length > 0">Chapters</v-card-title>
           <Chapter v-for="(item, i) in syllabus.chapters" @remove="syllabus.chapters.splice(i, 1)" v-model="syllabus.chapters[i]" :key="i"/>
           <v-divider v-if="syllabus.chapters.length > 0 || syllabus.chapterGroups.length > 0"/>
           <v-card-actions>
               <v-spacer/>
               <v-btn outlined text color="green" @click="addChapterGroup">ADD CHAPTER GROUP</v-btn>
               <v-btn outlined text color="pink" @click="addChapter">ADD CHAPTER</v-btn>
           </v-card-actions>
       </v-card-text>
   </v-card>
</template>

<script>
import ChapterGroup from './syllabus/ChapterGroup'
import Chapter from './syllabus/Chapter'
export default {
  components: {ChapterGroup, Chapter},
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    syllabus() {
      return this.value;
    },
  },
  methods: {
      addChapterGroup(){
          this.syllabus.chapterGroups.push({title: null, chapters: []})
      },
      addChapter(){
          this.syllabus.chapters.push({title: null, topics: []})
      }
  }
};
</script>

<style>
</style>
