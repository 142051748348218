<template>
  <PermissionComponent
      class="pa-1"
      :permissions="[$permissions.PAST_QUESTION_READ, $permissions[$route.params.id ? $route.params.id.toUpperCase().concat('_').concat('PAST_QUESTION_READ') : '']]"
  >
    <PastQuestionsMetadata ref="meta" v-model="q">
      <template v-slot:header>
        <v-text-field label="Search" v-model="q.query" outlined hide-details class="ma-1"
                      @input="() => getQuestions(0)"/>
      </template>
      <v-list-item v-if="!onSelect">
        <v-list-item-title>Paper View</v-list-item-title>
        <v-list-item-action>
          <v-switch v-model="paperView" hide-details/>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="!onSelect">
        <v-list-item-title>Single Update</v-list-item-title>
        <v-list-item-action>
          <v-switch v-model="singleMode" hide-details/>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>No Explanation</v-list-item-title>
        <v-list-item-action>
          <v-switch v-model="noExplanation" hide-details/>
        </v-list-item-action>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Question Type</v-list-item-title>
        <v-list-item-action>
          <v-select v-model="filterQuestionType" :items="filterQuestionTypes" label="Question Type" dense outlined
                    hide-details/>
        </v-list-item-action>
      </v-list-item>
      <div class="text-right" v-if="onSelect && questions.length > 0">
        <v-btn outlined small color="green" @click="() => { questions.forEach(el => onSelect(el)); }">IMPORT ALL</v-btn>
      </div>
    </PastQuestionsMetadata>
    <div class="pa-1" v-if="paperView" ref="paperView">
      <v-card :key="i" v-for="(q, i) in questions" class="mt-1">
        <v-tabs dark color="red">
          <v-tab v-for="lang in Object.keys(q.question)" :key="lang">{{ $getLangName(lang) }}</v-tab>
          <v-tab-item v-for="lang in Object.keys(q.question)" :key="lang" class="question-item pa-4">
            <LangQuestionComponent :type="q.type" :lang="lang" :question="q.question" v-if="q.isEditable"/>
            <div v-if="!q.isEditable">
              <div v-html="q.question[lang].content" class="mb-1"/>
              <div v-if="q.question[lang].options && q.question[lang].options.length > 0"
                   v-for="(option, i) in q.question[lang].options" :key="i">
                <h3>Option {{ option.identifier }}</h3>
                <div v-html="q.question[lang].options[i].content" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].correct_options && q.question[lang].correct_options.length > 0">
                <h3>Correct Options</h3>
                <div v-html="q.question[lang].correct_options" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].answer">
                <h3>Answer</h3>
                <div v-html="q.question[lang].answer" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].explanation">
                <h3>Explanation</h3>
                <div v-html="q.question[lang].explanation" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].hiddenExplanation">
                <h3>Hidden Explanation</h3>
                <div v-html="q.question[lang].hiddenExplanation" class="mt-1 mb-1 html-content"/>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
        <v-divider/>
        <v-card-actions>
          <v-btn small outlined text color="green" @click="() => $mathJaxRefresh($refs.paperView)">MATHTYPE</v-btn>
          <v-spacer/>
          <v-btn @click="() => nonFilteredQuestions.splice(nonFilteredQuestions.indexOf(q), 1)" outlined text
                 color="red" small>Remove
          </v-btn>
          <v-btn :to="'/pastquestions/'.concat(q.question_id)" target="_blank" outlined text color="green" small>
            UPDATE
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <v-layout wrap v-else-if="!singleMode" class="mt-1">
      <v-flex xs12 md6 lg4 class="pa-1" :key="i" v-for="(q, i) in questions">
        <v-card
            dark
            outlined
        >
          <v-layout align-center justify-space-between class="pa-2">
            <v-flex>
              <div>
                {{ q.paperTitle }}
                <div class="caption">{{ [q.chapter, q.subject].join(' • ') }}</div>
              </div>
            </v-flex>
            <v-card dark class="pa-1 caption" :color="getColor(q)">{{ q.status.toUpperCase() }}</v-card>
          </v-layout>
          <v-divider/>
          <div
              class="pa-2"
              style="word-wrap: break-word; height: 85px"
          >
            {{
              q.question[q.languages[0]].content.substring(0, 100).concat(q.question[q.languages[0]].content.length > 100 ? '...' : '')
            }}
          </div>
          <v-divider/>
          <v-card-actions class="pa-2 caption">
            <b>Languages:</b>
            {{
              q.languages.map(el => {
                return $getLangName(el)
              }).toString()
            }}
            <v-spacer/>
            <b>Marks:&nbsp</b>{{ q.marks }}/{{ q.negMarks }}
          </v-card-actions>
          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn
                text
                small
                outlined
                color="green"
                :to="onSelect ? '#' : '/pastquestions/'+ q.question_id"
                @click="() => { if(onSelect) { onSelect(q) } }"
            >{{ onSelect ? "SELECT" : "UPDATE" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <div v-else-if="singleMode" ref="el" class="pa-1">
      <v-card :key="i" v-for="(q, i) in questions" class="mt-1">
        <PastQuestionsMetadata v-model="questions[i]"/>
        <v-list-item>
          <v-list-item-title>Update</v-list-item-title>
          <v-list-item-action>
            <v-switch v-model="q.isEditable"/>
          </v-list-item-action>
        </v-list-item>
        <Platform v-model="questions[i]"/>
        <v-tabs dark color="red">
          <v-tab v-for="lang in Object.keys(q.question)" :key="lang">{{ $getLangName(lang) }}</v-tab>
          <v-tab-item v-for="lang in Object.keys(q.question)" :key="lang" class="question-item pa-4">
            <LangQuestionComponent :type="q.type" :lang="lang" :question="q.question" v-if="q.isEditable"/>
            <div v-if="!q.isEditable">
              <div v-html="q.question[lang].content" class="mb-1"/>
              <div v-if="q.question[lang].options && q.question[lang].options.length > 0"
                   v-for="(option, i) in q.question[lang].options" :key="i">
                <h3>Option {{ option.identifier }}</h3>
                <div v-html="q.question[lang].options[i].content" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].correct_options && q.question[lang].correct_options.length > 0">
                <h3>Correct Options</h3>
                <div v-html="q.question[lang].correct_options" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].answer">
                <h3>Answer</h3>
                <div v-html="q.question[lang].answer" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].explanation">
                <h3>Explanation</h3>
                <div v-html="q.question[lang].explanation" class="mt-1 mb-1 html-content"/>
              </div>
              <div v-if="q.question[lang].hiddenExplanation">
                <h3>Hidden Explanation</h3>
                <div v-html="q.question[lang].hiddenExplanation" class="mt-1 mb-1 html-content"/>
              </div>
            </div>
          </v-tab-item>
        </v-tabs>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="() => nonFilteredQuestions.splice(nonFilteredQuestions.indexOf(q), 1)" outlined text
                 color="red" small>Remove
          </v-btn>
          <v-btn @click="() => submit(q)" outlined text color="green" small>Submit</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <div class="pa-4 text-center">
      <div v-if="!questionGetting && questionGettingError">
        <h2 class="pa-4 title">Unknown Error Found</h2>
        <v-btn outlined @click="()=>{getQuestions()}">Retry</v-btn>
      </div>
      <div v-else-if="!q.exam">
        <h2 class="pa-4 title">Select Exam to View Questions</h2>
      </div>
      <div v-else-if="!questionGetting && exam && noMoreQuestions">
        <h2 class="pa-4 title">No {{ questions.length > 0 ? "More" : "" }} Questions Found</h2>
      </div>
      <div
          v-else-if="!questionGetting && !questionGettingError && q.exam && noMoreQuestions === false"
      >
        <v-btn color="red" outlined text @click="()=>{getQuestions()}">Load More</v-btn>
      </div>
      <v-progress-circular color="red" v-if="questionGetting" indeterminate/>
    </div>
  </PermissionComponent>
</template>

<script>
import PermissionComponent from "../../components/PermissionComponent";
import PastQuestionsMetadata from "@/components/metadata/PastQuestionMetadata";
import RichTextEditor from "@/components/RichTextEditor";
import LangQuestionComponent from "@/components/LangQuestionComponent";
import Platform from "@/components/PlatformComponent";

export default {
  name: "pq-all",
  components: {
    Platform,
    LangQuestionComponent,
    RichTextEditor,
    PastQuestionsMetadata,
    PermissionComponent
  },
  props: {
    abs: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: null
    }
  },
  methods: {
    async getExamGroups() {
      this.examGroupGetting = true;
      this.examGroupGettingError = false;
      try {
        const {data} = await this.$metaHttp.get("/examGroup", {
          params: {
            country: this.country,
          },
        });
        if (data.statusCode === 0) {
          this.examGroups = data.results.map((el) => {
            return {value: el.key, text: el.title['en']};
          });
        } else {
          this.examGroupGettingError = true;
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.examGroupGettingError = true;
        this.$message.error(error.message);
      }
      this.examGroupGetting = false;
    },
    getExams() {
      this.examGetting = true;
      this.examGettingError = false;
      this.$metaHttp
          .get("/exam", {
            params: {country: this.country, examGroup: this.examGroup},
          })
          .then((res) => {
            this.examGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.exams = json.results.map((el) => {
                return {value: el.key, text: el.title['en']};
              });
            } else {
              this.examGettingError = true;
            }
          })
          .catch((err) => {
            this.examGetting = false;
            this.examGettingError = true;
          });
    },
    getSubjectGroups() {
      this.subgGetting = true;
      this.subgGettingError = false;
      this.$metaHttp
          .get("/subjectGroup", {
            params: {
              country: this.country,
              examGroup: this.examGroup,
              exam: this.exam,
            },
          })
          .then((res) => {
            this.subgGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.subgs = json.results.map((el) => {
                return {value: el.key, text: el.title['en']};
              });
            } else {
              this.subgGettingError = true;
            }
          })
          .catch((err) => {
            this.subgGetting = false;
            this.subgGettingError = true;
          });
    },
    getSubjects() {
      this.subGetting = true;
      this.subGettingError = false;
      const post = {
        country: this.country,
        examGroup: this.examGroup,
        exam: this.exam,
      };
      if (this.subg) {
        post["subjectGroup"] = this.subg;
      }
      this.$metaHttp
          .get("/subject", {params: post})
          .then((res) => {
            this.subGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.subs = json.results.map((el) => {
                return {value: el.key, text: el.title['en']};
              });
            } else {
              this.subGettingError = true;
            }
          })
          .catch((err) => {
            this.subGetting = false;
            this.subGettingError = true;
          });
    },
    getChapterGroups() {
      this.chapgGetting = true;
      this.chapgGettingError = false;
      let post = {
        country: this.country,
        examGroup: this.examGroup,
        exam: this.exam,
        subject: this.sub,
      };
      if (this.subg) {
        post["subjectGroup"] = this.subg;
      }
      this.$metaHttp
          .get("/chapterGroup", {params: post})
          .then((res) => {
            this.chapgGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.chapgs = json.results.map((el) => {
                return {value: el.key, text: el.title['en']};
              });
            } else {
              this.chapgGettingError = true;
            }
          })
          .catch((err) => {
            this.chapgGetting = false;
            this.chapgGettingError = true;
          });
    },
    getChapters() {
      this.chapGetting = true;
      this.chapGettingError = false;
      const posb = {
        country: this.country,
        examGroup: this.examGroup,
        exam: this.exam,
        subject: this.sub,
      };
      if (this.subg) {
        posb["subjectGroup"] = this.subg;
      }
      if (this.chapg) {
        posb["chapterGroup"] = this.chapg;
      }
      this.$metaHttp
          .get("/chapter", {params: posb})
          .then((res) => {
            this.chapGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.chaps = json.results.map((el) => {
                return {value: el.key, text: el.title['en'], topics: el.topics || []};
              });
            } else {
              this.chapGettingError = true;
            }
          })
          .catch((err) => {
            this.chapGetting = false;
            this.chapGettingError = true;
          });
    },
    getPapers() {
      this.paperGetting = true;
      this.paperGettingError = false;
      const pob = {
        country: this.country,
        examGroup: this.examGroup,
        exam: this.exam,
      };
      this.$metaHttp
          .get("/paper", {params: pob})
          .then((res) => {
            this.paperGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              this.papers = json.results.map((el) => {
                return {value: el.key, text: el.title['en']};
              });
            } else {
              this.paperGettingError = true;
            }
          })
          .catch((err) => {
            this.paperGetting = false;
            this.paperGettingError = true;
          });
    },
    getQuestions(page) {
      if (typeof page !== "undefined") {
        this.nextPage = Number(page);
      }
      if (this.nextPage === 0) {
        this.nonFilteredQuestions = [];
        this.noMoreQuestions = false;
      }
      this.questionGetting = true;
      this.questionGettingError = false;
      this.$pqhttp
          .get("/list", {
            params: {
              country: this.q.country,
              examGroup: this.q.examGroup,
              exam: this.q.exam,
              subjectGroup: this.q.subjectGroup,
              subject: this.q.subject,
              chapterGroup: this.q.chapterGroup,
              chapter: this.q.chapter,
              paper: this.q.paperId,
              q: (this.q.query || '').trim(),
              status: this.q.status,
              page: this.nextPage,
            },
          })
          .then((res) => {
            this.questionGetting = false;
            let json = res.data;
            if (json.statusCode === 0) {
              json.data.map(el => {
                const question = el['question']
                if (["mcq", "t/f"].indexOf(el.type) !== -1) {
                  for (let lang in question) {
                    if (
                        Array.isArray(question[lang].correct_options) &&
                        ["mcq", "t/f"].indexOf(el.type) !== -1
                    ) {
                      let op = question[lang].correct_options;
                      question[lang].correct_options = op.join("");
                    }
                  }
                }
                return {...el, question: question, isEditable: false}
              }).forEach((l) => this.nonFilteredQuestions.push(l));
              if (this.$refs.el) this.$mathJaxRefresh(this.$refs.el)
              if (this.$refs.paperView) this.$mathJaxRefresh(this.$refs.paperView)
              if (json.data.length === 0) {
                this.noMoreQuestions = true;
                this.$message.warning(
                    `No ${this.questions.length > 0 ? "More" : ""} Questions Found`
                );
              } else if (json.data.length < 10) {
                this.noMoreQuestions = true;
              } else {
                this.nextPage++;
              }
            } else {
              this.questionGettingError = true;
            }
          })
          .catch((err) => {
            console.log(err);
            this.questionGetting = false;
            this.questionGettingError = true;
          });
    },
    getColor(q) {
      if (!q.status) return "red";
      if (q.status === "public") {
        if (q.question["en"].explanation === null) {
          return "purple";
        } else {
          return "green";
        }
      } else {
        if (q.question["en"].explanation != null) {
          return "teal";
        } else {
          if (q.status === "review") return "blue darken-2";
          return "pink";
        }
      }
    },
    async submit(q) {
      try {
        const question = {...q}
        delete question['isEditable']
        for (let i in question.question) {
          if (typeof question.question[i].correct_options === "string") {
            question.question[i].correct_options = question.question[i].correct_options.split("");
          }
        }
        const {data} = await this.$pqhttp.post("/createOrUpdate", question)
        if (data['statusCode'] !== 0) this.$message.error(data['error'] ? data['error']['message'] : "error occurred")
        else {
          if (["mcq", "t/f"].indexOf(q.type) !== -1) {
            for (let lang in question['question']) {
              if (
                  Array.isArray(question.question[lang].correct_options) &&
                  ["mcq", "t/f"].indexOf(q.type) !== -1
              ) {
                let op = question.question[lang].correct_options;
                question.question[lang].correct_options = op.join("");
              }
            }
          }
          this.$message.success("Successful")
        }
      } catch (e) {
        this.$message.error(e.toString())
      }
    },
    async deleteQuestion(q) {
      const res = confirm("Do you want to delete this question?")
      if (res === true) {
        try {
          const {data} = await this.$pqhttp.delete('/' + q.question_id)
          if (data['statusCode'] !== 0) this.$message.error(data['error'] ? data['error']['message'] : "error occurred")
          else {
            this.nonFilteredQuestions.splice(this.nonFilteredQuestions.indexOf(q), 1)
            this.$message.success("Successful")
          }
        } catch (e) {
          this.$message.error(e.toString())
        }
      }
    }
  },
  computed: {
    questions() {
      let q = this.nonFilteredQuestions
      if (this.noExplanation) q = q.filter(el => !el.question[el.languages[0]].explanation)
      if (this.filterQuestionType) q = q.filter(el => el.type === this.filterQuestionType)
      return q
    }
  },
  data() {
    return {

      paperView: false,

      singleMode: false,

      searchQuery: null,

      megUpdate: false,

      nonFilteredQuestions: [],
      nextPage: 0,
      questionGetting: false,
      questionGettingError: false,

      topics: [],
      topic: null,
      noMoreQuestions: false,
      //State of Networking
      examGroupGetting: false,
      examGetting: false,
      examGroupGettingError: false,
      examGettingError: false,
      subgGetting: false,
      subgGettingError: false,
      subGetting: false,
      subGettingError: false,
      chapgGetting: false,
      chapgGettingError: false,
      chapGetting: false,
      chapGettingError: false,
      topicGetting: false,
      topicGettingError: false,
      paperGetting: false,
      paperGettingError: false,

      examGroups: [],
      exams: [],
      papers: [],
      paper: null,
      examGroup: null,
      country: null,
      exam: null,
      subgs: [],
      subg: null,
      subs: [],
      sub: null,
      chapgs: [],
      chapg: null,
      chaps: [],
      chap: null,

      noExplanation: false,
      q: {
        query: null,
        country: null,
        examGroup: null,
        exam: null,
        subjectGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        status: null,
        paperId: null
      },
      filterQuestionTypes: [],
      filterQuestionType: null
    };
  },
  watch: {
    filterQuestionType(newValue) {
      if (newValue) {
        setTimeout(() => {
          if (this.$refs.el) this.$mathJaxRefresh(this.$refs.el)
          if (this.$refs.paperView) this.$mathJaxRefresh(this.$refs.paperView)
        }, 200)
      } else {
        if (this.$refs.el) this.$mathJaxClear(this.$refs.el)
        if (this.$refs.paperView) this.$mathJaxClear(this.$refs.paperView)
      }
    },
    singleMode(newValue) {
      if (newValue) {
        setTimeout(() => {
          if (this.$refs.el) this.$mathJaxRefresh(this.$refs.el)
          if (this.$refs.paperView) this.$mathJaxRefresh(this.$refs.paperView)
        }, 200)
      } else {
        if (this.$refs.el) this.$mathJaxClear(this.$refs.el)
        if (this.$refs.paperView) this.$mathJaxClear(this.$refs.paperView)
      }
    },
    paperView(newValue) {
      if (newValue) {
        setTimeout(() => {
          if (this.$refs.el) this.$mathJaxRefresh(this.$refs.el)
          if (this.$refs.paperView) this.$mathJaxRefresh(this.$refs.paperView)
        }, 200)
      } else {
        if (this.$refs.el) this.$mathJaxClear(this.$refs.el)
        if (this.$refs.paperView) this.$mathJaxClear(this.$refs.paperView)
      }
    },
    nonFilteredQuestions(newValue) {
      const set = new Set()
      newValue.forEach(el => set.add(el.type))
      this.filterQuestionTypes = Array.from(set)
      if (this.filterQuestionTypes.indexOf(this.filterQuestionType) === -1) this.filterQuestionType = null
    },
    'q.exam'(newValue, oldValue) {
      if (newValue) {
        this.filterQuestionType = null
        this.getQuestions(0)
      }
    },
    'q.subjectGroup'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.subject'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.chapterGroup'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.chapter'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.paperId'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    },
    'q.status'(newValue, oldValue) {
      if (newValue) this.getQuestions(0)
    }
  },
  beforeDestroy() {
    if (this.$refs.el) this.$mathJaxClear(this.$refs.el)
    if (this.$refs.paperView) this.$mathJaxClear(this.$refs.paperView)
  }
};
</script>

<style scoped>
.question-item > img {
  max-height: 200px;
}

.html-content {
  overflow-x: auto;
}
</style>
