<template>
  <v-card :color="$vuetify.theme.dark ? '': 'blue'" dark class="pa-1 ma-1">
      <v-card-text>
          <v-text-field v-model="syllabus.title" class="ma-1" hide-details outlined dense label="Title"/>
          <v-layout wrap>
              <v-flex xs4 class="pa-1">
 <v-select
                    outlined
                    dense
                    hide-details
                    height="20"
                    :items="$availCountries"
                    v-model="syllabus.country"
                    @input="()=>{ syllabus.examGroup = syllabus.exam = null; exam_groups  = exams = []; getExamGroups();}"
                    label="Select Country"
            ></v-select>
              </v-flex>

               <v-flex xs4 class="pa-1">
 <v-select
                    outlined
                    dense
                    hide-details
                    height="20"
                    :items="exam_groups"
                    v-model="syllabus.examGroup"
                    @input="()=>{ syllabus.exam = null; exams = []; getExams();}"
                    label="Select Exam Group"
            ></v-select>

            <v-progress-circular v-if="examGroupGetting" indeterminate color="white"></v-progress-circular>
            <v-btn v-if="examGroupGettingError" outlined color="white" rounded @click="getExamGroups">Retry</v-btn>
          
              </v-flex>

               <v-flex xs4 class="pa-1">
 <v-select
                    outlined
                    dense
                    hide-details
                    height="20"
                    :items="exams"
                    v-model="syllabus.exam"
                    @input="$emit('on-exam')"
                    label="Select Exam"
            ></v-select>
             <v-progress-circular v-if="examGetting" indeterminate color="white"></v-progress-circular>
            <v-btn v-if="examGettingError" outlined color="white" rounded @click="getExams">Retry</v-btn>
          
              </v-flex>
              
          </v-layout>
      </v-card-text>
  </v-card>
</template>

<script>
import Metadata from "../pages/pastquestions/lib/metadata";
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    syllabus() {
      return this.value;
    },
  },
  methods: {
    async getExamGroups() {
      await Metadata.getExamGroups(
        this,
        {
          country: this.syllabus.country,
        },
        (data) => {
          this.exam_groups = data.map((el) => {
            return { value: el.key, text: el.title['en'] };
          });
        },
        () => {
          this.examGroupGetting = true;
          this.examGroupGettingError = false;
        },
        () => {
          this.examGroupGetting = false;
        },
        () => {
          this.examGroupGettingError = true;
        }
      );
    },
    async getExams() {
      await Metadata.getExams(
        this,
        {
          country: this.syllabus.country,
          examGroup: this.syllabus.examGroup,
        },
        (data) => {
          this.exams = data.map((el) => {
            return { value: el.key, text: el.title['en'] };
          });
        },
        () => {
          this.examGetting = true;
          this.examGettingError = false;
        },
        () => {
          this.examGetting = false;
        },
        () => {
          this.examGettingError = true;
        }
      );
    },
    populate() {
      if (this.syllabus.country) {
        this.getExamGroups();
        if (this.syllabus.examGroup) {
          this.getExams();
        }
      }
    },
  },
  data: () => {
    return {
      examGroupGetting: false,
      examGroupGettingError: false,
      examGetting: false,
      examGettingError: false,
      exam_groups: [],
      exams: [],
    };
  },
};
</script>

<style>
</style>
