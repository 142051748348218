<template>
   <v-card dark color="purple" class="pa-1 ma-1">
       <v-card-text>
           <v-text-field v-model="chapter.title" label="Chapter Title" outlined dense hide-details/>
           <v-card-title>Topics</v-card-title>
           <v-card-text>
               <v-card class="pa-1 ma-1" v-for="(item, i) in chapter.topics" :key="i">
                   <v-card-text>
<v-text-field label="Topic Name" hide-details outlined dense v-model="chapter.topics[i].title"/>
<v-card-title v-if="chapter.topics[i].subTopics && chapter.topics[i].subTopics.length > 0">Sub Topics</v-card-title>
                    <v-layout wrap>
                        <v-flex xs6 class="pa-1" :key="i2" v-for="(item, i2) in chapter.topics[i].subTopics || []">
                            <v-text-field v-model="chapter.topics[i].subTopics[i2]" dense outlined hide-details class="mb-1"/>
                            <v-btn @click="chapter.topics[i].subTopics.splice(i2, 1)" outlined text dense small>REMOVE</v-btn>
                        </v-flex>
                    </v-layout>
                   </v-card-text>
                       <v-divider/>
                       <v-card-actions>
                           <v-spacer/>
                           <v-btn text outlined dense @click="() => { if(!Array.isArray(chapter.topics[i].subTopics)) { chapter.topics[i].subTopics = [] } chapter.topics[i].subTopics.push(null) } ">ADD SUB TOPIC</v-btn>
                           <v-btn text outlined dense @click="chapter.topics.splice(i, 1)">REMOVE</v-btn>
                       </v-card-actions>
                   </v-card>
           </v-card-text>
       </v-card-text>
       <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn text outlined dense @click="chapter.topics.push({title: null, subTopics: []})">ADD TOPIC</v-btn>
          <v-btn text outlined dense @click="$emit('remove')">REMOVE</v-btn>
      </v-card-actions>
   </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chapter() {
      return this.value;
    },
  },
};
</script>

<style>
</style>
