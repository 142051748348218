<template>
  <v-card class="ma-4">
    <v-card-title>Chapter Topic</v-card-title>
    <v-card-text>
      <v-layout>
        <v-flex xs8>
          <v-layout wrap>
            <v-flex xs4>
              <v-text-field disabled outlined hide-details v-model="find.country" label="Country" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field disabled outlined hide-details v-model="find.examGroup" label="Exam Group" dense/>
            </v-flex>
            <v-flex xs4 class="pl-1">
              <v-text-field disabled outlined hide-details v-model="find.subject" label="Subject" dense/>
            </v-flex>
            <v-flex xs3 class="mt-2">
              <v-text-field disabled outlined hide-details v-model="find.chapterGroup" label="Chapter Group (Optional)" dense />
            </v-flex>
            <v-flex xs6 class="mt-2 pl-1">
              <v-text-field disabled outlined hide-details v-model="find.chapter" label="Chapter" dense />
            </v-flex>
            <v-flex xs3 class="pl-1 mt-2">
              <v-text-field type="number" outlined hide-details v-model="create.order" label="Enter Order" dense/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <v-text-field v-model="create.key" label="Enter Key" hide-details outlined dense/>
            </v-flex>
            <v-flex xs12 class="mt-2">
              <LangFieldEditor v-model="create.title" label="Title"/>
            </v-flex>
          </v-layout>
          <KeyValuePairEditor v-model="create.keyValuePairs"/>
          <div class="text-center pa-4">
            <v-btn outlined color="primary" :disabled="!create.title || !create.key || !find.country || !find.examGroup"
                   @click="createOrUpdate">CREATE OR UPDATE
            </v-btn>
            <v-btn outlined color="red" :disabled="create.id == null" @click="deleteDialog = true">DELETE</v-btn>
          </div>
        </v-flex>
        <v-flex xs4 class='pl-1'>
          <v-select outlined hide-details v-model="find.country" label="Select Country"
                    @input="()=> {findRequest(); null; create.id = null;}" :items="$availCountries" dense/>
          <v-select outlined hide-details v-model="find.examGroup" :loading="find.loading"
                    @input="(v) => {findRequest2();}" class="mt-2" :items="groupItems" label="Select Exam Group" dense/>
          <v-select outlined hide-details v-model="find.subject" :loading="find.loading"
                    @input="(v) => {findRequest3(); findRequest4();}" class="mt-2" :items="subItems"
                    label="Select Subject" dense/>
          <v-select outlined hide-details v-model="find.chapterGroup" :loading="find.loading"
                    @input="(v) => {findRequest4();}" class="mt-2" :items="chapterGroupItems"
                    label="Select Chapter Group" dense/>
          <v-select outlined hide-details v-model="find.chapter" :loading="find.loading"
                    @input="(v) => {findRequest5();}" class="mt-2" :items="chapterItems" label="Select Chapter" dense/>
          <v-select outlined hide-details v-model="find.topic" :loading="find.loading" @input="(v) => {
const item = this.find.topics.filter(el=> el.key === v)[0];
this.create.key = item.key;
this.create.title = typeof item.title === 'object' ? item.title : {en: item.title};
this.create.id = item.metaId;
 this.create.keyValuePairs = item.keyValuePairs || {};
this.create.order = item.order || 0}" class="mt-2" :items="topicItems" label="Select Topic" dense/>
        </v-flex>
      </v-layout>
      <v-dialog v-model="deleteDialog" :max-width="600">
        <v-card class="pa-4">
          <v-card-text>
            Do you want to delete?
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text outlined color="green" @click="deleteDialog = false">CANCEL</v-btn>
            <v-btn text outlined color="red" @click="deleteRequest">DELETE</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>

</template>

<script>
import LangFieldEditor from "@/components/LangFieldEditor";
import KeyValuePairEditor from "@/components/KeyValuePairEditor";

export default {
  components: {KeyValuePairEditor, LangFieldEditor},
  methods: {
    resetLevel(level) {
      if (level <= 0) {
        this.find.examGroups = [];
        this.find.examGroup = null;
      }
      if (level <= 1) {
        this.find.subjects = [];
        this.find.subject = null;
      }
      if (level <= 2) {
        this.find.chapterGroups = [];
        this.find.chapterGroup = null;
      }
      if (level <= 3) {
        this.find.chapters = [];
        this.find.chapter = null;
      }
      if (level <= 4) {
        this.find.topics = [];
        this.find.topic = null;
      }
    },
    async findRequest() {
      this.find.loading = true;
      this.resetLevel(0);
      try {
        const {data} = await this.$testHttp.get("/metadata/examGroup", {
          params: {
            country: this.find.country
          },
        });
        if (data.statusCode === 0) {
          this.find.examGroups = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest2() {
      this.find.loading = true;
      this.resetLevel(1);
      try {
        const {data} = await this.$testHttp.get("/metadata/subject", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country
          },
        });
        if (data.statusCode === 0) {
          this.find.subjects = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest3() {
      this.find.loading = true;
      this.resetLevel(2);
      try {
        const {data} = await this.$testHttp.get("/metadata/chapterGroup", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            subject: this.find.subject
          },
        });
        if (data.statusCode === 0) {
          this.find.chapterGroups = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest4() {
      this.find.loading = true;
      this.resetLevel(3);
      try {
        const {data} = await this.$testHttp.get("/metadata/chapter", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            subject: this.find.subject,
            chapterGroup: this.find.chapterGroup
          },
        });
        if (data.statusCode === 0) {
          this.find.chapters = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async findRequest5() {
      this.find.loading = true;
      this.resetLevel(4);
      try {
        const {data} = await this.$testHttp.get("/metadata/chapterTopic", {
          params: {
            examGroup: this.find.examGroup,
            country: this.find.country,
            subject: this.find.subject,
            chapterGroup: this.find.chapterGroup,
            chapter: this.find.chapter
          },
        });
        if (data.statusCode === 0) {
          this.find.topics = data.results;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.find.loading = false;
    },
    async createOrUpdate() {
      this.create.loading = false;
      try {
        const {data} = await this.$testHttp.post("/metadata/chapterTopic",
            {
              country: this.find.country,
              examGroup: this.find.examGroup,
              subject: this.find.subject,
              chapterGroup: this.find.chapterGroup,
              chapter: this.find.chapter,
              title: this.create.title,
              key: this.create.key,
              keyValuePairs: this.create.keyValuePairs,
              order: this.create.order
            }
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
      this.create.loading = false;
    },
    async deleteRequest() {
      this.deleteDialog = false;
      try {
        const {data} = await this.$testHttp.delete("/metadata/chapterTopic",
            {params: {id: this.create.id}}
        );
        if (data.statusCode === 0) {
          this.$message.success("Successful");
          this.create.id = null;
        } else {
          this.$message.error(data.error.message);
        }
      } catch (error) {
        this.$message.error(error.message);
      }
    },
  },
  computed: {
    groupItems() {
      return this.find.examGroups.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    chapterGroupItems() {
      return this.find.chapterGroups.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    chapterItems() {
      return this.find.chapters.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    subItems() {
      return this.find.subjects.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
    topicItems() {
      return this.find.topics.map((el) => {
        return {value: el.key, text: el.name || el.title['en']};
      });
    },
  },
  data: () => {
    return {
      deleteDialog: false,
      create: {
        id: null,
        loading: false,
        title: {en: null},
        key: null,
        keyValuePairs: {},
        order: 0
      },
      find: {
        country: null,
        examGroup: null,
        subject: null,
        chapterGroup: null,
        chapter: null,
        topic: null,
        loading: false,
        chapterGroups: [],
        chapters: [],
        subjects: [],
        examGroups: [],
        topics: [],
        exams: [],
      },
    };
  },
};
</script>

<style>
</style>
