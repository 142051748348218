<template>
  <div class="pa-2">
    <v-card>
      <v-card-text>
        <v-progress-circular v-if="userInfoLoading" class="ma-4" indeterminate />
        <div v-if="userInfo">
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="userInfo.picture"/>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{userInfo.displayName}}</v-list-item-title>
              <v-list-item-subtitle>{{userInfo.email}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-layout class="ma-4" wrap style="row-gap: 8px">
            <v-flex xs12 md4 lg2>
              <h4>Signup Date</h4>
              <div>{{new Date(userInfo.createdAt).toLocaleString()}}</div>
            </v-flex>
            <v-flex xs12 md4 lg2>
              <h4>Phone</h4>
              <div>{{userInfo.phone}}</div>
            </v-flex>
            <v-flex xs12 md4 lg2>
              <h4>Providers</h4>
              <div>{{userInfo.providers.join(", ")}}</div>
            </v-flex>
            <v-flex xs12 md4 lg2>
              <h4>Date of Birth</h4>
              <div>{{userInfo.dateOfBirth ? new Date(userInfo.dateOfBirth).toLocaleDateString() : "Not Specified"}}</div>
            </v-flex>
            <v-flex xs12 md4 lg2>
              <h4>Gender</h4>
              <div>{{userInfo.gender || "Not Specified"}}</div>
            </v-flex>
            <v-flex xs12 md4 lg2>
              <h4>Last Password Change</h4>
              <div>{{userInfo.lastPasswordChangedAt ? new Date(userInfo.lastPasswordChangedAt).toLocaleDateString() : "Not Available"}}</div>
            </v-flex>
            <v-flex xs12 md4 lg2>
              <h4>Last Token After</h4>
              <div>{{userInfo.tokenIssuedAfter ? new Date(userInfo.tokenIssuedAfter).toLocaleString() : "Not Available"}}</div>
            </v-flex>
          </v-layout>
        </div>
      </v-card-text>
    </v-card>
    <v-card class="mt-2">
      <v-tabs v-model="currentTAB" centered>
        <v-tab>SESSIONS</v-tab>
        <v-tab>SUBSCRIPTION</v-tab>
        <v-tab>PAYMENTS</v-tab>
        <v-tab>PREVIOUS YEAR QUESTION</v-tab>
        <v-tab>TESTS</v-tab>
      </v-tabs>
      <v-tabs-items v-model="currentTAB">
        <v-tab-item class="pa-3">
          <div v-for="session in sessions" :key="session.sessionToken">
            <v-layout justify-center align-center style="gap: 8px">
              <v-flex xs12 lg10 style="overflow: hidden; white-space: nowrap;text-overflow: ellipsis;line-height: 28px">
                Expire At: {{new Date(session.expireAt).toString()}}<br/>
                Created At: {{new Date(session.createdAt).toString()}}<br/>
                <v-btn text outlined rounded small color="green" @click="$store.commit('dialog/setIpToInfo', session.lastIp)">LAST IP INFO</v-btn>
                &nbsp;
                <v-btn text outlined rounded small color="red">DEVICE INFO</v-btn>
              </v-flex>
              <v-flex xs12 lg2>
                <v-select v-model="session.status" :items="sessionStatus" label="Status" outlined dense hide-details rounded />
              </v-flex>
            </v-layout>
          </div>
        </v-tab-item>
        <v-tab-item>
          <h3>SUBSCRIPTION</h3>
        </v-tab-item>
        <v-tab-item>
          <h3>PAYMENTS</h3>
        </v-tab-item>
        <v-tab-item>
          <h3>PREVIOUS YEAR QUESTION</h3>
        </v-tab-item>
        <v-tab-item>
          <h3>TESTS</h3>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
export default {
  methods: {
    async getUserInfo() {
      this.userInfoLoading = true
      try {
        const {data} = await this.$authHttp.get('/users/'+this.$route.params.id)
        this.userInfo = data['data']
        this.getUserSessions()
      }catch (e) {
        this.$message.error(e.toString())
      }
      this.userInfoLoading = false
    },
    async getUserSessions() {
      try {
        const {data} = await this.$authHttp.get('/users/'+this.$route.params.id+'/sessions')
        this.sessions = data['results']
      }catch (e) {
        this.$message.error(e.toString())
      }
    }
  },
  data() {
    return {
      userInfoLoading: false,
      userInfo: null,
      sessions: [],
      currentTAB: 0,
      sessionStatus: ["ACTIVE", "EXPIRED"]
    }
  },
  mounted() {
    this.getUserInfo()
  }
}
</script>

<style scoped>

</style>
