<template>
    <v-card style="height: 535px">
        <v-card-title><h3>{{this.title}}</h3></v-card-title>
        <v-divider/>
        <v-list class="pa-1">
            <div v-for="(item, i) in data.slice(0, 8)" :key="i">
                <v-divider inset v-if="i !== 0"/>
                <v-list-item dense>
                    <v-list-item-content>
                        <v-list-item-title>{{item[0]}}</v-list-item-title>
                        <v-list-item-subtitle>Total Request: {{item[1]}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <button class="green--text mr-2" @click="()=> $store.commit('dialog/setIpToInfo', item[0])">Info</button>
                    <button class="red--text" @click="()=> $store.commit('dialog/setIpToBlock', item[0])">Block</button>
                </v-list-item>
            </div>
        </v-list>
        <v-divider v-if="total > 8"/>
        <v-card-actions v-if="total > 8">
            <v-spacer/>
            <v-btn text outlined color="red" @click="()=> $store.commit('dialog/setIps', this.getQuery())">View Others ({{total  - 8}})</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "IPRequestList",
        props: {
            freq: {
                type: String,
                default: '5m'
            },
            title: {
                type: String,
                default: "Last 5 Minute"
            },
            interval: {
                type: Number,
                default: 30
            }
        },
        methods: {
            getQuery() {
              return `SELECT ip, SUM(measure_value::bigint) as "count" FROM "examgoal"."IPRequestLogs" WHERE time between ago(${this.freq}) and now() GROUP BY ip ORDER BY "count" DESC`
            },
            prepareInterval(){
               this.clearInterval();
               this.intervalId = setInterval(this.refresh, this.interval * 1000);
            },
            clearInterval(){
                if(this.intervalId) clearInterval(this.intervalId);
            },
            refresh() {
               if(this.loading) return;
               this.loading = true;
               this.$http.post("/security/timeStream", {query: this.getQuery()}, {cancelToken: this.axiosSource.token})
               .then(res=>{
                   this.loading = false;
                   if(res.data.statusCode === 0){
                       this.data = res.data.results.map(el => [el.Data[0].ScalarValue, Number(el.Data[1].ScalarValue)]);
                       this.total = res.data.results.length;
                   }
               }).catch(err=>{
                  console.log(err)
                  this.loading = false;
                  this.$message.error(err.toLocaleString());
               });
            }
        },
        data() {
            return {
                axiosSource: null,
                loading: false,
                data: [],
                total: 0,
                group: this.freq,
                intervalId: null,
            }
        },
        mounted() {
            this.axiosSource = this.$axios.CancelToken.source();
            this.refresh();
            this.prepareInterval();
        },
        beforeDestroy() {
            this.clearInterval();
            this.axiosSource.cancel("Cancelled Before Destroy");
        }
    }
</script>

<style scoped>

</style>
