<template>
    <div class="pa-1">
       <v-layout row wrap>
           <v-flex xs3 v-for="(item, i) in data" :key="i" class="pa-1">
               <v-card dark color="red">
                   <v-card-text>{{item.cidr}}</v-card-text>
                   <v-divider/>
                   <v-card-actions>
                       <v-spacer/>
                       <v-btn @click="()=> unBlock(item.cidr)">UNBLOCK IP</v-btn>
                   </v-card-actions>
               </v-card>
           </v-flex>
       </v-layout>
       <v-layout align-center justify-center class="pa-2">
           <h2 v-if="!loading && noMore">No{{data.length > 0 ? ' More' : ''}} IP Found</h2>
           <v-btn flat outline @click="refresh" v-if="!loading && !noMore">LOAD MORE</v-btn>
           <v-progress-circular indeterminate v-if="loading && !noMore"/>
       </v-layout>
    </div>
</template>

<script>
    export default {
        name: "blocked",
        data() {
            return {
                loading: false,
                page: 0,
                noMore: false,
                data: []
            }
        },
        methods: {
            unBlock(ip){
                this.$http.delete("/security/unblockIp", {data: {ip}})
                .then(res=>{
                    if(res.data.statusCode === 0){
                        this.$message.success("Successfully unblocked ip");
                        const i = this.data.indexOf(this.data.filter(el=> el.cidr === ip)[0]);
                        if(i >= 0){
                            this.data.splice(i, 1);
                        }
                    }else {
                        this.$message.error("Failed to unblock ip");
                    }
                }).catch(err=>{
                    this.$message.error(err.toLocaleString());
                })
            },
            refresh() {
                this.loading = true;
                this.$http.get("/security/block/ips/list")
                .then(res=>{
                    this.loading = false;
                    if(res.data.statusCode === 0){
                        this.data = [...this.data, ...res.data.results];
                        if(res.data.results.length < 20){
                            this.noMore = true;
                        }else {
                            this.page = this.page + 1;
                        }
                    }else {
                        this.$message.error("Failed to Load Data");
                    }
                }).catch(err=>{
                    this.loading = false;
                    this.$message.error(err.toLocaleString());
                })
            }
        },
        mounted() {
            this.refresh();
        }
    }
</script>

<style scoped>

</style>
