import Vue from 'vue'
import App from './App.vue'
import axios from "axios";
import vuetify from './plugins/vuetify';
import router from "./router";
import store from "./store";
import Permissions from "./permissions";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import {io} from "socket.io-client";

Vue.use(ElementUI);
Vue.prototype.$io = io
Vue.config.productionTip = false
Vue.prototype.$availLanguages = [
    {
        value: "en",
        text: "English",
    },
    {
        value: "hi",
        text: "हिन्दी",
    },
    {
        value: "bn",
        text: "বাংলা",
    },
];

Vue.prototype.$getLangName = (id) => {
    switch (id) {
        case "en":
            return "English";
        case "hi":
            return "हिन्दी (Hindi)";
        case "bn":
            return "বাংলা (Bengali)";
    }
    return "Unknown";
};

Vue.prototype.$mathJaxClear = (el) => {
    if (window.MathJax) {
        if (el) window.MathJax.typesetClear([el])
        else window.MathJax.typesetClear()
    }
}

Vue.prototype.$mathJaxRefresh = (el) => {
    setTimeout(() => {
        if (window.MathJax) {
            if (el) window.MathJax.typesetClear([el])
            window.MathJax.typesetPromise(el ? [el] : undefined)
        }
    }, 200);
}

const axiosResponseHandler = (res) => {
    if (
        typeof res.data === "object" &&
        (res.data.error && res.data.error.code === "admin/session-expired")
    ) {
        store.commit("setUser", null);
        return Promise.reject(new Error(res.data.error.message));
    }
    return res;
};

const axiosErrorHandler = (error) => {
    if (error.message === "Network Error") {
        Vue.prototype.$message.error("No Connection! Check Internet Connection");
    }
    return Promise.reject(error);
};

const prepareAxios = (axios) => {
    axios.interceptors.response.use(axiosResponseHandler, axiosErrorHandler);
    axios.interceptors.request.use((request) => request, axiosErrorHandler);
    return axios;
}
Vue.axios = Vue.prototype.$axios = axios;
Vue.http = Vue.prototype.$http = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://admin.examgoalapis.com"
}));
Vue.prototype.$pqhttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://past-question.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$authHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://auth.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$subsHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://subscription.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$metaHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://metadata.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$notiHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://notification.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$testHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://test.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$practiceHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://theory.examgoalapis.com/v1/practice/_admin"
}));
Vue.prototype.$formulaHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://theory.examgoalapis.com/v1/_admin/formula"
}));
Vue.prototype.$notesHttp = prepareAxios(axios.create({
    withCredentials: true,
    baseURL: "https://theory.examgoalapis.com/v1/_admin"
}));
Vue.prototype.$permissions = Permissions;
Vue.prototype.$formulaModel = () => {
    return {
        languages: ["en"],
        content: {
            en: Vue.prototype.$getFormulaLangModel(),
        },
        examples: [],
        tags: [],
    };
};
Vue.prototype.$getFormulaLangModel = () => {
    return {
        title: null,
        description: null,
        formula: null,
        derivation: null,
    };
};

Vue.prototype.$availCountries = [
    {
        value: "in",
        text: "India"
    }
];

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
